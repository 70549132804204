import { connect } from "react-redux";

import SignIn from "./SignIn";
import * as actions from "../../stores/actions";

const mapDispatchToProps = function (dispatch) {
  return {
    setLoggedUser: function (payload) {
      dispatch(actions.setLoggedUser(payload));
    },
    signOut: function () {
      dispatch(actions.signOut());
    },
  };
};

export default connect(undefined, mapDispatchToProps)(SignIn);
