import * as actionTypes from "../actionTypes";

const initialState = {
  sites: [],
  inactiveSites: [],
  userSites: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_SITES:
      return {
        ...state,
        sites: action.payload,
      };
    case actionTypes.SET_USER_SITES:
      return {
        ...state,
        userSites: action.payload,
      };
    case actionTypes.GET_USER_SITES_FAILED: {
      return {
        ...state,
        userSites: [],
      };
    }
    case actionTypes.SET_INACTIVE_SITES:
      return {
        ...state,
        inactiveSites: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
