import React, { useEffect, useState } from "react";
import { Link, useNavigate, useResolvedPath } from "react-router-dom";

import { defaultFormData, defaultFormError } from "./defaultData";
import { FeaturePermission, SiteSelection } from "..";
import { nonEmpty } from "../../../../../helpers";

import "./style.scss";
import "./userDetail.css";
import { Input } from "../../../../UI/FormGenerator/FormGenerator";
import DropDown from "../../../DropDown/DropDown";
import floatInfo from "../../../../UI/floatInfo/floatInfo";
import NewConfirmDialog from "../../../../UI/ConfirmDialog/NewConfirmDialog";
import { defaultOption, rearrangeRole } from "../../../../../helpers/misc";
import moment from "moment";
import NewPopUpFrame from "../../../../UI/PopupFrame/NewPopUpFrame";
import { site } from "../../../../../@types/sites";
import { Features } from "../../../../../@types/feature";
import { userFormData, userGroup } from "../../../../../@types/user";
import { option } from "../../../../UI/FormGenerator/formTypes";

type PartialNull<T> = { [P in keyof T]: T[P] | null };
type user = Omit<userFormData, "sites"> & { sites: site[]; createdAt: string };
export type userData = Omit<PartialNull<userFormData>, "sites"> & {
  sites: number[];
};

const UserDetail = function ({
  userId,
  user,
  sites,
  features,
  roles,
  userGroups,
  isConfirmYes,
  getUser,
  delUser,
  saveUser,
  onCancel,
  setConfirmDialog,
  resetConfirmDialog,
  currentUserId,
}: {
  userId: number | null;
  user: user;
  sites: site[];
  features: Features[];
  roles: { id: number; name: string }[];
  userGroups: userGroup[];
  isConfirmYes: boolean;
  getUser: (id: number) => void;
  delUser: (params: { id: number; userId: number | undefined }) => void;
  saveUser: (type: "edit" | "new", data: userData) => void;
  onCancel: () => void;
  setConfirmDialog: (text: string) => void;
  resetConfirmDialog: () => void;
  currentUserId: number | undefined;
}) {
  const navigate = useNavigate();

  type partialUser = Omit<PartialNull<user>, "sites"> & { sites: site[] };

  const [formData, setFormData] = useState<partialUser>({ ...defaultFormData });
  const [formError, setFormError] = useState<{
    [key in keyof user]?: boolean;
  }>({ ...defaultFormError });
  const [roleOptions, setRoleOptions] = useState<option[]>([]);
  const [userGroupOptions, setUserGroupOptions] = useState<option[]>([]);
  const [siteSelectionShown, setSiteSelectionShown] = useState(false);
  const [featurePermissionShown, setFeaturePermissionShown] = useState(false);
  const [paswdConfirm, setPaswdConfirm] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (userId) {
      getUser(userId);
    }
  }, []);

  useEffect(() => {
    if (user) {
      setFormData(user);
    }
  }, [user]);

  useEffect(() => {
    if (roles && roles.length > 0) {
      const _options = rearrangeRole(
        roles.map((role) => {
          return {
            label: role.name,
            value: role.id,
          };
        })
      );

      setRoleOptions(_options);
    }
  }, [roles]);

  useEffect(() => {
    if (userGroups && userGroups.length > 0) {
      const _options = userGroups.map((userGroup) => {
        return {
          label: userGroup.name,
          value: userGroup.id,
        };
      });

      setUserGroupOptions(_options);
    }
  }, [userGroups]);

  useEffect(() => {
    if (isConfirmYes) {
      resetConfirmDialog();
      actualDel();
    }
  }, [isConfirmYes]);

  const updateFormData = (objData: Partial<user>) => {
    const _formError = { ...formError };

    for (const k in objData) {
      const key = k as keyof user;
      _formError[key] = false;
    }

    setFormData({ ...formData, ...objData });
    setFormError(_formError);
  };

  const isActiveChanged = (status: "active" | "inactive") => {
    const isActive = status === "active";
    updateFormData({ isActive });
  };

  const showSiteSelection = () => {
    setSiteSelectionShown(true);
  };

  const showFeaturePermission = () => {
    setFeaturePermissionShown(true);
    setSiteSelectionShown(false);
  };

  const getValue = (id: number | undefined, list: option[]) => {
    try {
      let list2 = Array.isArray(list) ? list : [];

      if (typeof id !== `number` || id < 1) {
        return defaultOption;
      } else {
        const result = list2.filter((item) => item.value === id)[0];
        if (result) {
          return {
            label: result.label,
            value: result.value,
          };
        } else {
          return defaultOption;
        }
      }
    } catch {
      return defaultOption;
    }
  };

  const sanitizedFeatures = () => {
    const newFeatureList = (formData.featurePermissions || []).filter(
      (feature) => {
        return (formData.sites || []).some((site) => {
          return site.id === feature.site;
        });
      }
    );

    return newFeatureList;
  };

  const onSave = () => {
    const data: userData = {
      ...formData,
      sites: (formData.sites || []).map((site) => site.id),
      featurePermissions:
        formData.featurePermissions && formData.featurePermissions.length > 0
          ? sanitizedFeatures()
          : formData.featurePermissions,
    };

    saveUser("edit", data);
    setTimeout(() => {
      navigate("/settings/staff");
    }, 1000);
  };

  const isValid = () => {
    if (formData.paswd && paswdConfirm && formData.paswd !== paswdConfirm) {
      return false;
    }

    return true;
  };

  const actualDel = () => {
    delUser({ id: user.id, userId: currentUserId });
    onCancel();
  };

  const delOnClick = () => {
    setConfirmDialog("Ar tikrai norite pašalinti paskyrą?");
  };

  const containsRemovedSite = () => {
    const contained = (site: site) => {
      return formData.sites.some((fSite) => {
        return fSite.id === site.id;
      });
    };
    return (user?.sites || []).some((site) => {
      return !contained(site);
    });
    /**if the new set does not include all, call the function */
  };

  const url = useResolvedPath("").pathname;

  return (
    <>
      <form>
        <div className="user-form user_form_wrapper">
          <div className="user_form_record">
            <div className="user_form_header">Darbuotojo vardas</div>
            <div className="user_form_header split">
              <span>Vardas</span>
              <span>Pavardė</span>
            </div>
            <div className="user_form_header">
              Darbuotojo tipas sistemoje (rolė)
            </div>
            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ uname: value })}
                  type={"text"}
                  value={nonEmpty(formData.uname)}
                  placeholder={"Vardas, pavardė"}
                  error={false}
                />
              </div>
            </div>

            <div className="user_form_container split">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ firstName: value })}
                  type={"text"}
                  value={nonEmpty(formData.firstName)}
                  placeholder={"Vardas"}
                  error={false}
                />
              </div>
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ lastName: value })}
                  type={"text"}
                  value={nonEmpty(formData.lastName)}
                  placeholder={"Pavardė"}
                  error={false}
                />
              </div>
            </div>

            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <DropDown
                  options={roleOptions}
                  onSelect={(selectedVal) =>
                    updateFormData({ roleId: selectedVal.value })
                  }
                  value={getValue(formData.roleId || undefined, roleOptions)}
                  border={""}
                  title={"Pasirinkti..."}
                  error={false}
                />
              </div>
            </div>
          </div>
          <div className="user_form_record">
            <div className="user_form_header">Naujas slaptažodis</div>

            <div className="user_form_header">El. pašto adresas</div>

            <div className="user_form_header">Darbo grupė</div>

            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => {
                    updateFormData({ paswd: value });
                  }}
                  type={"password"}
                  value={nonEmpty(formData.paswd)}
                  placeholder={"Naujas slaptažodis"}
                  error={!isValid()}
                />
              </div>
            </div>
            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ email: value })}
                  type={"text"}
                  value={nonEmpty(formData.email)}
                  placeholder={"El. pašto adresas"}
                  error={false}
                />
              </div>
            </div>
            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <DropDown
                  options={userGroupOptions}
                  value={getValue(
                    formData.userGroupId || undefined,
                    userGroupOptions
                  )}
                  onSelect={(selected) =>
                    updateFormData({ userGroupId: selected.value })
                  }
                  border={""}
                  title={"Ieškoti..."}
                  error={false}
                />
              </div>
            </div>
          </div>

          <div className="user_form_record">
            <div className="user_form_header">
              Patvirtinkite įvestą slaptažodį
            </div>
            <div className="user_form_header split">
              <span>Telefono nr.</span>
              <span>Pareigos</span>
            </div>
            <div className="user_form_header">Strukūrinis padalinys</div>
            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => setPaswdConfirm(value)}
                  type={"password"}
                  value={paswdConfirm}
                  placeholder={"Patvirtinkite įvestą slaptažodį"}
                  error={!isValid()}
                />
              </div>
            </div>

            <div className="user_form_container split">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ phone: value })}
                  type={"number"}
                  value={nonEmpty(formData.phone)}
                  placeholder={"Tel. nr."}
                  error={false}
                />
              </div>
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ position: value })}
                  type={"text"}
                  value={nonEmpty(formData.position)}
                  placeholder={"Pareigos"}
                  error={false}
                />
              </div>
            </div>

            <div className="user_form_container">
              <button
                type="button"
                className="btn btn-default btn-stretch"
                onClick={() => showSiteSelection()}
              >
                Priskirti / Pašalinti objektą (aikštelę)
              </button>
            </div>
          </div>
          <div className="user_form_record">
            <div className="user_form_header">Būsena</div>
            <div className="user_form_header"></div>
            <div className="user_form_header"></div>
            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <DropDown
                  options={[
                    { label: "Aktyvi", value: 1 },
                    { label: "Neaktyvi", value: 2 },
                  ]}
                  value={
                    formData.isActive
                      ? {
                          label: "Aktyvi",
                          value: 1,
                        }
                      : { label: "Neaktyvi", value: 2 }
                  }
                  onSelect={(selected) =>
                    isActiveChanged(
                      selected.value === 1 ? "active" : "inactive"
                    )
                  }
                  border={""}
                  title={""}
                  error={false}
                />
              </div>
            </div>

            <div className="user_form_container">
              <button
                type="button"
                className={`btn btn-default ${
                  formData.sites.length > 0 ? "" : "inActive"
                }`}
                onMouseOver={(e) => {
                  if (!(formData.sites.length > 0)) {
                    floatInfo.subscribe(
                      e,
                      `Please add a site before adding permissions`
                    );
                  }
                }}
                onMouseOut={(e) => {
                  if (!(formData.sites.length > 0)) {
                    floatInfo.unsubscribe(e);
                  }
                }}
                onClick={() => {
                  if (formData.sites.length > 0) {
                    showFeaturePermission();
                  } else {
                    return;
                  }
                }}
              >
                Prieigos lygių valdymas
              </button>
            </div>

            <div className="user_form_container split">
              <Link
                className="userActivity_det_link"
                to={`${url}/activities`}
                state={{
                  userId: formData.id || "",
                  fullName: [formData.firstName, formData.lastName]
                    .filter((item) => item)
                    .join(" "),
                }}
              >
                <button type="button" className="btn btn-default">
                  Veiklos istorija
                </button>
              </Link>
              <button
                type="button"
                className="btn btn-default"
                onClick={delOnClick}
              >
                Pašalinti paskyrą
              </button>
            </div>
          </div>
          <div className="user_form_btn_container">
            <div className="col">
              Paskyra sukurta:{" "}
              {moment(formData.createdAt || "").format("YYYY-MM-DD HH:mm")}
            </div>
            <div className="actions">
              <button
                type="button"
                className="btn btn-custom"
                onClick={() => onCancel()}
              >
                Atšaukti
              </button>
              <button
                type="button"
                onMouseOver={(e) => {
                  if (!(formData.sites.length > 0)) {
                    floatInfo.subscribe(
                      e,
                      `Please add at least one site before saving your edits`
                    );
                  }
                }}
                onMouseOut={(e) => {
                  if (!(formData.sites.length > 0)) {
                    floatInfo.unsubscribe(e);
                  }
                }}
                className={`btn btn-green ${
                  formData.sites.length > 0 ? "" : "inActive"
                }`}
                onClick={() => {
                  if (containsRemovedSite()) {
                    if (formData.sites.length > 0) {
                      setShowPrompt(true);
                    } else {
                      return;
                    }
                  } else {
                    if (formData.sites.length > 0) {
                      onSave();
                    } else {
                      return;
                    }
                  }
                }}
              >
                Išsaugoti
              </button>
            </div>
          </div>
        </div>
      </form>
      {showPrompt && (
        <NewConfirmDialog
          txt={`Dėmesio: ištrynus aikštelę, bus ištrinti ir visi darbuotojų prieigos nustatymai. Patys Darbuotojai nebus ištrinti.`}
          isVisible={showPrompt}
          continueText={`Supratau, ištrinti`}
          returnText={`Grįžti`}
          onExit={() => setShowPrompt(false)}
          onContinue={() => onSave()}
          isLoading={false}
        />
      )}

      {siteSelectionShown && (
        <NewPopUpFrame
          isShown={siteSelectionShown}
          handleOpen={setSiteSelectionShown}
          title={"AIKŠTELĖS"}
          showShadow={false}
        >
          <SiteSelection
            sites={sites}
            formData={formData as userFormData}
            onCancel={() => setSiteSelectionShown(false)}
            onSave={() => setSiteSelectionShown(false)}
            updateFormData={updateFormData}
          />
        </NewPopUpFrame>
      )}

      {featurePermissionShown && (
        <NewPopUpFrame
          showShadow={false}
          isShown={featurePermissionShown}
          handleOpen={setFeaturePermissionShown}
          title={"DARBUOTOJO PRIEIGOS VALDYMAS"}
          classNameWrap="feature_popup_wrap"
        >
          <FeaturePermission
            features={features}
            formData={formData as userFormData}
            onCancel={() => setFeaturePermissionShown(false)}
            updateFormData={updateFormData}
            onSave={() => setFeaturePermissionShown(false)}
          />
        </NewPopUpFrame>
      )}
    </>
  );
};

export default UserDetail;
