import { ofType } from "redux-observable";
import { mergeMap, catchError, switchMap } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { FetchRX } from "../../helpers/misc";

export const savePerson = (action$) =>
  action$.pipe(
    ofType(actions.SAVE_PERSON),
    mergeMap((action) =>
      FetchRX("/persons", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        switchMap((result) => [
          {
            type: actions.SAVE_PERSON_SUCCEED,
            payload: result.person,
          },
          {
            type: actions.RESET_PERSON_SAVING_STATUS,
          },
        ]),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.SAVE_PERSON_FAILED });
        })
      )
    )
  );
