import React from "react";

import { Overlay } from "../";

import "./style.scss";

const ConfirmDialog = function ({ txt, isVisible, onExit }) {
  return (
    <Overlay
      visibility={isVisible ? "visible" : "hidden"}
      needExit
      exit={onExit}
      styleContainer={{ width: "80%" }}
    >
      <div className="confirm-dialog">
        <p>{txt}</p>
        <div className="action">
          <button className="btn btn-default" onClick={() => onExit(false)}>
            Atšaukti
          </button>
          <button className="btn btn-green" onClick={() => onExit(true)}>
            Taip
          </button>
        </div>
      </div>
    </Overlay>
  );
};

export default ConfirmDialog;
