import ExcelExport, { alignment, defaultDataType } from 'export-xlsx';

export class DataExportService {

	constructor() {
		this.excelExport = new ExcelExport();
		this.config = {
			workSheets: [],
		};
	}

	init(title, headers) {
		this.config.fileName = title;
		this.config.workSheets[0] = {
			sheetName: 'Sheet 1',
			startingRowNumber: 2,
			gapBetweenTwoTables: 2,
			tableSettings: {
				data: {
					importable: true,
					tableTitle: title,
					notification: '',
					headerGroups: [],
					headerDefinition: headers,
				},
			},
		};
	}

	export(rows) {
		this.excelExport.downloadExcel(this.config, [{ data: rows }]);
	}

}

export {
	alignment,
	defaultDataType,
};