import * as actionTypes from "../actionTypes";
import * as statuses from "../../statuses";

const initialState = {
  savingStatus: statuses.STATUS_IDLE,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_WASTE_ACCUMULATION:
      return {
        ...state,
        savingStatus: statuses.STATUS_IN_PROGRESS,
      };
    case actionTypes.SAVE_WASTE_ACCUMULATION_SUCCEED:
      return {
        ...state,
        savingStatus: statuses.STATUS_DONE,
      };
    case actionTypes.RESET_WASTE_ACCUMULATION_SAVING_STATUS:
      return {
        ...state,
        savingStatus: statuses.STATUS_IDLE,
      };
    default:
      return state;
  }
}

export default reducer;
