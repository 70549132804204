import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { store } from "../..";
import {
  getFormations as get_formations,
  getInactiveFormations as get_inactive_formations,
} from "../actions";
import { FetchRX } from "../../helpers/misc";

export const getFormations = (action$) =>
  action$.pipe(
    ofType(actions.GET_FORMATIONS),
    mergeMap(() =>
      FetchRX("/formations").pipe(
        map((result) => {
          return {
            type: actions.SET_FORMATIONS,
            payload: result.formations,
          };
        }),
        catchError((error) => {
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const getInactiveFormations = (action$) =>
  action$.pipe(
    ofType(actions.GET_INACTIVE_FORMATIONS),
    mergeMap(() =>
      FetchRX("/formations/inactive").pipe(
        map((result) => {
          return {
            type: actions.SET_INACTIVE_FORMATIONS,
            payload: result.formations,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const deactivateFormation = (action$) =>
  action$.pipe(
    ofType(actions.DEACTIVATE_FORMATION),
    mergeMap((action) =>
      FetchRX("/formations/deactivate", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_formations());
          store.dispatch(get_inactive_formations());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const hideFormation = (action$) =>
  action$.pipe(
    ofType(actions.HIDE_FORMATION),
    mergeMap((action) =>
      FetchRX("/formations/hide", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_formations());
          store.dispatch(get_inactive_formations());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const restoreFormation = (action$) =>
  action$.pipe(
    ofType(actions.RESTORE_FORMATION),
    mergeMap((action) =>
      FetchRX("/formations/restore", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_formations());
          store.dispatch(get_inactive_formations());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const createFormation = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_FORMATION),
    mergeMap((action) =>
      FetchRX("/formations/create", {
        method: "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_FORMATIONS,
            payload: result.formations,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const editFormation = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_FORMATION),
    mergeMap((action) =>
      FetchRX("/formations/edit", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_FORMATIONS,
            payload: result.formations,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
