import { option } from "../components/UI/FormGenerator/formTypes";
import { store } from "..";
import { SIGNOUT } from "../stores/actionTypes";
import { mergeMap } from "rxjs/operators";
import { from, OperatorFunction } from "rxjs";

export const shortName = (munic: string): string => {
  const stringArray = munic.split(/\s+/);
  const shortMunic = `${stringArray[0]}${
    stringArray.length > 1 && stringArray[1]
      ? ` ${stringArray[1].charAt(0).toLowerCase()}.`
      : ``
  }`;
  return shortMunic;
};

export const appendSort = (
  value: string,
  toggle = false,
  clear = true,
  state: {
    filter: { order: string[] };
    updateFilter: (data: { order: string[] }) => void;
  }
) => {
  const { filter, updateFilter } = state;
  const order = filter.order || [];
  let index = -1;

  for (let n = 0; n < order.length; n++) {
    const item = order[n];
    const string = item.split(` `)[0];
    const exists = string === value;
    if (exists) {
      index = n;
      break;
    }
  }

  if (index >= 0) {
    if (toggle) {
      const stringVal = `${value} ${enabled(value, state) ? `ASC` : `DESC`}`;
      const newSort = order.map((item, n) => {
        if (index === n) {
          return stringVal;
        } else {
          return item;
        }
      });
      if (clear) {
        updateFilter({ order: [stringVal] });
      } else {
        updateFilter({ order: [...newSort] });
      }
    } else {
      return;
    }
  } else {
    updateFilter({ order: [...(clear ? [] : order), `${value} DESC`] });
  }
};

export const enabled = (
  value: string,
  state: {
    filter: { order: string[] };
    updateFilter: (data: { order: string[] }) => void;
  }
) => {
  try {
    const { filter, updateFilter } = state;
    if (filter.order) {
      const available = filter.order.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      )[0];
      if (available.split(` `)[1] === `DESC`) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export const getDropDownValue = (
  id: number | undefined,
  options: option[]
): option => {
  const option = options.find((opt) => opt.value === id);

  return option || defaultOption;
};

export const isNumber = (value: number | string | undefined | null) => {
  value = parseFloat(`${value}`);

  return !isNaN(value) && typeof value === "number";
};

export const min = (num1: number, num2: number) => {
  if (num1 < num2) {
    return num1;
  }
  return num2;
};
export const max = (num1: number, num2: number) => {
  if (num1 > num2) {
    return num1;
  }
  return num2;
};

export const pageSettingOptions = [
  { label: `10`, value: 0 },
  { label: `25`, value: 0 },
  { label: `50`, value: 0 },
];

export const makeInt = (value: string | number) => {
  value = `${value}`.replace(/\,/, ".");
  const num = parseFloat(value) || 0;
  return num;
};

export const docNumTransform = (value: number, maxCount = 3): string => {
  const idString = value.toString();
  const num = maxCount - idString.length;
  const numOfZeros = Math.sign(num) === 1 ? num : 0;
  return `${"0".repeat(numOfZeros)}${idString}`;
};

export const serijaNumTransform = (info: {
  serija_doc_nr?: number;
  serija?: string;
  acceptanceId?: number;
}): string => {
  const { acceptanceId, serija, serija_doc_nr } = info;
  if (!serija_doc_nr || !serija) {
    return `${serija || ""} ${
      acceptanceId ? docNumTransform(acceptanceId) : "--"
    }`;
  } else {
    const idString = serija_doc_nr.toString();
    const num = 4 - idString.length;
    const numOfZeros = Math.sign(num) === 1 ? num : 0;

    return `Serija ${serija.toUpperCase()} Nr. ${"0".repeat(
      numOfZeros
    )}${idString}`;
  }
};

export const sanitize = (obj: { [key: string]: any }) => {
  let newObj: { [key: string]: any } = {};
  Object.keys(obj).map((key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      if (Array.isArray(obj[key])) {
        if (!!obj[key].length) {
          newObj[key] = obj[key];
        }
      } else if (!!obj[key]) {
        newObj[key] = obj[key];
      }
    }
  });
  return newObj;
};

export const makeLabelInt = (option: option) => {
  return {
    label: parseInt(option.label) || 20,
    value: option.value,
  };
};

export const isLTNum = (value: string) => {
  const numberRegex = /^\d*\,?\d*$/;
  const result = numberRegex.test(value);
  return result;
};

export const isDigit = (value: string): boolean => {
  return /^\d*$/.test(value);
};

export const transform = (
  value: string | number,
  format: boolean = true
): string => {
  value = `${value}`;
  if (format) {
    if (!isNaN(value as unknown as number)) {
      // is number
      try {
        const val = value.toString().replace(/\./g, ",");
        return val;
      } catch {
        return ``;
      }
    } else if (isLTNum(value)) {
      // is formatted correctly
      return value;
    } else {
      // not formatted correctly
      return ``;
    }
  } else {
    return value;
  }
};

export const LT_Months = [
  "sausio",
  "vasario",
  "kovo",
  "balandžio 1 d.",
  "gegužės",
  "birželio",
  "liepos",
  "rugpjūčio",
  "rugsėjo",
  "spalio",
  "lapkričio",
  "gruodžio",
];

export const preferredMunics = [
  "Vilniaus m.",
  "Vilniaus r.",
  "Elektrėnų s.",
  "Širvintų r.",
  "Švenčionių r.",
  "Ukmergės r.",
  "Šalčininkų r.",
  "Trakų r.",
];

export const rearrangeMunic = (options: option[]): option[] => {
  return options.sort((a, b) => {
    const indexA = preferredMunics.indexOf(a.label);
    const indexB = preferredMunics.indexOf(b.label);
    const prefA = preferredMunics.indexOf(a.label) >= 0;
    const prefB = preferredMunics.indexOf(b.label) >= 0;
    if (prefA && prefB) {
      // if both are in the list, arrange according to index on list
      return indexA - indexB;
    } else if (prefA && !prefB) {
      return -1;
    } else if (prefB && !prefA) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const unPreferredRoles = ["Kita"];

export const rearrangeRole = (options: option[]): option[] => {
  return options.sort((a, b) => {
    const indexA = unPreferredRoles.indexOf(a.label);
    const indexB = unPreferredRoles.indexOf(b.label);
    const prefA = unPreferredRoles.indexOf(a.label) >= 0;
    const prefB = unPreferredRoles.indexOf(b.label) >= 0;
    if (prefA && prefB) {
      // if both are in the list, arrange according to index on list
      return indexA - indexB;
    } else if (prefA && !prefB) {
      return 1;
    } else if (prefB && !prefA) {
      return -1;
    } else {
      return 0;
    }
  });
};

export const numToText = (num: number) => {
  const units = [
    "",
    "vienas",
    "du",
    "trys",
    "keturi",
    "penki",
    "šeši",
    "septyni",
    "aštuoni",
    "devyni",
  ];

  const subTen = [
    "",
    "vienuolika",
    "dvylika",
    "trylika",
    "keturiolika",
    "penkiolika",
    "šešiolika",
    "septyniolika",
    "aštuoniolika",
    "devyniolika",
  ];

  const tens = [
    "",
    "dešimt",
    "dvidešimt",
    "trisdešimt",
    "keturiasdešimt",
    "penkiasdešimt",
    "šešiasdešimt",
    "septyniasdešimt",
    "aštuoniasdešimt",
    "devyniasdešimt",
  ];

  const numWord = [
    ["milijonas", "milijonai", "milijonų"],
    ["tūkstantis", "tūkstančiai", "tūkstančių"],
  ];
  if (num < 0 || num >= 10 ** 9) {
    return transform(`${num}`);
  } else {
    const strInt_num = `${parseInt(`${num}`)}`;
    const len = 9 - strInt_num.length;
    const numOfZeros = Math.sign(len) === 1 ? len : 0;
    // Make string to have 9 values. If the length of num is less than 9
    //zeros will fill the left side
    const formatted = `${"0".repeat(numOfZeros)}${strInt_num}`;

    // Divide num into 3 arrays bearing 3 values
    const numTripletArray = formatted.split(/(.{3})/g).filter((N) => N);
    const numSet: string[] = [];
    numTripletArray.forEach((triplet, n) => {
      num = parseInt(triplet) || 0;

      let decimValues = 0;

      const hundDiv = Math.floor(num / 100);

      if (hundDiv > 0) {
        if (hundDiv > 1) {
          numSet.push(units[hundDiv]);
        }
        numSet.push(hundDiv > 1 ? "šimtai" : "šimtas");
      }

      // If num =  is 197
      const subHundNum = num % 100; // 97
      const tensValue = Math.floor(subHundNum / 10); // 9
      const unitValue = subHundNum % 10; // 7

      if (10 < subHundNum && subHundNum < 20) {
        // If num is between 10 and 20
        numSet.push(subTen[unitValue]);
        decimValues = 2;
      } else {
        // Num that is greater than or equal to 20
        if (tensValue > 0) {
          // If there is a tens value
          numSet.push(tens[tensValue]);
        }
        if (unitValue > 0) {
          // If there is a unit value
          numSet.push(units[unitValue]);
          decimValues = unitValue > 1 ? 1 : 0;
        } else {
          decimValues = 2;
        }
      }
      if (n < numWord.length && triplet !== "000") {
        numSet.push(numWord[n][decimValues]);
      }
    });
    let number = numSet.join(" ").trim();
    number = number.replace("  ", " ");
    return number;
  }
};

export const decPlaces = (value: number) => {
  if (Number.isInteger(value)) {
    return 0;
  } else {
    return value.toString().split(".")[1]?.length || 0;
  }
};

const fetchErrorHander = async (response: Response) => {
  if (response.ok) {
    return response;
  } else {
    const errorResponse = await response.json();

    const reload = response.status === 401 && errorResponse.verificationError;
    if (reload) {
      store.dispatch({ type: SIGNOUT });
    }

    throw errorResponse;
  }
};

export const Fetch = async (
  url: string,
  options?: RequestInit,
  removeContentType?: boolean
) => {
  return await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    ...options,
    headers: {
      Authorization: `Bearer ${store.getState().authReducer?.authToken || ""}`,
      ...(removeContentType ? {} : { "Content-Type": "application/json" }),
      ...options?.headers,
    },
  }).then(fetchErrorHander);
};

export const FetchRX = (url: string, options?: RequestInit | undefined) => {
  options = options
    ? {
        ...options,
        ...(options.body
          ? {
              body:
                typeof options.body === "string"
                  ? options.body
                  : JSON.stringify(options.body),
            }
          : {}),
      }
    : options;

  const request = Fetch(url, options);
  type rxjs_operator = OperatorFunction<any, { type: string }>;

  const pipedReactiveResponse = {
    pipe: (...operatorFuncs: [rxjs_operator, rxjs_operator]) => {
      return from(request).pipe(
        mergeMap((response) => response.json()),
        ...operatorFuncs
      );
    },
  };

  return pipedReactiveResponse;
};

export const getWastePrice = (params: {
  price: number | null | undefined;
  priceVat: number | null | undefined;
  direct_vat_calc: boolean;
}): {
  price: number;
  priceVat: number;
} => {
  const { price, priceVat, direct_vat_calc } = params;
  const vatConst = 1.21;

  const newPrice = isNumber(price) ? parseFloat(`${price}`) : 0;
  const newPriceVat = isNumber(priceVat) ? parseFloat(`${priceVat}`) : 0;

  if (direct_vat_calc && newPriceVat) {
    return {
      price: newPriceVat / vatConst,
      priceVat: newPriceVat,
    };
  } else {
    return {
      price: newPrice,
      priceVat: newPrice * vatConst,
    };
  }
};

export const resolve_unit_price = (
  price: number | undefined,
  quantity: number | undefined
): number => {
  price = price || 0;
  quantity = quantity || 0;
  const fix = (val: number) => Number(val.toFixed(2));
  return fix(price * quantity) / quantity;
};

export const defaultOption = { label: "", value: 0 };
export const getUrl = process.env.REACT_APP_API_URL;

export const convertToParamsObject = (paramsString: string): object => {
  if (paramsString.charAt(0) === "?") {
    paramsString = paramsString.slice(1);
  }

  if (!paramsString) {
    return {};
  }
  const params = new URLSearchParams();

  // Split the string by '&' to get individual key-value pairs
  const keyValuePairs = paramsString.split("&");

  // Iterate over the key-value pairs and set them in the params object
  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    params.set(key, value);
  });

  const paramsObject = Object.fromEntries(params);
  return paramsObject;
};

export const convertParamsObjectToString = (
  paramsObject: Record<string, string>
): string => {
  const encodedParams = [];

  for (const key in paramsObject) {
    if (paramsObject.hasOwnProperty(key)) {
      const value = paramsObject[key as keyof typeof paramsObject];
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);

      encodedParams.push(`${encodedKey}=${encodedValue}`);
    }
  }

  return encodedParams.join("&");
};

export const dataURItoBlob = (dataURI: string | null) => {
  if (dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], { type: mimeString });
  } else {
    return ``;
  }
};
