import { connect } from 'react-redux';

import UserGroupForm from './UserGroupForm';
import * as actions from '../../../../../stores/actions';

const mapStoreStateToProps = function (store) {
	return {
    currentUser: store.authReducer.currentUser,
  };
};

const mapDispatchToProps = function (dispatch) {
	return {
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(UserGroupForm);
