import { company, person } from "../../@types/client";
import { municipality } from "../../@types/municipality";
import { accptQueryWasteType } from "../../@types/waste";
import { Fetch } from "../../helpers/misc";

export type acceptance = {
  acceptance: {
    serija_doc_nr: number | null;
    serija: string | null;
    signature: string | null;
    date: string;
    id: number;
    siteId: number;
    isCompany: boolean;
    client: (person & municipality) | (company & municipality);
    wastes: accptQueryWasteType[];
  };
};

export const getClientWasteInfo = async (params: {
  id: string | null;
  isCompany: string | null;
  token: string | null;
}) => {
  const { id, isCompany, token } = params;
  if (!id || !isCompany) {
    throw Error(`Incomplete info provided`);
  } else {
    console.log({ token });
    return await Fetch(
      `/clientWastes/acceptance?id=${id}&isCompany=${isCompany === `true`}`,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : undefined
    )
      .then((res) => {
        const response: Promise<acceptance> = res.json();

        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
};
