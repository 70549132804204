import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { store } from "../..";
import {
  getGroups as get_groups,
  getInactiveGroups as get_inactive_groups,
} from "../actions";
import { FetchRX } from "../../helpers/misc";

export const getGroups = (action$) =>
  action$.pipe(
    ofType(actions.GET_GROUPS),
    mergeMap(() =>
      FetchRX("/groups").pipe(
        map((result) => {
          return {
            type: actions.SET_GROUPS,
            payload: result.groups,
          };
        }),
        catchError((error) => {
          return of({ type: actions.GET_GROUPS_FAILED });
        })
      )
    )
  );

export const getInactiveGroups = (action$) =>
  action$.pipe(
    ofType(actions.GET_INACTIVE_GROUPS),
    mergeMap(() =>
      FetchRX("/groups/inactive").pipe(
        map((result) => {
          return {
            type: actions.SET_INACTIVE_GROUPS,
            payload: result.groups,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const deactivateGroup = (action$) =>
  action$.pipe(
    ofType(actions.DEACTIVATE_GROUP),
    mergeMap((action) =>
      FetchRX("/groups/deactivate", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_groups());
          store.dispatch(get_inactive_groups());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const hideGroup = (action$) =>
  action$.pipe(
    ofType(actions.HIDE_GROUP),
    mergeMap((action) =>
      FetchRX("/groups/hide", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_groups());
          store.dispatch(get_inactive_groups());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const restoreGroup = (action$) =>
  action$.pipe(
    ofType(actions.RESTORE_GROUP),
    mergeMap((action) =>
      FetchRX("/groups/restore", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_groups());
          store.dispatch(get_inactive_groups());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const createGroup = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_GROUP),
    mergeMap((action) =>
      FetchRX("/groups/create", {
        method: "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_GROUPS,
            payload: result.groups,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const editGroup = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_GROUP),
    mergeMap((action) =>
      FetchRX("/groups/edit", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_GROUPS,
            payload: result.groups,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
