import * as actionTypes from "../actionTypes";
import * as statuses from "../../statuses";

const initialState = {
  companies: [],
  company: null,
  savingStatus: statuses.STATUS_IDLE,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case actionTypes.SAVE_COMPANY:
      return {
        ...state,
        savingStatus: statuses.STATUS_IN_PROGRESS,
      };
    case actionTypes.SAVE_COMPANY_SUCCEED:
      return {
        ...state,
        savingStatus: statuses.STATUS_DONE,
        company: action.payload,
      };
    case actionTypes.SAVE_COMPANY_FAILED:
      return {
        ...state,
        savingStatus: statuses.STATUS_IDLE,
      };
    case actionTypes.RESET_COMPANY_SAVING_STATUS:
      return {
        ...state,
        savingStatus: statuses.STATUS_IDLE,
      };
    default:
      return state;
  }
}

export default reducer;
