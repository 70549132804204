import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { site, userSite } from "../../@types/sites";
import {
  childField,
  Header,
  numberField,
  textBox,
} from "../../components/UI/FormGenerator/formField";
import FormGenerator, {
  GenerateForm,
  Input,
} from "../../components/UI/FormGenerator/FormGenerator";
import {
  option,
  pageConstructType,
} from "../../components/UI/FormGenerator/formTypes";
import "./SiteWastes.css";
import { useAsync } from "../../helpers/asyncFunc";
import { defaultOption } from "../../helpers/misc";
import {
  deleteGroupedSiteWastes,
  editDetails,
  editDetailsInv,
  editDetailsVatType,
  editGroupedSiteWastes,
  editGroupFreeLimit,
  getGroupedSiteWastes,
  groupedSiteWastes,
  groupSiteWasteResponse,
} from "./getter";
import DropDown from "../../components/elements/DropDown/DropDown";
import IconContainer from "../../components/UI/iconContainer/iconContainer";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as TickIcon } from "../../assets/images/tick.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/del.svg";
import NewConfirmDialog from "../../components/UI/ConfirmDialog/NewConfirmDialog";
import CheckBox from "../../components/UI/checkBox/checkBox";

const SiteWastes = ({
  sites,
  wasteOpen,
  currentSiteId,
}: {
  sites: site[];
  wasteOpen: boolean;
  currentSiteId: number | null;
}) => {
  const selectedRowId = `grouped__siteWasteSelectedId`;
  const [groupedWastes, setGroupedWastes] = useState<groupSiteWasteResponse>();
  const [checkLoading, setCheckLoading] = useState<
    {
      siteId: number;
      wasteId: number;
      isInvoiceCheck: boolean;
    }[]
  >([]);
  const [siteOptions, setSiteOptions] = useState<userSite[]>([]);
  const [selectedSite, setSelectedSite] = useState<number | null>(
    currentSiteId
  );
  const [editingObj, setEditingObj] = useState<editDetails>();
  const [deleteObj, setDeleteObj] = useState<{
    siteId: number;
    wasteId: number;
  }>();
  const [delLoading, setDelLoading] = useState(false);
  const [groupFreeLimitObj, setGroupFreeLimitObj] = useState<{
    freeLimit: number | null;
    groupId: number;
    siteId: number;
  }>();

  const [dialogVisibility, setDialogVisibility] = useState(false);

  const groupHeaderInputId = `groupHeader__InputId`;

  useEffect(() => {
    if (sites) {
      const options: userSite[] = [];
      sites.forEach((site) => {
        options.push({ value: site.id, label: site.name });
      });
      setSiteOptions(options);
    }
  }, [sites]);

  useEffect(() => {
    setCheckLoading([]);
  }, []);

  const groupWastesRes = useAsync(
    {
      asyncFunc: getGroupedSiteWastes,
      funcParams: { id: selectedSite },
      immediate: true,
    },
    [selectedSite, wasteOpen]
  );

  useEffect(() => {
    const groupedWastes = groupWastesRes.data;

    if (groupedWastes) {
      setGroupedWastes({ ...groupedWastes });
    } else {
      setGroupedWastes(groupedWastes);
    }
  }, [groupWastesRes.data]);

  const mountedRef = useRef(true);
  useEffect(() => {
    const outsideActiveRowClick = (e: MouseEvent) => {
      const target = e.target as Node;
      const element = document.getElementById(selectedRowId);
      const editingElement = document.getElementById(groupHeaderInputId);

      if (element && !element.contains(target)) {
        setEditingObj(undefined);
      }

      if (editingElement && !editingElement.contains(target)) {
        setGroupFreeLimitObj(undefined);
      }
    };

    document.addEventListener("click", outsideActiveRowClick);
    return () => {
      document.addEventListener("click", outsideActiveRowClick);
      mountedRef.current = false;
    };
  }, []);

  const saveGroupWaste = () => {
    const obj = editingObj;
    if (obj) {
      setEditingObj(undefined);
      editGroupedSiteWastes(obj)
        .then((res) => {
          if (mountedRef.current && res) {
            setGroupedWastes({ ...res });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function saveCompInvoice(compObj: editDetailsInv, isInvoiceCheck: true): void;
  function saveCompInvoice(
    compObj: editDetailsVatType,
    isInvoiceCheck: false
  ): void;
  function saveCompInvoice(
    compObj: editDetailsInv | editDetailsVatType,
    isInvoiceCheck: boolean
  ) {
    setCheckLoading([
      ...checkLoading,
      {
        siteId: compObj.siteId,
        wasteId: compObj.wasteId,
        isInvoiceCheck: isInvoiceCheck,
      },
    ]);

    const newLoadingArray = checkLoading.filter((obj) => {
      if (obj.siteId === compObj.siteId && obj.wasteId === compObj.wasteId) {
        return false;
      } else {
        return true;
      }
    });

    editGroupedSiteWastes(compObj)
      .then((res) => {
        if (mountedRef.current && res) {
          setCheckLoading([...newLoadingArray]);
          setGroupedWastes({ ...res });
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          setCheckLoading([...newLoadingArray]);
        }
        console.log(err);
      });
  }

  const deleteSiteWaste = () => {
    setDelLoading(true);

    const obj = deleteObj;
    if (obj) {
      setDeleteObj(undefined);
      deleteGroupedSiteWastes(obj)
        .then((res) => {
          if (mountedRef.current && res) {
            setDelLoading(false);
            setDialogVisibility(false);
            setGroupedWastes({ ...res });
          }
        })
        .catch((err) => {
          setDelLoading(false);
          setDialogVisibility(false);
          console.log(err);
        });
    } else {
      return;
    }
  };

  const siteValue = (id: number | null): option => {
    if (id) {
      const option = siteOptions.find((opt) => opt.value === id);
      return option || defaultOption;
    } else {
      return defaultOption;
    }
  };

  const dist = [0.7, `3rem`, 3, 1, 1, 1, 1, 1, `5rem`, `6rem`];

  const editGroupLimit = useAsync({
    asyncFunc: editGroupFreeLimit,
    funcParams: groupFreeLimitObj as {
      freeLimit: number | null;
      siteId: number;
      groupId: number;
    },
    immediate: false,
  });

  useEffect(() => {
    const groupedWastes = editGroupLimit.data;

    if (groupedWastes) {
      setGroupedWastes({ ...groupedWastes });
    } else {
      setGroupedWastes(groupedWastes);
    }
    setGroupFreeLimitObj(undefined);
  }, [editGroupLimit.data]);

  const limitString = (value: number | null | undefined): string => {
    const isNull = value === null || value === undefined;

    if (isNull) {
      return ``;
    } else {
      return `${value}`;
    }
  };

  type numberNull = number | null;
  const isNumber = (value: number | string | null) => {
    value = parseFloat(`${value}`);

    return !isNaN(value) && typeof value === "number";
  };

  const freeError = (freeValue: numberNull, accumLimit: numberNull) => {
    let allowed = true;
    const canCompareLimit = isNumber(freeValue) && isNumber(accumLimit);

    if (canCompareLimit) {
      allowed = freeValue! <= accumLimit!;
    }

    return !allowed;
  };

  const isEditing = (
    wasteId: number,
    groupId: number | null,
    siteId: number | null
  ) => {
    return !!(
      editingObj &&
      editingObj.groupId === groupId &&
      editingObj.siteId === siteId &&
      editingObj.wasteId === wasteId
    );
  };

  const pageConstruct = (
    wastesObj: groupedSiteWastes
  ): (pageConstructType & { id: number })[] => {
    const siteWastes = wastesObj.siteWastes;
    const siteId = selectedSite!;
    const groupId = wastesObj.groupId;

    return siteWastes.map((waste, n) => {
      const id = waste.wasteId;
      const editing = isEditing(id, groupId, siteId);

      const freeString = limitString(waste.freeLimit)
        ? `${limitString(waste.freeLimit)}`
        : `-`;
      const maxString = limitString(waste.maxLimit)
        ? `${limitString(waste.maxLimit)}`
        : `-`;
      const priceString = limitString(waste.price)
        ? `${limitString(waste.price)}`
        : `-`;
      const priceVatString = limitString(waste.priceVat)
        ? `${limitString(waste.priceVat)}`
        : `-`;

      const isCheckLoading = !!checkLoading.find((i) => {
        return i.siteId === siteId && i.wasteId === id && i.isInvoiceCheck;
      });

      const isVatCheckLoading = !!checkLoading.find((i) => {
        return i.siteId === siteId && i.wasteId === id && !i.isInvoiceCheck;
      });

      const fEditingLimit = editing ? editingObj?.info.freeLimit || null : null;
      const mEditingLimit = editing ? editingObj?.info.maxLimit || null : null;
      const pEditingLimit = editing ? editingObj?.info.price || null : null;
      const pVEditingLimit = editing ? editingObj?.info.priceVat || null : null;

      const freeValue = limitString(fEditingLimit);
      const maxLValue = limitString(mEditingLimit);
      const priceValue = limitString(pEditingLimit);
      const priceVatValue = limitString(pVEditingLimit);

      const fError = freeError(fEditingLimit, waste.accumulationLimit);

      return {
        id,
        sizeDist: dist,
        typeDist: [
          textBox(waste.code, false),
          textBox(`${waste.wasteId}`, false),
          textBox(waste.waste, false),
          textBox(`kg`, false),

          groupId
            ? childField(<div key={`freeGroupLimit${n}`}>-</div>)
            : editing
            ? numberField(
                "Nemokamas metinis limitas",
                fError,
                () => {},
                freeValue,
                (value) => {
                  setEditingObj({
                    ...editingObj!,
                    info: {
                      ...editingObj!.info,
                      freeLimit: isNumber(value)
                        ? (value as unknown as number)
                        : null,
                    },
                  });
                }
              )
            : textBox(freeString, false),

          editing
            ? numberField(
                "Didžiausias vienu metu galimas pristatyti kiekis",
                false,
                () => {},
                maxLValue,
                (value) => {
                  setEditingObj({
                    ...editingObj!,
                    info: {
                      ...editingObj!.info,
                      maxLimit: isNumber(value)
                        ? (value as unknown as number)
                        : null,
                    },
                  });
                }
              )
            : textBox(maxString, false),

          editing
            ? numberField(
                "Įkainis be PVM",
                false,
                () => {},
                priceValue,
                (value) => {
                  setEditingObj({
                    ...editingObj!,
                    info: {
                      ...editingObj!.info,
                      price: isNumber(value)
                        ? (value as unknown as number)
                        : null,
                    },
                  });
                }
              )
            : textBox(priceString, false),

          editing
            ? numberField(
                "Įkainis su PVM",
                false,
                () => {},
                priceVatValue,
                (value) => {
                  setEditingObj({
                    ...editingObj!,
                    info: {
                      ...editingObj!.info,
                      priceVat: isNumber(value)
                        ? (value as unknown as number)
                        : null,
                    },
                  });
                }
              )
            : childField(
                <div
                  style={{ gap: 12 }}
                  className={`flex ${
                    isVatCheckLoading ? `disableCheckSiteWaste` : ``
                  }`}
                  key={`direct_vat${n}`}
                >
                  <CheckBox
                    isChecked={!!waste.direct_vat_calc}
                    readOnly={isVatCheckLoading}
                    onChange={(e) => {
                      saveCompInvoice(
                        {
                          groupId,
                          siteId,
                          wasteId: id,
                          info: { direct_vat_calc: !waste.direct_vat_calc },
                        },
                        false
                      );
                    }}
                  />
                  {priceVatString}
                </div>
              ),
          childField(
            <div
              className={`accpt_waste_removal_btn_wrapper stWastesCheck ${
                isCheckLoading ? `disableCheckSiteWaste` : ``
              }`}
              key={`invoice__sitewaste_${n}`}
            >
              <CheckBox
                isChecked={!!waste.compInvoice}
                readOnly={isCheckLoading}
                onChange={(e) => {
                  saveCompInvoice(
                    {
                      groupId,
                      siteId,
                      wasteId: id,
                      info: { compInvoice: !waste.compInvoice },
                    },
                    true
                  );
                }}
              />
            </div>
          ),
          childField(
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              key={n}
              className="flex1 jus_flex_end"
            >
              <button
                className={`btn btn-green ${
                  editing ? "showSiteEdit" : "hideSiteEdit"
                }`}
                disabled={fError}
                onClick={() => {
                  if (fError) {
                    return;
                  } else {
                    saveGroupWaste();
                  }
                }}
              >
                Išsaugoti
              </button>
              <div
                className={`flex1 jus_flex_end ${
                  !editing ? "showSiteEdit" : "hideSiteEdit"
                }`}
              >
                <IconContainer size="lg">
                  <EditIcon
                    onClick={(e) => {
                      setEditingObj({
                        groupId,
                        siteId,
                        wasteId: id,
                        info: {
                          freeLimit: waste.freeLimit,
                          maxLimit: waste.maxLimit,
                          price: waste.price,
                          priceVat: waste.priceVat,
                        },
                      });
                    }}
                  />
                </IconContainer>
                <IconContainer size="lg">
                  <DeleteIcon
                    onClick={() => {
                      setDeleteObj({
                        siteId,
                        wasteId: id,
                      });
                      setDialogVisibility(true);
                    }}
                  />
                </IconContainer>
              </div>
            </div>
          ),
          childField(
            editing ? (
              fError ? (
                <div
                  key={`error_msg_${n}`}
                  className="site_limit_error_wrapper stwasteError"
                >
                  <div className="site_limit_error_container">
                    {fError ? (
                      <>
                        <p>
                          Accumulation limit:{" "}
                          {waste.accumulationLimit || <>&infin;</>}
                        </p>
                        <div>
                          Free limit should not be more than the accumulation
                          limit
                        </div>
                      </>
                    ) : (
                      ``
                    )}
                  </div>
                </div>
              ) : (
                <React.Fragment key={`error_msg_${n}`}></React.Fragment>
              )
            ) : (
              <React.Fragment key={`error_msg_${n}`}></React.Fragment>
            )
          ),
        ],
        hasHeader: false,
        headerText: [],
      };
    });
  };

  const GroupHeadConstruct = (
    wasteObj: groupedSiteWastes
  ): pageConstructType => {
    const siteId = selectedSite!;
    const editing =
      groupFreeLimitObj &&
      groupFreeLimitObj.groupId === wasteObj.groupId &&
      groupFreeLimitObj.siteId === siteId;

    return {
      sizeDist: [1],
      typeDist: [],
      hasHeader: true,
      headerText: [
        Header(
          [
            <div
              className={`groupHead_text_wrapper ${editing ? "editing" : ""}`}
            >
              <div className="groupHead_text flex1">{wasteObj.groupName}</div>
              <div className="groupHead_input_wrapper">
                <span>Nemokamas metinis limitas:</span>
                <div id={editing ? groupHeaderInputId : undefined}>
                  <Input
                    key={`waste_freelimit`}
                    value={``}
                    disabled={!editing}
                    placeholder={""}
                    handler={() => {}}
                    error={false}
                    type={"number"}
                    externalValue={
                      editing
                        ? `${groupFreeLimitObj.freeLimit || ""}`
                        : `${wasteObj.groupFreeLimit || ""}`
                    }
                    iconClick={() => {
                      if (editing) {
                        if (groupFreeLimitObj) {
                          const freeLimit = isNumber(
                            groupFreeLimitObj.freeLimit
                          )
                            ? groupFreeLimitObj.freeLimit
                            : null;
                          editGroupLimit.execute({
                            ...groupFreeLimitObj,
                            freeLimit,
                          });
                        }
                      } else {
                        setGroupFreeLimitObj({
                          freeLimit: wasteObj.groupFreeLimit,
                          groupId: wasteObj.groupId!,
                          siteId,
                        });
                      }
                    }}
                    icon={
                      <IconContainer>
                        <>
                          <TickIcon
                            className={`site_waste_edit_group_fl tick ${
                              editing ? "" : "inactive"
                            }`}
                          />
                          <EditIcon
                            className={`site_waste_edit_group_fl edit ${
                              editing ? "inactive" : ""
                            }`}
                          />
                        </>
                      </IconContainer>
                    }
                    setExternalValue={(freeLimit) => {
                      if (editing) {
                        setGroupFreeLimitObj({
                          ...groupFreeLimitObj,
                          freeLimit: isNumber(freeLimit)
                            ? (freeLimit as unknown as number)
                            : null,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>,
          ],
          false,
          true
        ),
      ],
    };
  };

  const headConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [],
      hasHeader: true,
      headerText: [
        Header(["Kodas"], false, true),
        Header(["ID"], false, true),
        Header(["Atlieka"], false, true),
        Header(["Mato vienetas"], false, true),
        Header(["Nemokamas metinis limitas"], false, true),
        Header(
          ["Didžiausias vienu metu galimas pristatyti kiekis"],
          false,
          true
        ),
        Header(["Įkainis be PVM"], false, true),
        Header(["Įkainis su PVM"], false, true),
        Header(["Privaloma sąskaita"], false, true),
      ],
    },
  ];

  const groupedArray = groupedWastes?.siteWastes.groupedWastes || [];

  return (
    <div>
      {dialogVisibility && (
        <NewConfirmDialog
          txt="Ar tikrai norite pašalinti šią atlieką iš atliekų sąrašo"
          isVisible={dialogVisibility}
          onExit={() => {
            setDialogVisibility(false);
            setDeleteObj(undefined);
          }}
          isLoading={delLoading}
          onContinue={() => {
            deleteSiteWaste();
          }}
        />
      )}
      <div className="siteLimit_drop_wrapper">
        <p>Pasirinkite aikštelę</p>
        <DropDown
          error={false}
          border=""
          onSelect={(option) => {
            setSelectedSite(option.value);
          }}
          options={siteOptions}
          title="Ieškoti..."
          value={siteValue(selectedSite)}
        />
      </div>
      <div className="viewExt">
        <GenerateForm classNameWrap="groupWaste_header">
          {headConstruct.map((construct, n) => (
            <FormGenerator
              key={n}
              gridSizeDist={construct.sizeDist}
              gridTypeDist={construct.typeDist}
              hasHeader={construct.hasHeader}
              headerText={construct.headerText}
            />
          ))}
        </GenerateForm>

        {groupedArray.map((wastesObj, n) => (
          <div key={n}>
            <GenerateForm classNameWrap="site_waste_table">
              {wastesObj.groupName
                ? [GroupHeadConstruct(wastesObj)].map((construct, n) => (
                    <FormGenerator
                      key={n}
                      gridSizeDist={construct.sizeDist}
                      gridTypeDist={construct.typeDist}
                      hasHeader={construct.hasHeader}
                      headerText={construct.headerText}
                    />
                  ))
                : [<React.Fragment key={n}></React.Fragment>]}
              {pageConstruct(wastesObj).map((construct, n) => (
                <FormGenerator
                  key={`groupWaste_${n}`}
                  id={
                    isEditing(construct.id, wastesObj.groupId, selectedSite)
                      ? selectedRowId
                      : undefined
                  }
                  gridSizeDist={construct.sizeDist}
                  gridTypeDist={construct.typeDist}
                  hasHeader={construct.hasHeader}
                  headerText={construct.headerText}
                />
              ))}
            </GenerateForm>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SiteWastes;
