import React, { useEffect, useState } from "react";
import moment from "moment";

import { STATUS_DONE } from "../../../../../statuses";

import "./style.scss";
import "./UserList.css";
import {
  option,
  pageConstructType,
} from "../../../../UI/FormGenerator/formTypes";
import {
  childField,
  dropField,
  Header,
  textField,
  textBox,
  multiDropField,
} from "../../../../UI/FormGenerator/formField";
import FormGenerator, {
  GenerateForm,
} from "../../../../UI/FormGenerator/FormGenerator";
import UserDetail from "../UserDetail";
import IconContainer from "../../../../UI/iconContainer/iconContainer";
import { ReactComponent as EditLogo } from "../../../../../assets/images/edit.svg";
import { userData } from "../UserDetail/UserDetail";
import { userFormData } from "../../../../../@types/user";
import DiscreteRangeDatePicker from "../../../../UI/DiscreteRangePicker/DiscreteRangePicker";
import { range } from "../../../../../pages/Report/@types";
import FractionList from "../../../../UI/fractionList/FractionList";
import { makeLabelInt, pageSettingOptions } from "../../../../../helpers/misc";
import { DataExportService } from "../../../../../services";
import { getData, getXlsxObj } from "../../dataExportFormat";
import { useLocation, useResolvedPath } from "react-router-dom";
import { useAsync } from "../../../../../helpers/asyncFunc";
import { getUsers } from "./getter";
import { rootFilterType } from "../../UserManagement";

const activeStatuses = [
  { label: "Aktyvi", value: 0 },
  { label: "Neaktyvi", value: 1 },
];

const statusArray: ("active" | "inactive")[] = ["active", "inactive"];

export type user = Omit<userFormData, "sites" | "userSiteId"> & {
  lastLogin: string | null;
  isActive: boolean;
  isDeleted: boolean;
  roleName: string;
};

const UserList = function ({
  roles,
  userSavingStatus,
  resetUserStatus,
  globalKeywords,
  userFormSave,
  onCancel,
  retrieve,
  xlsxExport,
  setRootFilter,
  rootFilter,
}: {
  roles: { name: string; id: number }[];
  userSavingStatus: number;
  resetUserStatus: () => void;
  globalKeywords: string | null;
  userFormSave: (type: "new" | "edit", user: userData) => void;
  onCancel: () => void;
  retrieve: number | undefined;
  xlsxExport: (func: any) => void;
  setRootFilter: (data: rootFilterType) => void;
  rootFilter: rootFilterType;
}) {
  const url = useResolvedPath("").pathname;
  const { pathname } = useLocation();
  const dataExportService = new DataExportService();

  type userListFilter = {
    uname: string;
    fullName: string;
    email: string;
    position: string;
    accessLevel: string;
    activeStatus: "active" | "inactive" | "";
    roleId: option[];
    lastLogin: {
      startDate: string;
      endDate: string;
    };
    all: string;
  };
  const defaultFilter: userListFilter = {
    uname: "",
    fullName: "",
    email: "",
    position: "",
    accessLevel: "",
    activeStatus: "",
    roleId: [],
    lastLogin: {
      startDate: "",
      endDate: "",
    },
    all: globalKeywords || "",
  };

  const [userDetailId, setUserDetailId] = useState<number | null>(null);

  const onDetailClick = (id: number) => {
    setUserDetailId(id);
  };
  const [roleOptions, setRoleOptions] = useState<option[]>([]);

  const [showDetails, setShowDetails] = useState(false);
  const [filter, setFilter] = useState<userListFilter>({
    ...defaultFilter,
  });
  const [currentStart, setCurrentPage] = useState(1);
  const [users, setUsers] = useState<user[]>([]);
  const [count, setCount] = useState(0);

  const [pageRecordNum, setPageRecordNum] = useState(
    makeLabelInt(pageSettingOptions[1])
  );
  const limit = pageRecordNum.label;
  const offset = currentStart - 1;

  const xlsx = () => {
    const { description, title } = getXlsxObj("USER_LIST");
    dataExportService.init(title, description);

    const rows = getData({
      type: "USER_LIST",
      records: users,
    });

    dataExportService.export(rows);
  };

  const canExport = retrieve && url === pathname;
  useEffect(() => {
    if (canExport) {
      xlsxExport(xlsx);
    }
  }, [retrieve]);

  const Filter = (() => {
    return Object.keys(filter)
      .map((k) => {
        const multiFilterKeys: (keyof userListFilter)[] = ["roleId"];

        const key = k as keyof userListFilter;
        if (key === "lastLogin") {
          if (filter.lastLogin.startDate && filter.lastLogin.endDate) {
            return `startDate=${filter.lastLogin.startDate}&endDate=${filter.lastLogin.endDate}`;
          }

          return "startDate=&endDate=";
        } else if (multiFilterKeys.indexOf(key) >= 0) {
          return (
            key + "=" + (filter[key] as option[]).map((i) => i.value).join(",")
          );
        } else {
          return key + "=" + filter[key];
        }
      })
      .join("&");
  })();

  useEffect(() => {
    setRootFilter({
      ...rootFilter,
      userList: Filter,
    });
  }, [Filter]);

  const userRes = useAsync(
    {
      asyncFunc: getUsers,
      funcParams: { query: Filter, limit, offset, hide: !!showDetails },
      immediate: true,
      clearOnError: true,
    },
    [Filter, limit, offset, showDetails]
  );

  useEffect(() => {
    const users = userRes.data?.users || [];
    const count = userRes.data?.count || 0;

    setUsers([...users]);
    setCount(count);
  }, [userRes.data]);

  useEffect(() => {
    setFilter({ ...filter, all: globalKeywords ? globalKeywords : "" });
  }, [globalKeywords]);

  useEffect(() => {
    if (roles) {
      const options = roles.map((role) => ({
        label: role.name,
        value: role.id,
      }));
      setRoleOptions([...options]);
    }
  }, [roles]);

  useEffect(() => {
    if (userSavingStatus === STATUS_DONE) {
      userRes.execute({ hide: false, limit, offset, query: Filter });
      resetUserStatus();
    }
  }, [userSavingStatus]);

  const updateFilter = (objFilter: Partial<userListFilter>) => {
    setFilter({ ...filter, ...objFilter, all: "" });
  };

  const dist = [1, 1, 1, 1, 1.5, 1, 1.5, `5.2rem`];
  const pageConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [
        textField("Nuo...", false, (txt) => updateFilter({ uname: txt })),
        textField("Nuo...", false, (txt) => updateFilter({ fullName: txt })),
        textField("Nuo...", false, (txt) => updateFilter({ email: txt })),
        textField("Nuo...", false, (txt) => updateFilter({ position: txt })),
        multiDropField(
          roleOptions,
          "Ieškoti...",
          false,
          filter.roleId,
          (options) => {
            updateFilter({ roleId: options });
          }
        ),
        dropField(
          activeStatuses,
          "Ieškoti...",
          false,
          { label: "", value: 0 },
          (val) => {
            updateFilter({ activeStatus: statusArray[val] });
          }
        ),
        childField(
          <div
            className="userList_range_wrapper"
            key={"userList_range_wrapper"}
          >
            <DiscreteRangeDatePicker
              key={"userList_range"}
              onChange={(val: range) => {
                if (val.startDate || val.startDate) {
                  updateFilter({
                    lastLogin: {
                      startDate: val.startDate
                        ? moment(new Date(val.startDate)).format("YYYY-MM-DD")
                        : "",
                      endDate: val.endDate
                        ? moment(new Date(val.endDate)).format("YYYY-MM-DD")
                        : "",
                    },
                  });
                }
              }}
              clear={!filter.lastLogin.startDate && !filter.lastLogin.endDate}
              value={undefined}
            />
          </div>
        ),
      ],
      hasHeader: true,
      headerText: [
        Header(["Darbuotojas"], false),
        Header(["Vardas ir pavardė"], false),
        Header(["El. p. adresas"], false),
        Header(["Pareigos"], false),
        Header(["Darbuotojo tipas sistemoje (rolė)"], false),
        Header(["Aktyvumas"], false),
        Header(["Paskutinis prisijungimas"], false),
      ],
    },
  ];

  const textConstruct: pageConstructType[] = users.map(
    (user): pageConstructType => ({
      sizeDist: dist,
      typeDist: [
        textBox(user.uname, false),
        textBox(`${user.firstName} ${user.lastName}`, false),
        textBox(`${user.email || `-`}`, false),
        textBox(user.position || `-`, false),
        textBox(user.roleName || `-`, false),
        textBox(user.isActive ? "Aktyvi" : "Neaktyvi", true),
        textBox(
          user.lastLogin
            ? moment(user.lastLogin).format("YYYY-MM-DD HH:mm")
            : "",
          true
        ),
        childField(
          <div
            key={"userList_btn_container"}
            className="userList_btn_container"
          >
            <div
              onClick={() => {
                onDetailClick(user.id);
                setShowDetails(true);
              }}
            >
              <IconContainer size="lg">
                <EditLogo />
              </IconContainer>
            </div>
          </div>
        ),
      ],
      hasHeader: false,
      headerText: [],
    })
  );

  return (
    <div className="user_list">
      {showDetails ? (
        <UserDetail
          userId={userDetailId}
          onCancel={() => {
            setShowDetails(false);
            onCancel();
          }}
          saveUser={(type, user) => {
            setShowDetails(false);
            userFormSave(type, user);
          }}
        />
      ) : (
        <>
          <div className="viewExt">
            <GenerateForm loading={userRes.loading}>
              {pageConstruct.map((construct, n) => (
                <FormGenerator
                  key={`userList_form_1_${n}`}
                  gridSizeDist={construct.sizeDist}
                  gridTypeDist={construct.typeDist}
                  hasHeader={construct.hasHeader}
                  headerText={construct.headerText}
                />
              ))}
              {textConstruct.map((construct, n) => (
                <FormGenerator
                  key={`userList_form_2_${n}`}
                  gridSizeDist={construct.sizeDist}
                  gridTypeDist={construct.typeDist}
                  hasHeader={construct.hasHeader}
                  headerText={construct.headerText}
                />
              ))}
            </GenerateForm>
            <FractionList
              count={count}
              currentStart={currentStart}
              setCurrentPage={setCurrentPage}
              pageRecordNum={pageRecordNum}
              setPageRecordNum={setPageRecordNum}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default UserList;
