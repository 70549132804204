import * as actionTypes from "../actionTypes";

import * as statuses from "../../statuses";

const initialState = {
  person: null,
  savingStatus: statuses.STATUS_IDLE,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_PERSON:
      return {
        ...state,
        savingStatus: statuses.STATUS_IN_PROGRESS,
      };
    case actionTypes.SAVE_PERSON_SUCCEED:
      return {
        ...state,
        savingStatus: statuses.STATUS_DONE,
        person: action.payload,
      };
    case actionTypes.SAVE_PERSON_FAILED:
      return {
        ...state,
        savingStatus: statuses.STATUS_IDLE,
      };
    case actionTypes.RESET_PERSON_SAVING_STATUS:
      return {
        ...state,
        savingStatus: statuses.STATUS_IDLE,
      };
    default:
      return state;
  }
}

export default reducer;
