import React, { useEffect, useState } from "react";
import { useAsync } from "./asyncFunc";
import { Fetch } from "./misc";
import { useInterval } from "./useInterval";
import { store } from "..";

const getVersion = async (param: { id?: number }) => {
  return await Fetch(`/version?id=${param.id || ""}`)
    .then((res) => {
      const response = res.json() as Promise<{
        success: true;
        version: string;
      }>;
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

const useVersion = () => {
  const version = process.env.REACT_APP_CURRENT_GIT_SHA;
  const versionLocal = sessionStorage.getItem("dyanamic_version") || null;
  const [count, setCount] = useState(0);

  const time = 1000 * 60 * 10;

  useInterval(
    {
      func: () => {
        setCount(count + 1);
      },
      time,
      call: true,
    },
    [count]
  );

  const operatorId = store.getState().authReducer?.currentUser?.id;

  const versionRes = useAsync(
    {
      asyncFunc: getVersion,
      funcParams: { id: operatorId },
      immediate: true,
      clearOnError: true,
      canCall: !!operatorId,
    },
    [count, operatorId]
  );

  useEffect(() => {
    if (versionRes.called) {
      const vv = versionRes.data?.version || null;

      if (vv !== version) {
        sessionStorage.setItem("dyanamic_version", vv || "");
        if (vv != versionLocal) {
          // @ts-ignore
          window.location.reload(true);
        }
      }
    }
  }, [versionRes.data]);
};

export default useVersion;
