import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { FetchRX } from "../../helpers/misc";

export const saveWasteActivity = (action$) =>
  action$.pipe(
    ofType(actions.SAVE_WASTE_ACTIVITY),
    mergeMap((action) =>
      FetchRX("/wasteActivities", {
        method: action.payload.isEdit ? "PUT" : "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SAVE_WASTE_ACTIVITY_SUCCEED,
            payload: result.wasteActivity,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.SAVE_WASTE_ACTIVITY_FAILED });
        })
      )
    )
  );
