import React, { useState, useEffect } from "react";

import { UserActivity, UserForm, UserList, UserGroupList } from "./elements";
import { ReactComponent as PlusIcon } from "../../../assets/images/plusBtn.svg";

import "./style.scss";
import IconContainer from "../../UI/iconContainer/iconContainer";
import {
  Link,
  Route,
  useNavigate,
  useLocation,
  useResolvedPath,
  Routes,
} from "react-router-dom";
import { Input } from "../../UI/FormGenerator/FormGenerator";
import { userData } from "./elements/UserForm/UserForm";
import { userData as userData2 } from "./elements/UserDetail/UserDetail";
import ExcelDownload from "../../UI/DownloadPopUp/DownloadPopUp";
import { downloadType } from "../../../helpers/downloadHook";

export type rootFilterType = Partial<{ [key in downloadType]: string }>;

const UserManagement = function ({
  saveUser,
}: {
  saveUser: (type: "edit" | "new", user: userData | userData2) => void;
}) {
  const navigate = useNavigate();
  const replace = (path: string) => navigate(path, { replace: true });
  const url = useResolvedPath("").pathname;
  const { pathname } = useLocation();

  const [globalKeywords, setGlobalKeywords] = useState<string | null>(null);
  const [retrieveXlsx, setRetrieveXlsx] = useState<number>();
  const [excelType, setExcelType] = useState<downloadType>();
  const [filter, setFilter] = useState<rootFilterType>({
    userList: "",
    userActivities: "",
    userGroups: "",
  });

  const urls = [`${url}`, `${url}/activities`, `${url}/groups`];
  const exportable = () => {
    return urls.indexOf(pathname) >= 0;
  };

  const getType = (): downloadType | undefined => {
    switch (pathname) {
      case url: {
        return "userList";
      }
      case `${url}/activities`: {
        return "userActivities";
      }
      case `${url}/groups`: {
        return "userGroups";
      }

      default: {
        return undefined;
      }
    }
  };

  const type = getType();

  useEffect(() => {
    if (urls.indexOf(pathname) >= 0) {
      setExcelType(type);
    }
  }, [pathname]);

  const userFormSave = (type: "edit" | "new", user: userData | userData2) => {
    saveUser(type, user);
    replace(`${url}`);
  };

  const userDetailOnCancel = () => {
    replace(`${url}`);
  };

  const xlsxExport = (func: () => void) => {
    func();
    setRetrieveXlsx(undefined);
  };

  useEffect(() => {
    return () => {
      setRetrieveXlsx(undefined);
    };
  }, []);

  return (
    <div className="user-management">
      <div className="tab-content-header">
        <div className="table-actions">
          <div className="button_navigation">
            <Link to={`${url}`} className="userMgtNavLink">
              <button
                className={`flexBtn tile ${
                  pathname === `${url}` ? "btn-green" : "btn-default"
                }`}
              >
                Sąrašas
              </button>
            </Link>
            <Link to={`${url}/activities`} className="userMgtNavLink">
              <button
                className={`flexBtn tile ${
                  pathname === `${url}/activities` ? "btn-green" : "btn-default"
                }`}
              >
                Veiklos žurnalas
              </button>
            </Link>
            <Link to={`${url}/groups`} className="userMgtNavLink">
              <button
                className={`flexBtn tile ${
                  pathname === `${url}/groups` ? "btn-green" : "btn-default"
                }`}
              >
                Darbo grupės
              </button>
            </Link>
            <Link to={`${url}/create`} className="userMgtNavLink">
              <button
                className={`flexBtn spbtw tile ${
                  pathname === `${url}/create` ? "btn-green" : "btn-default"
                }`}
              >
                <IconContainer size="lg">
                  <PlusIcon className="userMgt_plus" />
                </IconContainer>
                <span>Nauja paskyra</span>
              </button>
            </Link>
          </div>
          <Input
            error={false}
            handler={() => {}}
            className={"globalKeyword_input_wrapper"}
            placeholder="Ieškoti..."
            type="text"
            value=""
            externalValue={globalKeywords || ""}
            setExternalValue={(txt) => setGlobalKeywords(txt)}
          />

          <ExcelDownload
            type={excelType}
            query={type ? filter[type] : ""}
            noRange={type === "userGroups"}
          />
          {/* <ButtonXlsx
            disabled={!exportable()}
            onClick={() => {
              if (exportable()) {
                setRetrieveXlsx(new Date().getTime());
              }
            }}
          /> */}
        </div>
      </div>
      <Routes>
        <Route
          path={""}
          // path={path}
          element={
            <UserList
              setRootFilter={setFilter}
              rootFilter={filter}
              xlsxExport={xlsxExport}
              retrieve={retrieveXlsx}
              globalKeywords={globalKeywords}
              userFormSave={userFormSave}
              onCancel={userDetailOnCancel}
            />
          }
        />

        <Route
          path={"/activities"}
          element={
            <UserActivity
              setRootFilter={setFilter}
              rootFilter={filter}
              xlsxExport={xlsxExport}
              retrieve={retrieveXlsx}
              globalKeywords={globalKeywords}
            />
          }
        />
        <Route
          path={"groups"}
          element={
            <UserGroupList xlsxExport={xlsxExport} retrieve={retrieveXlsx} />
          }
        />
        <Route path={"create"} element={<UserForm saveUser={userFormSave} />} />
      </Routes>
    </div>
  );
};

export default UserManagement;
