import { connect } from "react-redux";

import WasteDisposal from "./WasteDisposal";

const mapStoreStateToProps = function (store) {
  return {
    wasteActivityTypes: store.wasteActivityTypeReducer.wasteActivityTypes,
    wastes: store.wasteReducer.wastes,
    formations: store.formationReducer.formations,
    materials: store.materialReducer.materials,
    primarySources: store.primarySourceReducer.primarySources,
  };
};

export default connect(mapStoreStateToProps)(WasteDisposal);
