import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { InputText } from "../../components/UI";
import { useAsync } from "../../helpers/asyncFunc";
import { signIn } from "./signInHandler";

import "./style.scss";

const SignIn = function ({ setLoggedUser, signOut }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [uname, setUname] = useState("");
  const [paswd, setPaswd] = useState("");
  const [unameError, setUnameError] = useState(false);
  const [paswdError, setPaswdError] = useState(false);
  const [msg, setMsg] = useState("");

  const signInRes = useAsync(
    {
      asyncFunc: signIn,
      funcParams: {},
      immediate: false,
      clearOnError: true,
    },
    []
  );

  useEffect(() => {
    const data = signInRes.data?.authInfo;
    if (data) {
      setLoggedUser(data);

      const link = location.state?.from;
      navigate(link && link.pathname !== "/signin" ? { ...link } : "/");
    }
  }, [signInRes.data]);

  useEffect(() => {
    const error = signInRes.error;
    const errorObj = signInRes.errorObject;
    if (errorObj || error) {
      const inactive = Object(errorObj).inactive;
      signOut();

      setMsg(
        inactive
          ? "Prieiga prie jūsų paskyros išjungta"
          : "Jūsų įvestas darbuotojo slapyvardis arba slaptažodis yra neteisingas"
      );
    }
  }, [signInRes.error]);

  useEffect(() => {
    setMsg("");
    setUnameError(false);
    setPaswdError(false);
  }, [uname, paswd]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!uname) {
      setMsg("Username cannot be empty");
      setUnameError(true);
      return;
    }

    if (!paswd) {
      setMsg("Password cannot be empty");
      setPaswdError(true);
      return;
    }

    signInRes.execute({ uname, paswd });
  };

  return (
    <div className="signin">
      <div className="container">
        <div className="content row justify-content-center">
          <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 col-xxl-4">
            <div className="panel panel-login">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12 col-xs-6">
                    <h3>PRISIJUNGIMAS</h3>
                  </div>
                </div>
                <hr />
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-12">
                    <form style={{ display: "block" }} onSubmit={onSubmit}>
                      <div className="form-group">
                        <label>Darbuotojo slapyvardis</label>
                        <InputText
                          name="userid"
                          id="userid"
                          value={uname}
                          onChange={(e) => setUname(e.target.value)}
                          tabIndex="1"
                          className={`form-control ${
                            unameError ? "both-error" : ""
                          }`}
                          placeholder="Įveskite vartotojo vardą arba el. pašto adresą"
                        />
                      </div>
                      <div className="form-group">
                        <label>Slaptažodis</label>
                        <InputText
                          isPassword
                          name="password"
                          id="password"
                          value={paswd}
                          onChange={(e) => setPaswd(e.target.value)}
                          tabIndex="2"
                          className={`form-control ${
                            paswdError ? "both-error" : ""
                          }`}
                          placeholder="Įveskite slaptažodį"
                        />
                      </div>
                      <div className="form-group">
                        {msg && (
                          <div className="row">
                            <div className="col-12 txt-error">{msg}</div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-12">
                            <input
                              type="submit"
                              name="login-submit"
                              id="login-submit"
                              tabIndex="4"
                              className="btn btn-green"
                              disabled={signInRes.loading}
                              value="Prisijungti"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
