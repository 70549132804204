import { connect } from "react-redux";

import WasteJournal from "./WasteJournal";

const mapStoreStateToProps = function (store) {
  return {
    sites: store.siteReducer.sites,
    wastes: store.wasteReducer.wastes,
    municipalities: store.municipalityReducer.municipalities,
    origins: store.originReducer.origins,
    billings: store.billingReducer.billings,
    carriers: store.carrierReducer.carriers,
    primarySources: store.primarySourceReducer.primarySources,
    isAdmin: store.authReducer.currentUser?.isAdmin,
    currentUserId: store.authReducer?.currentUser?.id,
  };
};

export default connect(mapStoreStateToProps)(WasteJournal);
