import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconContainer from "../iconContainer/iconContainer";
import { ReactComponent as UserSVG } from "../../../assets/images/user.svg";
import { ReactComponent as GearSvg } from "../../../assets/images/gear.svg";
import { ReactComponent as LogoutSvg } from "../../../assets/images/logout.svg";

import "./UserProfile.css";

const UserProfile = function ({ user, signOut }) {
  const navigate = useNavigate();
  const profileLinkRef = useRef(null);

  const [menuIsShown, setMenuIsShown] = useState(false);

  useEffect(() => {
    if (menuIsShown) {
      if (profileLinkRef.current) {
        profileLinkRef.current.focus();
      }
    }
  }, [menuIsShown]);

  const toggle = () => {
    setMenuIsShown(!menuIsShown);
  };

  const signout = () => {
    signOut();
  };

  return (
    <>
      {menuIsShown && <div className="user-profile-bg" onClick={toggle} />}
      <div className="user-profile" onClick={toggle}>
        <div className="user-name">
          {`${user ? `${user.firstName} ${user.lastName}` : ""}`}{" "}
          <span className={`bg-arrow ${menuIsShown ? "up" : "down"}`}></span>
        </div>
        <ul className={`user-drop-down ${menuIsShown ? "" : "hide"}`}>
          <li>
            <Link to="/settings/site-wastes" className="user_flex">
              <div className="user_drop_icon">
                <IconContainer width={24}>
                  <GearSvg />
                </IconContainer>
              </div>
              <div className="user_drop_text">Nustatymai</div>
            </Link>
          </li>
          <li>
            <Link to="/" onClick={(e) => signout()} className="user_flex">
              <div className="user_drop_icon">
                <IconContainer width={24}>
                  <LogoutSvg />
                </IconContainer>
              </div>
              <div className="user_drop_text">Atsijungti</div>
            </Link>
          </li>

          {/* <li className="bg-user"><Link to="/profile">Profilis</Link></li>
					<li className="bg-settings"><Link to="/settings">Nustatymai</Link></li>
					<li className="bg-exit"><a href="#" onClick={(e) => signout()}>Atsijungti</a></li> */}
        </ul>
      </div>
    </>
  );
};

export default UserProfile;
