import React, { useEffect, useState } from "react";
import moment from "moment";

import "./style.scss";
import {
  childField,
  Header,
  multiDropField,
  textBox,
  textField,
} from "../../../../UI/FormGenerator/formField";
import {
  option,
  pageConstructType,
} from "../../../../UI/FormGenerator/formTypes";
import { site } from "../../../../../@types/sites";
import FractionList from "../../../../UI/fractionList/FractionList";
import {
  Fetch,
  makeLabelInt,
  pageSettingOptions,
} from "../../../../../helpers/misc";
import DiscreteRangeDatePicker from "../../../../UI/DiscreteRangePicker/DiscreteRangePicker";
import { range } from "../../../../../pages/Report/@types";
import FormGenerator, {
  GenerateForm,
} from "../../../../UI/FormGenerator/FormGenerator";
import { parseStringTemplate } from "../../../../../helpers";
import { useNavigate, useLocation, useResolvedPath } from "react-router-dom";
import { useAsync } from "../../../../../helpers/asyncFunc";
import IconContainer from "../../../../UI/iconContainer/iconContainer";
import { ReactComponent as RestoreIcon } from "../../../../../assets/images/restore.svg";
import NewConfirmDialog from "../../../../UI/ConfirmDialog/NewConfirmDialog";
import { restoreObjType, restore_activity } from "./getter";
import { currentUserType } from "../../../../../pages/WasteAcceptance/@types";
import { DataExportService } from "../../../../../services";
import { getData, getXlsxObj } from "../../dataExportFormat";
import { rootFilterType } from "../../UserManagement";

export type userActivity = {
  createdAt: string;
  params: { [key: string]: string };
  id: number;
  activityId: number;
  short: string;
  uname: string;
  firstName: string;
  lastName: string;
  position: string | null;
  notifTxt: string;
  siteName: string;
};

export type userActivityFilterType = {
  date: {
    startDate: string;
    endDate: string;
  };
  uname: string;
  fullName: string;
  position: string;
  activity: string;
  userId: number | "";
  siteId: option[];
  all: string;
};

export const getValParams = (params: {
  [key: string]: string;
}): { [key: string]: string } => {
  try {
    const prevWeight = params.prevWeight;
    const newWeight = params.newWeight;
    const newQuantity = params.newQuantity;
    const prevQuantity = params.prevQuantity;
    const uom = params.uom;

    if (newWeight && prevWeight) {
      const isQuantified = uom === "unit";
      const prevVal = isQuantified ? prevQuantity : prevWeight;
      const newVal = isQuantified ? newQuantity : newWeight;
      return {
        ...params,
        prevVal,
        newVal,
        uom: isQuantified ? "vnt." : uom || "kg",
      };
    } else {
      return params;
    }
  } catch (err) {
    console.log(err);
    return params;
  }
};

const UserActivity = function ({
  sites,
  globalKeywords,
  currentUser,
  retrieve,
  xlsxExport,
  setRootFilter,
  rootFilter,
}: {
  sites: site[];
  globalKeywords: string | null;
  currentUser: currentUserType | undefined;
  retrieve: number | undefined;
  xlsxExport: (func: any) => void;
  setRootFilter: (data: rootFilterType) => void;
  rootFilter: rootFilterType;
}) {
  const defaultFilter: userActivityFilterType = {
    date: {
      startDate: "",
      endDate: "",
    },
    uname: "",
    fullName: "",
    position: "",
    activity: "",
    userId: "",
    siteId: [],
    all: globalKeywords || "",
  };
  const navigate = useNavigate();
  const url = useResolvedPath("").pathname;
  const { pathname } = useLocation();
  const location = useLocation();
  const dataExportService = new DataExportService();

  const { state }: { state: Partial<userActivityFilterType> } = useLocation();
  const extFilter = { ...state };

  const [restoreObj, setRestoreObj] = useState<restoreObjType>();
  const [siteOptions, setSiteOptions] = useState<option[]>([]);
  const [filter, setFilter] = useState({
    ...defaultFilter,
    ...(extFilter ? extFilter : {}),
  });
  const [userActivities, setUserActivities] = useState<userActivity[]>([]);
  const [count, setCount] = useState(0);
  const [currentStart, setCurrentPage] = useState(1);

  const [pageRecordNum, setPageRecordNum] = useState(
    makeLabelInt(pageSettingOptions[1])
  );
  const limit = pageRecordNum.label;
  const offset = currentStart - 1;

  const restoreActivityRes = useAsync({
    asyncFunc: restore_activity,
    funcParams: {} as unknown as restoreObjType,
    immediate: false,
  });

  const restoreActivity = () => {
    restoreActivityRes.execute(restoreObj as restoreObjType);
    setRestoreObj(undefined);
  };

  const xlsx = () => {
    const { description, title } = getXlsxObj("USER_ACTIVITIES");
    dataExportService.init(title, description);

    const rows = getData({
      type: "USER_ACTIVITIES",
      records: userActivities,
    });

    dataExportService.export(rows);
  };

  const canExport = retrieve && url === pathname;
  useEffect(() => {
    if (canExport) {
      xlsxExport(xlsx);
    }
  }, [retrieve]);

  const getFilterQuery = () => {
    const queryObj: Partial<
      { [key in keyof userActivityFilterType]: string } & {
        startDate: string;
        endDate: string;
      }
    > = {};

    const query = Object.keys(filter)
      .map((Key) => {
        const multiFilterKeys: (keyof userActivityFilterType)[] = ["siteId"];

        const key = Key as keyof userActivityFilterType;
        if (key === "date") {
          if (filter.date.startDate && filter.date.endDate) {
            queryObj["startDate"] = filter.date.startDate;
            queryObj["endDate"] = filter.date.endDate;
            return `startDate=${filter.date.startDate}&endDate=${filter.date.endDate}`;
          }

          return "startDate=&endDate=";
        } else if (multiFilterKeys.indexOf(key) >= 0) {
          queryObj[key] = (filter[key] as option[])
            .map((i) => i.value)
            .join(",");
          return (
            key + "=" + (filter[key] as option[]).map((i) => i.value).join(",")
          );
        } else {
          queryObj[key] = filter[key] as string;
          return key + "=" + filter[key];
        }
      })
      .join("&");

    return { queryObj, query };
  };

  const Filter = (() => {
    const _query = getFilterQuery().query;

    return _query;
  })();

  useEffect(() => {
    setRootFilter({
      ...rootFilter,
      userActivities: Filter,
    });
  }, [Filter]);

  const getUserAct = async (params: {
    query: string;
    limit: number;
    offset: number;
  }) => {
    const { query, limit, offset } = params;

    return await Fetch(
      `/userActivities?${query}&limit=${limit}&offset=${offset}`
    )
      .then((result) => {
        const response = result.json() as Promise<{
          success: true;
          userActivities: userActivity[];
          count: number;
        }>;
        return response;
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  useEffect(() => {
    setFilter({ ...filter, all: globalKeywords ? globalKeywords : "" });
  }, [globalKeywords]);

  const userActivityRes = useAsync(
    {
      asyncFunc: getUserAct,
      funcParams: { query: Filter, limit, offset },
      immediate: true,
      clearOnError: true,
    },
    [Filter, limit, offset]
  );

  useEffect(() => {
    const ua = userActivityRes.data?.userActivities || [];
    const count = userActivityRes.data?.count || 0;

    setUserActivities([...ua]);
    setCount(count);
  }, [userActivityRes.data]);

  useEffect(() => {
    const restoreResponse = restoreActivityRes.data;
    if (restoreResponse) {
      userActivityRes.execute({ query: Filter, limit, offset });
    }
  }, [restoreActivityRes.data]);

  useEffect(() => {
    if (sites && sites.length > 0) {
      const options = sites.map((site) => {
        return {
          label: site.name,
          value: site.id,
        };
      });

      setSiteOptions(options);
    }
  }, [sites]);

  const updateFilter = (objFilter: Partial<userActivityFilterType>) => {
    navigate("", { ...location, state: {}, replace: true });
    setFilter({ ...filter, ...objFilter, userId: "", all: "" });
  };

  const cannotRestore = ["WASTE_DISPOSAL_ORDER"];

  const dist = new Array(6).fill(1).concat(["5rem"]);
  const textConstruct: pageConstructType[] = (userActivities || []).map(
    (userActivity, n) => ({
      sizeDist: dist,
      typeDist: [
        textBox(
          moment(userActivity.createdAt).format("YYYY-MM-DD HH:mm"),
          true
        ),
        textBox(userActivity.uname, false),
        textBox(
          `${userActivity.firstName || ""} ${userActivity.lastName || ""}`,
          false
        ),
        textBox(userActivity.position || "", false),
        textBox(
          parseStringTemplate(
            userActivity.notifTxt,
            getValParams(userActivity.params)
          ),
          false
        ),
        textBox(userActivity.siteName, false),
        childField(
          <div key={userActivity.id} className="center">
            {cannotRestore.indexOf(userActivity.short) < 0 ? (
              <IconContainer size="lg">
                <RestoreIcon
                  onClick={() => {
                    setRestoreObj({
                      params: userActivity.params,
                      userId: currentUser?.id,
                      userActivityId: userActivity.id,
                      short: userActivity.short,
                    });
                  }}
                  className="restore_svg_user_Activities"
                />
              </IconContainer>
            ) : (
              <></>
            )}
          </div>
        ),
      ],
      hasHeader: false,
      headerText: [],
    })
  );

  const pageConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [
        childField(
          <div
            className="userList_range_wrapper"
            key={"userList_range_wrapper"}
          >
            <DiscreteRangeDatePicker
              key={"userList_range"}
              onChange={(val: range) => {
                if (val.startDate || val.startDate) {
                  updateFilter({
                    date: {
                      startDate: val.startDate
                        ? moment(new Date(val.startDate)).format("YYYY-MM-DD")
                        : "",
                      endDate: val.endDate
                        ? moment(new Date(val.endDate)).format("YYYY-MM-DD")
                        : "",
                    },
                  });
                }
              }}
              clear={!filter.date.startDate && !filter.date.endDate}
              value={undefined}
            />
          </div>
        ),
        textField(
          "",
          false,
          () => {},
          filter.uname,
          (uname) => {
            updateFilter({ uname });
          }
        ),
        textField(
          "",
          false,
          () => {},
          filter.fullName,
          (fullName) => {
            updateFilter({ fullName });
          }
        ),
        textField(
          "",
          false,
          () => {},
          filter.position,
          (position) => {
            updateFilter({ position });
          }
        ),
        textField(
          "",
          false,
          () => {},
          filter.activity,
          (activity) => {
            updateFilter({ activity });
          }
        ),
        multiDropField(
          siteOptions,
          "Ieškoti...",
          false,
          filter.siteId,
          (options) => {
            updateFilter({ siteId: options });
          }
        ),
      ],
      hasHeader: true,
      headerText: [
        Header(["Veiklos laikotarpis"], false),
        Header(["Darbuotojas"], false, true),
        Header(["Vardas ir pavardė"], false, true),
        Header(["Pareigos"], false, true),
        Header(["Veiksmas"], false, true),
        Header(["Vieta"], false, true),
        Header(["Veiksmas"], false, true),
      ],
    },
  ];

  return (
    <div className="user-activity">
      {restoreObj && (
        <NewConfirmDialog
          txt="Ar tikrai norite anuliuoti šį veiksmą?"
          isVisible={!!restoreObj}
          onExit={() => {
            setRestoreObj(undefined);
          }}
          isLoading={false}
          onContinue={() => {
            restoreActivity();
          }}
        />
      )}
      <div className="viewExt">
        <div className="DATASET__wrapper">
          <GenerateForm loading={userActivityRes.loading}>
            {pageConstruct.map((construct, n) => (
              <FormGenerator
                key={`wasteAccumulation_1_${n}`}
                gridSizeDist={construct.sizeDist}
                gridTypeDist={construct.typeDist}
                hasHeader={construct.hasHeader}
                headerText={construct.headerText}
              />
            ))}
            {textConstruct.map((construct, n) => (
              <FormGenerator
                key={`wasteAccumulation_2_${n}`}
                gridSizeDist={construct.sizeDist}
                gridTypeDist={construct.typeDist}
                hasHeader={construct.hasHeader}
                headerText={construct.headerText}
              />
            ))}
          </GenerateForm>
        </div>
        <FractionList
          count={count}
          currentStart={currentStart}
          setCurrentPage={setCurrentPage}
          pageRecordNum={pageRecordNum}
          setPageRecordNum={setPageRecordNum}
        />
      </div>
    </div>
  );
};

export default UserActivity;
