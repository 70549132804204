import * as actions from "../actionTypes";
import { combineReducers } from "redux";

import authReducer from "./auth";
import commonReducer from "./common";
import billingReducer from "./billing";
import companyReducer from "./company";
import carrierReducer from "./carrier";
import disposalReducer from "./disposal";
import documentReducer from "./document";
import formationReducer from "./formation";
import featureReducer from "./feature";
import groupReducer from "./group";
import materialReducer from "./material";
import municipalityReducer from "./municipality";
import originReducer from "./origin";
import personReducer from "./person";
import roleReducer from "./role";
import serijaReducer from "./serija";
import siteReducer from "./site";
import suggestionReducer from "./suggestion";
import userGroupReducer from "./userGroup";
import wasteReducer from "./waste";
import primarySourceReducer from "./primarySource";
import wasteAccumulationReducer from "./wasteAccumulation";
import userReducer from "./user";
import wasteActivityReducer from "./wasteActivity";
import wasteActivityTypeReducer from "./wasteActivityType";

const appReducer = combineReducers({
  authReducer,
  commonReducer,
  companyReducer,
  billingReducer,
  carrierReducer,
  disposalReducer,
  documentReducer,
  formationReducer,
  featureReducer,
  groupReducer,
  materialReducer,
  municipalityReducer,
  originReducer,
  personReducer,
  roleReducer,
  serijaReducer,
  siteReducer,
  suggestionReducer,
  userGroupReducer,
  wasteReducer,
  primarySourceReducer,
  wasteAccumulationReducer,
  userReducer,
  wasteActivityTypeReducer,
  wasteActivityReducer,
});

const rootReducer = (state, action) => {
  if (action.type === actions.SIGNOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
