import React, {
  ChangeEvent,
  ReactChild,
  ReactElement,
  useEffect,
  useState,
} from "react";
import DropDown from "../../elements/DropDown/DropDown";
import "./FormGenerator.css";
import {
  DropDownFieldType,
  FormGeneratorComponentProps,
  MultiDropDownFieldType,
  option,
} from "./formTypes";
import moment from "moment";
import { DateTimePicker } from "../../../base";
import { ReactComponent as CalendarLogo } from "../../../assets/images/calendar.svg";
import { ReactComponent as SearchLogo } from "../../../assets/images/search.svg";
import NewMultiDropDown from "../NewMultiDropDown/NewMultiDropDown";
import { isDigit, isLTNum, transform } from "../../../helpers/misc";
import { childField } from "./formField";
import { useTimeout } from "../../../helpers/useTimeout";

const getMin = (num: (number | string)[]): number | undefined => {
  let min: number | undefined = undefined; // Defaults to the first item in the array
  for (let i = 0; i < num.length; i++) {
    const number = num[i];
    if (typeof number === "number" && min === undefined) {
      min = number;
    } else if (typeof number === "number" && min && number < min) {
      min = number;
    }
  }
  return min;
};

/**
 * 
 * @param gridSizeDist The distribution of the template columns. This can contain either
 * numbers or string. The expected string should be valid i.e 1rem, 1px, 1pc or the likes.
 * This component does not check for that so the developer is advised to take note where
 * necessary

 * @param minWidth This is the minimum width of the small field. Other fields will make their
 * minimum width while maintaining the ratio between their width. It is advised that icons have 
 * explicit width of the string literal type to avoid overblown fields.
 * @param flex Flex is a boolean which indicates whether a grid container should be optimized for
 * different screen sizes. It is almost a misnomer
 * @returns A string which represents the grid-template-columns styling of a grid container
 */
export const sizeToStyle = (gridSizeDist: (number | string)[]) => {
  const min = getMin(gridSizeDist);
  let templateColumns: string = "";
  for (let i = 0; i < gridSizeDist.length; i++) {
    const frac = gridSizeDist[i];
    if (typeof frac === "number") {
      let tracklist: string;
      tracklist = `${frac}fr `;
      templateColumns = templateColumns + tracklist;
    } else {
      const tracklist = `${frac} `;
      templateColumns = templateColumns + tracklist;
    }
  }
  return templateColumns.trim();
};

export const header = (arr: ReactElement[]) => {
  //In case someone passes a header type that isnt an array with one element
  return arr.length === 1 ? arr[0] : arr.join("|");
};

export const checkValue = (arr: option) => {
  //Check if value passed is good
  if (Object.keys.length === 0) return false;
  let check = true;
  for (let i = 0; i < Object.keys(arr).length; i++) {
    const key = Object.keys(arr)[i];
    const element = arr[key as keyof option];
    if (element === undefined || null) {
      return false;
    }
  }
  return check;
};

const FormGenerator = ({
  gridSizeDist,
  gridTypeDist,
  hasHeader,
  headerText,
  raw,
  id,
}: FormGeneratorComponentProps) => {
  return (
    <div id={id} className="formGenWrapper fdCol">
      {hasHeader ? (
        <div
          style={{
            gridTemplateColumns: sizeToStyle(gridSizeDist),
            paddingLeft: raw ? raw : "",
            paddingRight: raw ? raw : "",
          }}
          className={`fgHead ${gridTypeDist.length > 0 ? "" : "noBorder"}`}
        >
          {headerText.map((head, n) => (
            <div
              className={`${head.error ? "txt-error" : ""} ${
                head.alignLeft ? "alignLeft" : ""
              }`}
              key={`headerKey_${n}`}
            >
              {header(head.fieldText)}
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
      {gridTypeDist.length > 0 && (
        <div
          style={{
            gridTemplateColumns: sizeToStyle(gridSizeDist),
            paddingLeft: raw ? raw : "",
            paddingRight: raw ? raw : "",
          }}
          className="fgRow"
        >
          {gridTypeDist.map((gType, n) =>
            gType.fieldType === "field" ? (
              <div
                key={`gridRow_field_${n}`}
                className="fgFieldInputWrap fdCol"
              >
                <div className="top"></div>
                {(gType as DropDownFieldType).dropDown &&
                !(gType as DropDownFieldType).dropDownTypeMultiple ? (
                  <DropDown
                    error={gType.error!}
                    clearable={!!gType.clearable}
                    value={
                      checkValue((gType as DropDownFieldType).value)
                        ? (gType as DropDownFieldType).value
                        : { label: "", value: 0 }
                    }
                    border={gType.error ? "1px solid #FD4B0D" : ""}
                    options={gType.options || []}
                    onSelect={(detail: option) =>
                      gType.fieldHandler(detail.value)
                    } //sends out a number
                    title={gType.placeholder || ""}
                  />
                ) : (gType as MultiDropDownFieldType).dropDownTypeMultiple ? (
                  <NewMultiDropDown
                    options={gType.options || []}
                    selectedOptions={(gType as MultiDropDownFieldType).value}
                    onSelect={(detail: option[]) => gType.fieldHandler(detail)}
                    border={""}
                    placeholder={gType.placeholder || ""}
                  />
                ) : gType.type === "date" ? (
                  <div className="input_wrap_date">
                    <DateTimePicker
                      value={
                        gType.value
                          ? gType.value
                          : moment().format("YYYY-MM-DD")
                      }
                      onChange={(date: any) =>
                        gType.fieldHandler(moment(date[0]).format("YYYY-MM-DD"))
                      }
                      placeholder={gType.placeholder}
                      options={{
                        disable: [
                          (dt: string | number | Date) =>
                            moment(new Date(dt)).unix() > moment().unix(),
                        ],
                      }}
                    />
                  </div>
                ) : gType.type === "decoratedDate" ? (
                  <DatePicker
                    border={""}
                    showDrop={true}
                    drop={() => {}}
                    dropLogo={""}
                  />
                ) : gType.type === "search" ? (
                  <Search
                    search={gType.fieldHandler}
                    placeholder={gType.placeholder}
                    externalValue={gType.controlledText}
                    setExternalValue={gType.controlledFunction}
                  />
                ) : (
                  <Input
                    value={typeof gType.value === "string" ? gType.value : ""}
                    placeholder={gType.placeholder!}
                    handler={gType.fieldHandler}
                    error={gType.error!}
                    type={gType.type!}
                    externalValue={
                      typeof gType.controlledText === `string`
                        ? gType.controlledText
                        : undefined
                    }
                    setExternalValue={gType.controlledFunction || undefined}
                  />
                )}
              </div>
            ) : gType.fieldType === "text" ? (
              <div
                key={`gridRow_text_${n}`}
                className={`fgFieldTextWrap ${gType.center ? "center" : ""}`}
              >
                {gType.text || ""}
              </div> //More to be added here
            ) : (
              gType.fieldType === "child" && gType.child
            )
          )}
        </div>
      )}
    </div>
  );
};

export const Input = ({
  handler,
  type,
  value,
  placeholder,
  error,
  disabled,
  readOnly,
  autoFocus,
  externalValue,
  setExternalValue,
  onlyDigit,
  icon,
  iconClick,
  className,
}:
  | {
      handler: (data: string) => void;
      type: string;
      value: string;
      placeholder: string;
      error: boolean;
      disabled?: boolean;
      readOnly?: boolean;
      autoFocus?: boolean;
      externalValue?: never;
      setExternalValue?: never;
      onlyDigit?: boolean;
      icon?: ReactChild;
      iconClick?: () => void;
      className?: string;
    }
  | {
      handler: (data: string) => void;
      type: string;
      value: string;
      placeholder: string;
      error: boolean;
      disabled?: boolean;
      readOnly?: boolean;
      autoFocus?: boolean;
      externalValue: string | undefined;
      setExternalValue: ((txt: string) => void) | undefined;
      onlyDigit?: boolean;
      icon?: ReactChild;
      iconClick?: () => void;
      className?: string;
    }) => {
  const numType = type === `number`;
  const [text, setInputText] = useState(transform(value, numType));

  const checkLocalLTNumber = (value: string): boolean => {
    if (numType) {
      const digit = onlyDigit ? isDigit(value) : true;

      if (isLTNum(value) && digit) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const saveText = (Text: string) => {
    setInputText(transform(Text, numType));
  };

  const handleChange = (e: ChangeEvent) => {
    const element = e.target as HTMLFormElement;
    const Text = element.value as string;
    const canChange = checkLocalLTNumber(Text);
    if (canChange) {
      if (typeof externalValue === `string` && setExternalValue) {
        e.stopPropagation();
        if (numType) {
          //Only convert the comma when it is a number
          setExternalValue(Text.replace(/\,/g, "."));
        } else {
          setExternalValue(Text);
        }
        handler(Text);
      } else {
        e.stopPropagation();
        saveText(Text);
        handler(Text);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (value) {
      saveText(value);
    }
  }, [value]);

  useEffect(() => {
    if (typeof externalValue === `string`) {
      saveText(externalValue);
    }
  }, [externalValue]);

  return (
    <div
      className={`input_bound_wrapper ${error ? "both-error" : "text"} ${
        className ? className : ""
      }`}
    >
      <div className="bottom flex1">
        <input
          autoComplete="off"
          onChange={handleChange}
          lang="lt"
          readOnly
          disabled={!!disabled}
          autoFocus={autoFocus}
          value={
            externalValue
              ? transform(externalValue, numType)
              : transform(text, numType)
          }
          onFocus={(e) => {
            if (!readOnly) {
              e.target.removeAttribute(`readOnly`);
            }
          }}
          onBlur={(e) => {
            e.target.setAttribute(`readOnly`, `true`);
          }}
          className={`fgBInput flex ${error ? "both-error" : "text"}`}
          type={type ? (type === `number` ? `text` : type) : "text"}
          placeholder={placeholder}
        />
      </div>
      {icon ? (
        <div
          className={`input_icon_btn `}
          onClick={() => {
            if (iconClick) {
              iconClick();
            }
          }}
        >
          <div>{icon}</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const DatePicker = ({
  border,
  showDrop,
  drop,
  dropLogo,
}: {
  border: string;
  showDrop: boolean;
  drop: (bool: boolean) => void;
  dropLogo: string;
}) => {
  return (
    <div className="dateField_wrapper bottom flex1">
      <input
        readOnly={true}
        onChange={() => {}}
        lang="lt"
        value={""}
        className="fgBInput flex flex1"
        type={"text"}
        placeholder={""}
      />
      <div
        className="dropD_btn"
        style={{ borderLeft: border || "" }}
        onClick={() => {
          drop(!showDrop);
        }}
      >
        <div>
          <CalendarLogo />
        </div>
      </div>
    </div>
  );
};

export const Search = ({
  search,
  placeholder,
  externalValue,
  setExternalValue,
}:
  | {
      search: (data: string) => void;
      placeholder: string;
      externalValue?: never;
      setExternalValue?: never;
    }
  | {
      search: (data: string) => void;
      placeholder: string;
      externalValue: string | undefined;
      setExternalValue: ((text: string) => void) | undefined;
    }) => {
  const [text, setText] = useState("");

  const handleChange = (e: ChangeEvent) => {
    if (typeof externalValue === `string` && setExternalValue) {
      e.stopPropagation();
      const element = e.target as HTMLFormElement;
      const Text = element.value;
      setExternalValue(Text);
    } else {
      e.stopPropagation();
      const element = e.target as HTMLFormElement;
      const Text = element.value;
      setText(Text);
    }
  };

  useEffect(() => {
    if (typeof externalValue === `string`) {
      setText(externalValue);
    }
  }, [externalValue]);

  return (
    <div className="fgSearch_container">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          search(text);
        }}
      >
        <div className="fgSearch_wrapper">
          <div className="fgSearch_btn" onClick={() => search(text)}>
            <div className="svgHolder">
              <SearchLogo
                onClick={(e) => {
                  e.stopPropagation();
                  search(text);
                }}
              />
            </div>
          </div>
          <div className="flex1">
            <input
              readOnly
              value={externalValue ? externalValue : text}
              onFocus={(e) => {
                e.target.removeAttribute(`readOnly`);
              }}
              onBlur={(e) => {
                e.target.setAttribute(`readOnly`, `true`);
              }}
              onChange={handleChange}
              type="text"
              name=""
              placeholder={placeholder}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export const GenerateForm = ({
  children,
  noMargin,
  classNameWrap,
  className,
  overflow,
  loading,
  gridDist,
}:
  | {
      children: ReactChild[][] | ReactChild[];
      noMargin?: boolean;
      classNameWrap?: string;
      className?: string;
      overflow?: undefined | false;
      loading?: boolean;
      gridDist?: never;
    }
  | {
      children: ReactChild[][] | ReactChild[];
      noMargin?: boolean;
      classNameWrap?: string;
      className?: string;
      overflow: true;
      loading?: boolean;
      gridDist: (string | number)[];
    }) => {
  return (
    <div
      className={`form_management_wrapper ${
        noMargin ? `noMargin noHeaderBorder` : ``
      } ${classNameWrap ? classNameWrap : ``}`}
    >
      <div
        className={`form_management_container ${className ? className : ``} ${
          overflow ? "overflow" : ``
        }`}
      >
        <LoadingGenerator gridDist={gridDist} loading={loading} />
        {children}
      </div>
    </div>
  );
};

export const LoadingGenerator = ({
  loading,
  gridDist,
}: {
  loading?: boolean;
  gridDist?: (string | number)[];
}) => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * We are using this custom setTimeout hook
   * ...because we do not want to flash loading
   * ...at the operator anytime a request is made
   * Loading should be shown after X amount of milliseconds into making the request
   */
  useTimeout(
    {
      func: () => {
        if (loading) {
          setIsLoading(true);
        }
      },
      time: 500,
      call: true,
    },
    [loading]
  );

  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  return isLoading ? (
    <div
      style={{
        padding: "4px 1.5rem",
        gap: "0 1rem",
        textAlign: "center",
        ...(gridDist
          ? { display: "grid", gridTemplateColumns: sizeToStyle(gridDist) }
          : {}),
        backgroundColor: "var(--vaatcLightGreen)",
        fontWeight: 500,
      }}
      className="formGenWrapper"
    >
      <span
        style={{
          ...(gridDist
            ? {
                gridColumn: `1 / ${gridDist.length + 1}`,
              }
            : {}),
        }}
        key={"loading_row"}
      >
        Luktelkite ...
      </span>
    </div>
  ) : (
    <></>
  );
};

export default FormGenerator;
