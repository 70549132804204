import React from "react";

const ExtraBar = ({
  totalCount,
  currentAvailableCount,
  expandFunc,
}: {
  totalCount: number;
  currentAvailableCount: number;
  expandFunc: () => void;
}) => {
  return totalCount - currentAvailableCount > 0 ? (
    <div className="viewMore_wrapper">
      <span onClick={expandFunc}>Rodyti daugiau</span>
    </div>
  ) : (
    <></>
  );
};

export default ExtraBar;
