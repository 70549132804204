import { connect } from "react-redux";

import UserManagement from "./UserManagement";

import * as actions from "../../../stores/actions";

const mapDispatchToProps = function (dispatch) {
  return {
    saveUser: function (type, user) {
      dispatch(actions.saveUser(type, user));
    },
  };
};

export default connect(undefined, mapDispatchToProps)(UserManagement);
