import moment from "moment";
import { parseStringTemplate } from "../../../helpers";
import {
  alignment,
  defaultDataType,
} from "../../../services/DataExportService";
import {
  getValParams,
  userActivity,
} from "./elements/UserActivity/UserActivity";
import { localUserGroup } from "./elements/UserGroupList/UserGroupList";
import { user } from "./elements/UserList/UserList";

type pathType = "" | "USER_LIST" | "USER_ACTIVITIES" | "USER_GROUP";

type paramType =
  | { type: "USER_LIST"; records: user[] }
  | { type: "USER_ACTIVITIES"; records: userActivity[] }
  | { type: "USER_GROUP"; records: localUserGroup[] }
  | { type: ""; records: any[] };

export const getData = (param: paramType): unknown[] => {
  const { type, records } = param;
  if (type === "USER_LIST") {
    return (records || []).map((record) => ({
      uname: record.uname,
      fullName: `${record.firstName} ${record.lastName}`,
      email: `${record.email || ``}`,
      position: record.position || ``,
      roleName: record.roleName || ``,
      isActive: record.isActive ? "Aktyvi" : "Neaktyvi",
      lastLogin: record.lastLogin
        ? moment(record.lastLogin).format("YYYY-MM-DD")
        : "",
    }));
  } else if (type === "USER_ACTIVITIES") {
    return (records || []).map((record) => ({
      date: moment(record.createdAt).format("YYYY-MM-DD"),
      uname: record.uname,
      fullName: `${record.firstName || ""} ${record.lastName || ""}`,
      position: record.position,
      notification: parseStringTemplate(
        record.notifTxt,
        getValParams(record.params)
      ),
      siteName: record.siteName,
    }));
  } else if (type === "USER_GROUP") {
    return (records || []).map((record, n) => ({
      index: `${n + 1}`,
      name: `${record.name}`,
      userCount: `${record.userCount}`,
      isActive: record.isActive ? "Taip" : "Ne",
      updatedAt: record.updatedAt
        ? moment(record.updatedAt).format("YYYY-MM-DD")
        : "",
    }));
  } else {
    return [];
  }
};

export const getXlsxObj = (
  type: pathType
): { title: string; description: { [key: string]: any }[] } => {
  if (type === "USER_LIST") {
    return {
      title: "Sąrašas",
      description: [
        {
          name: "Darbuotojas",
          key: "uname",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: "Vardas ir pavardė",
          key: "fullName",
          width: 50,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: "El. p. adresas",
          key: "email",
          width: 50,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: "Pareigos",
          key: "position",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: "Darbuotojo tipas sistemoje (rolė)",
          key: "roleName",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: "Aktyvumas",
          key: "isActive",
          width: 20,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: "Paskutinis prisijungimas",
          key: "lastLogin",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
      ],
    };
  } else if (type === "USER_ACTIVITIES") {
    return {
      title: "Veiklos žurnalas",
      description: [
        {
          name: "Veiklos laikotarpis",
          key: "date",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: "Darbuotojas",
          key: "uname",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: "Vardas ir pavardė",
          key: "fullName",
          width: 50,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: "Pareigos",
          key: "position",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: "Veiksmas",
          key: "notification",
          width: 90,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
        {
          name: "Vieta",
          key: "siteName",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleLeft },
        },
      ],
    };
  } else if (type === "USER_GROUP") {
    return {
      title: "Darbo grupės",
      description: [
        {
          name: "Eilės Nr.",
          key: "index",
          width: 15,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: "Pavadinimas",
          key: "name",
          width: 50,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: "Darbuotojai",
          key: "userCount",
          width: 15,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: "Aktyvumas",
          key: "isActive",
          width: 20,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
        {
          name: "Redaguota",
          key: "updatedAt",
          width: 30,
          dataType: defaultDataType.string,
          style: { alignment: alignment.middleCenter },
        },
      ],
    };
  } else {
    return {
      title: "",
      description: [],
    };
  }
};
