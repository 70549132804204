import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { applyMiddleware, createStore, compose } from "redux";
import { Provider } from "react-redux";
import { createEpicMiddleware } from "redux-observable";
import thunk from "redux-thunk";

import rootReducer from "./stores/reducers";
import rootEpic from "./stores/epics";

import App from "./App";

import "./assets/css/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.scss";
import "flatpickr/dist/flatpickr.min.css";

const epicMiddleware = createEpicMiddleware();

// To pass arguments through CLI, run the script but excluding npm_config from the variable in the package.json
//For example, ${npm_config_clear_storage} becomes --clear_storage
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA || "";

if (localStorage.getItem("APP_VERSION") != APP_VERSION) {
  localStorage.clear();
  localStorage.setItem("APP_VERSION", APP_VERSION);
}

const root_state = localStorage.getItem("ROOT_STATE");

const initialState = root_state ? JSON.parse(root_state) : {};

// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware, logger, thunk)));
export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(epicMiddleware, thunk))
);

store.subscribe(() => {
  try {
    const newState = store.getState();
    let serializedState = JSON.stringify(newState);
    localStorage.setItem("ROOT_STATE", serializedState);
  } catch (err) {}
});

epicMiddleware.run(rootEpic);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);
