import React, { useEffect, useState } from "react";
import moment from "moment";
import { formatNumber } from "../../helpers";

import "./style.scss";
import FractionList from "../../components/UI/fractionList/FractionList";
import {
  appendSort,
  enabled,
  makeLabelInt,
  pageSettingOptions,
} from "../../helpers/misc";
import Sorter from "../../components/elements/Sorter/Sorter";
import { DataExportService } from "../../services";
import {
  formationType,
  materialType,
  primarySource,
  wasteActivityType,
  wasteActivityTypeType,
  wasteType,
} from "../../@types/waste";
import {
  option,
  pageConstructType,
} from "../../components/UI/FormGenerator/formTypes";
import {
  childField,
  Header,
  multiDropField,
  numberField,
  textBox,
  textField,
} from "../../components/UI/FormGenerator/formField";
import FormGenerator, {
  GenerateForm,
} from "../../components/UI/FormGenerator/FormGenerator";
import DiscreteRangeDatePicker from "../../components/UI/DiscreteRangePicker/DiscreteRangePicker";
import { range } from "../Report/@types";
import FilterButtons from "../../components/UI/filterButtons/FilterButtons";
import { useAsync } from "../../helpers/asyncFunc";
import { getWasteDisposal } from "./getter";
import ExcelDownload from "../../components/UI/DownloadPopUp/DownloadPopUp";

const sortOptions = {
  column: "createdAt",
  active: "Naujausi viršuje",
  inactive: "Naujausi apačioje",
  main: "Naujausi viršuje",
};

const WasteDisposal = function ({
  wasteActivityTypes,
  primarySources,
  wastes,
  formations,
  materials,
}: {
  wasteActivityTypes: wasteActivityTypeType[];
  primarySources: primarySource[];
  wastes: wasteType[];
  formations: formationType[];
  materials: materialType[];
}) {
  type activityDefaultFilter = {
    date: {
      startDate: string;
      endDate: string;
    };
    order: string[];
    code: string;
    waste: string;
    totalWeight: number | "";
    wasteActivityTypeId: option[];
    primarySourceId: option[];
    wasteResult: option[];
    wasteResultWeight: number | "";
    materialResult: option[];
    materialResultWeight: number | "";
    materialUsed: option[];
    materialUsedWeight: number | "";
  };

  const defaultFilter: activityDefaultFilter = {
    date: {
      startDate: "",
      endDate: "",
    },
    order: ["createdAt DESC"],
    code: "",
    waste: "",
    totalWeight: "",
    wasteActivityTypeId: [],
    primarySourceId: [],
    wasteResult: [],
    wasteResultWeight: "",
    materialResult: [],
    materialResultWeight: "",
    materialUsed: [],
    materialUsedWeight: "",
  };

  const dataExportService = new DataExportService();

  const [filter, setFilter] = useState(defaultFilter);
  const [wasteActivityTypeOptions, setWasteActivityTypeOptions] = useState<
    option[]
  >([]);
  const [primarySourceOptions, setPrimarySourceOptions] = useState<option[]>(
    []
  );
  const [wasteResultOptions, setWasteResultOptions] = useState<option[]>([]);
  const [formationOptions, setFormationOptions] = useState<option[]>([]);
  const [materialOptions, setMaterialOptions] = useState<option[]>([]);

  const [currentStart, setCurrentPage] = useState(1);
  const [wasteActivities, setWasteActivities] = useState<wasteActivityType[]>(
    []
  );
  const [count, setCount] = useState(0);

  const [pageRecordNum, setPageRecordNum] = useState(
    makeLabelInt(pageSettingOptions[1])
  );
  const limit = pageRecordNum.label;
  const offset = currentStart - 1;

  useEffect(() => {
    if (wasteActivityTypes && wasteActivityTypes.length > 0) {
      const _options = wasteActivityTypes.map((wasteActivityType) => {
        return {
          label: wasteActivityType.name,
          value: wasteActivityType.id,
        };
      });

      setWasteActivityTypeOptions(_options);
    }
  }, [wasteActivityTypes]);

  useEffect(() => {
    if (primarySources && primarySources.length > 0) {
      const _options = primarySources.map((primarySource) => {
        return {
          label: primarySource.name,
          value: primarySource.id,
        };
      });

      setPrimarySourceOptions(_options);
    }
  }, [primarySources]);

  useEffect(() => {
    if (wastes && wastes.length > 0) {
      const _options = wastes.map((waste) => {
        return {
          label: `${waste.code} ${waste.waste}`,
          value: waste.id,
        };
      });

      setWasteResultOptions(_options);
    }
  }, [wastes]);

  useEffect(() => {
    if (formations && formations.length > 0) {
      const _options = formations.map((formation) => {
        return {
          label: formation.name,
          value: formation.id,
        };
      });

      setFormationOptions(_options);
    }
  }, [formations]);

  useEffect(() => {
    if (materials && materials.length > 0) {
      const _options = materials.map((material) => {
        return {
          label: material.name,
          value: material.id,
        };
      });

      setMaterialOptions(_options);
    }
  }, [materials]);

  const Filter = (() => {
    return Object.keys(filter)
      .map((k) => {
        const multiFilterKeys: (keyof activityDefaultFilter)[] = [
          "primarySourceId",
          "wasteActivityTypeId",
          "wasteResult",
          "materialResult",
          "materialUsed",
        ];
        const key = k as keyof activityDefaultFilter;

        if (key === "date") {
          if (filter.date.startDate && filter.date.endDate) {
            return `startDate=${filter.date.startDate}&endDate=${filter.date.endDate}`;
          }

          return "startDate=&endDate=";
        } else if (multiFilterKeys.indexOf(key) >= 0) {
          return (
            key + "=" + (filter[key] as option[]).map((i) => i.value).join(",")
          );
        } else {
          return key + "=" + filter[key];
        }
      })
      .join("&");
  })();

  const wDisposalRes = useAsync(
    {
      asyncFunc: getWasteDisposal,
      funcParams: { query: Filter, limit, offset },
      immediate: true,
      clearOnError: true,
    },
    [Filter, limit, offset]
  );

  useEffect(() => {
    const wasteA = wDisposalRes.data?.wasteActivities || [];
    const count = wDisposalRes.data?.count || 0;

    setWasteActivities([...wasteA]);
    setCount(count);
  }, [wDisposalRes.data]);

  const updateFilter = (objFilter: Partial<activityDefaultFilter>) => {
    setFilter({ ...filter, ...objFilter });
  };

  const dist = Array(11).fill("10rem") as string[];
  // const dist = [1.2, 1, 0.5, 1, 0.75, 1, 0.5, 1, 0.5, 1, 0.5];

  const textConstruct: pageConstructType[] = (wasteActivities || []).map(
    (wActivity, n) => ({
      sizeDist: dist,
      typeDist: [
        textBox(moment(wActivity.date).format("YYYY-MM-DD"), true),
        textBox(`${wActivity.wasteCode} ${wActivity.wasteName}`, false),
        textBox(formatNumber(wActivity.totalWeight / 1000), false),
        textBox(wActivity.wasteActivityTypeName, false),
        textBox(wActivity.primarySourceName, false),
        textBox(wActivity.wasteResult || "", false),
        textBox(
          typeof wActivity.wasteResultWeight === "number"
            ? formatNumber(wActivity.wasteResultWeight / 1000)
            : "",
          false
        ),
        textBox(wActivity.materialResult || "", false),
        textBox(
          typeof wActivity.materialResultWeight === "number"
            ? formatNumber(wActivity.materialResultWeight / 1000)
            : "",
          false
        ),
        textBox(wActivity.materialUsed || "", false),
        textBox(
          typeof wActivity.materialUsedWeight === "number"
            ? formatNumber(wActivity.materialUsedWeight / 1000)
            : "",
          false
        ),
      ],
      hasHeader: false,
      headerText: [],
    })
  );

  const pageConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [
        childField(
          <div
            className="userList_range_wrapper"
            key={"userList_range_wrapper"}
          >
            <DiscreteRangeDatePicker
              key={"userList_range"}
              onChange={(val: range) => {
                if (val.startDate || val.startDate) {
                  updateFilter({
                    date: {
                      startDate: val.startDate
                        ? moment(new Date(val.startDate)).format("YYYY-MM-DD")
                        : "",
                      endDate: val.endDate
                        ? moment(new Date(val.endDate)).format("YYYY-MM-DD")
                        : "",
                    },
                  });
                }
              }}
              clear={!filter.date.startDate && !filter.date.endDate}
              value={undefined}
            />
          </div>
        ),
        textField(
          "",
          false,
          () => {},
          filter.waste,
          (waste) => {
            updateFilter({ waste: waste });
          }
        ),
        numberField(
          "0",
          false,
          () => {},
          `${filter.totalWeight}`,
          (value) => {
            updateFilter({
              totalWeight: (value as unknown as number) || "",
            });
          }
        ),
        multiDropField(
          wasteActivityTypeOptions,
          "Ieškoti...",
          false,
          filter.wasteActivityTypeId,
          (options) => {
            updateFilter({ wasteActivityTypeId: options });
          }
        ),
        multiDropField(
          primarySourceOptions,
          "Ieškoti...",
          false,
          filter.primarySourceId,
          (options) => {
            updateFilter({ primarySourceId: options });
          }
        ),
        multiDropField(
          wasteResultOptions,
          "Ieškoti...",
          false,
          filter.wasteResult,
          (options) => {
            updateFilter({ wasteResult: options });
          }
        ),
        numberField(
          "0",
          false,
          () => {},
          `${filter.wasteResultWeight}`,
          (value) => {
            updateFilter({
              wasteResultWeight: (value as unknown as number) || "",
            });
          }
        ),
        ///
        multiDropField(
          formationOptions,
          "Ieškoti...",
          false,
          filter.materialResult,
          (options) => {
            updateFilter({ materialResult: options });
          }
        ),
        numberField(
          "0",
          false,
          () => {},
          `${filter.materialResultWeight}`,
          (value) => {
            updateFilter({
              materialResultWeight: (value as unknown as number) || "",
            });
          }
        ),
        ///
        multiDropField(
          materialOptions,
          "Ieškoti...",
          false,
          filter.materialUsed,
          (options) => {
            updateFilter({ materialUsed: options });
          }
        ),
        numberField(
          "0",
          false,
          () => {},
          `${filter.materialUsedWeight}`,
          (value) => {
            updateFilter({
              materialUsedWeight: (value as unknown as number) || "",
            });
          }
        ),
      ],
      hasHeader: true,
      headerText: [
        Header(["Sutvarkymo data"], false),
        Header(["Atliekos pavadinimas"], false, true),
        Header(
          [
            <span
              onClick={() => {
                appendSort(`totalWeight`, true, true, { filter, updateFilter });
              }}
              className="waste_accum_toggle"
            >
              Atliekų kiekis, t
            </span>,
          ],
          false
        ),
        Header(["Atliekų tvarkymo veikla"], false, true),
        Header(["Pirminis atliekų šaltinis"], false, true),
        Header(["Susidariusi atlieka"], false, true),
        Header(
          [
            <span
              onClick={() => {
                appendSort(`materialResult`, true, true, {
                  filter,
                  updateFilter,
                });
              }}
              className="waste_accum_toggle"
            >
              Gauta medžiaga
            </span>,
          ],
          false,
          true
        ),
        ///

        Header(["Susidariusi medžiaga, daiktas"], false, true),
        Header(
          [
            <span
              onClick={() => {
                appendSort(`materialResult`, true, true, {
                  filter,
                  updateFilter,
                });
              }}
              className="waste_accum_toggle"
            >
              Gauta medžiaga
            </span>,
          ],
          false,
          true
        ),
        ///

        Header(["Naudotos medžiagos, daiktai"], false, true),
        Header(
          [
            <span
              onClick={() => {
                appendSort(`materialResult`, true, true, {
                  filter,
                  updateFilter,
                });
              }}
              className="waste_accum_toggle"
            >
              Gauta medžiaga
            </span>,
          ],
          false,
          true
        ),
      ],
    },
  ];

  return (
    <div className="waste-disposal">
      <div>
        <div className="sortFilterHeader">
          <div className="table-action">
            <FilterButtons
              multiFilters={[
                "materialResult",
                "materialUsed",
                "primarySourceId",
                "wasteResult",
                "wasteActivityTypeId",
              ]}
              filter={filter}
              setFilter={setFilter}
              defaultFilter={defaultFilter}
              exclude={["order"]}
            />
            <div className="sortHolder">
              <Sorter
                valueOptions={sortOptions}
                onClick={() => {
                  const sortValue = sortOptions.column;
                  appendSort(sortValue, true, true, { filter, updateFilter });
                }}
                rotate={!enabled(sortOptions.column, { filter, updateFilter })}
              />
            </div>
            <ExcelDownload type={"wasteDisposal"} query={Filter} />
            {/* <ButtonXlsx onClick={() => xlsxExport()} /> */}
          </div>
        </div>
      </div>
      <div className="viewExt">
        <GenerateForm
          className="journalFormMin"
          overflow
          gridDist={pageConstruct[0].sizeDist}
          loading={wDisposalRes.loading}
        >
          {pageConstruct.map((construct, n) => (
            <FormGenerator
              key={`wasteAccumulation_1_${n}`}
              gridSizeDist={construct.sizeDist}
              gridTypeDist={construct.typeDist}
              hasHeader={construct.hasHeader}
              headerText={construct.headerText}
            />
          ))}
          {textConstruct.map((construct, n) => (
            <FormGenerator
              key={`wasteAccumulation_2_${n}`}
              gridSizeDist={construct.sizeDist}
              gridTypeDist={construct.typeDist}
              hasHeader={construct.hasHeader}
              headerText={construct.headerText}
            />
          ))}
        </GenerateForm>
        <FractionList
          count={count}
          currentStart={currentStart}
          setCurrentPage={setCurrentPage}
          pageRecordNum={pageRecordNum}
          setPageRecordNum={setPageRecordNum}
        />
      </div>
    </div>
  );
};

export default WasteDisposal;
