import React from "react";

import "./style.scss";

const InputText = function ({
  name,
  value,
  onChange,
  readOnly,
  isPassword,
  placeholder,
  disabled,
}) {
  return (
    <input
      name={name ? name : ""}
      type={isPassword ? "password" : "text"}
      value={value}
      onChange={onChange}
      className="input-text"
      disabled={disabled ? true : false}
      readOnly={readOnly}
      placeholder={placeholder}
    />
  );
};

export default InputText;
