import React, { useEffect, useState } from "react";
import moment from "moment";
import WasteAccumulationForm from "./elements/WasteAccumulationForm";
import { formatNumber } from "../../helpers";
import * as statuses from "../../statuses";
import { DataExportService } from "../../services";
import { alignment, defaultDataType } from "../../services/DataExportService";
import { ReactComponent as EditLogo } from "../../assets/images/edit.svg";

import "./style.scss";
import "./WasteAccumulation.css";
import {
  option,
  pageConstructType,
} from "../../components/UI/FormGenerator/formTypes";
import {
  childField,
  Header,
  multiDropField,
  numberField,
  textBox,
  textField,
} from "../../components/UI/FormGenerator/formField";
import IconContainer from "../../components/UI/iconContainer/iconContainer";
import FractionList from "../../components/UI/fractionList/FractionList";
import {
  appendSort,
  enabled,
  makeLabelInt,
  pageSettingOptions,
} from "../../helpers/misc";
import Sorter from "../../components/elements/Sorter/Sorter";
import ButtonXlsx from "../../components/elements/ButtonXlsx/ButtonXlsx";
import FormGenerator, {
  GenerateForm,
} from "../../components/UI/FormGenerator/FormGenerator";
import DiscreteRangeDatePicker from "../../components/UI/DiscreteRangePicker/DiscreteRangePicker";
import { range } from "../Report/@types";
import NewPopUpFrame from "../../components/UI/PopupFrame/NewPopUpFrame";
import { wasteAccumulation, wasteType } from "../../@types/waste";
import { site } from "../../@types/sites";
import FilterButtons from "../../components/UI/filterButtons/FilterButtons";
import { useAsync } from "../../helpers/asyncFunc";
import { getWasteAccumulations } from "./getter";
import Notification from "../../components/UI/Notification/Notification";
import ExcelDownload from "../../components/UI/DownloadPopUp/DownloadPopUp";

const sortOptions = {
  column: "date",
  active: "Naujausi viršuje",
  inactive: "Naujausi apačioje",
  main: "Naujausi viršuje",
};

export type defaultFormData = {
  id?: number;
  siteId: number;
  date: string;
  wasteId: number;
  weight: number;
  notes: string;
  isEdit: boolean;
};
export const defaultFormData: Partial<defaultFormData> = {
  id: undefined,
  siteId: undefined,
  date: "",
  wasteId: undefined,
  weight: undefined,
  notes: "",
  isEdit: false,
};

const WasteAccumulation = ({
  sites,
  wastes,
  wasteAccumulationsSavingStatus,
  resetWasteAccumulationSavingStatus,
}: {
  sites: site[];
  wastes: wasteType[];
  wasteAccumulationsSavingStatus: number;
  resetWasteAccumulationSavingStatus: () => void;
}) => {
  type accumDefaultFilter = {
    order: string[];
    siteId: option[];
    wasteId: option[];
    date: {
      startDate: string;
      endDate: string;
    };
    weight: number | "";
    notes: string;
  };

  const defaultFilter: accumDefaultFilter = {
    order: ["date DESC"],
    siteId: [],
    wasteId: [],
    date: {
      startDate: "",
      endDate: "",
    },
    weight: "",
    notes: "",
  };

  const dataExportService = new DataExportService();

  const [formData, setFormData] = useState<defaultFormData>({
    ...defaultFormData,
  } as defaultFormData);

  const [filter, setFilter] = useState(defaultFilter);
  const [weightOrder, setWeightOrder] = useState<boolean>();
  const [siteOptions, setSiteOptions] = useState<option[]>([]);
  const [wasteOptions, setWasteOptions] = useState<option[]>([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentStart, setCurrentPage] = useState(1);
  const [wasteAccumulations, setWasteAccumulations] = useState<
    wasteAccumulation[]
  >([]);
  const [count, setCount] = useState(0);

  const [pageRecordNum, setPageRecordNum] = useState(
    makeLabelInt(pageSettingOptions[1])
  );

  const limit = pageRecordNum.label;
  const offset = currentStart - 1;

  const Filter = (() => {
    const weight_order =
      typeof weightOrder === "boolean" ? (weightOrder ? "DESC" : "ASC") : "";

    const Filter = { ...filter, weight_order };
    return Object.keys(Filter)
      .map((Key) => {
        const multiFilterKeys: (keyof accumDefaultFilter)[] = [
          "siteId",
          "wasteId",
        ];

        const key = Key as keyof accumDefaultFilter;
        if (key === "date") {
          if (Filter.date.startDate && Filter.date.endDate) {
            return `startDate=${Filter.date.startDate}&endDate=${Filter.date.endDate}`;
          }

          return "startDate=&endDate=";
        } else if (multiFilterKeys.indexOf(key) >= 0) {
          return (
            key + "=" + (Filter[key] as option[]).map((i) => i.value).join(",")
          );
        } else {
          return key + "=" + Filter[key];
        }
      })
      .join("&");
  })();

  const wAccumRes = useAsync(
    {
      asyncFunc: getWasteAccumulations,
      funcParams: { query: Filter, limit, offset },
      immediate: true,
      clearOnError: true,
    },
    [Filter, limit, offset]
  );

  useEffect(() => {
    const wa = wAccumRes.data?.wasteAccumulations || [];
    const count = wAccumRes.data?.count || 0;

    setWasteAccumulations([...wa]);
    setCount(count);
  }, [wAccumRes.data]);

  useEffect(() => {
    if (
      wasteAccumulationsSavingStatus &&
      wasteAccumulationsSavingStatus === statuses.STATUS_DONE
    ) {
      setFilter({ ...defaultFilter, order: filter.order });
      const _query = `order=${filter.order}`;
      wAccumRes.execute({ limit, offset, query: _query });
      setIsFormVisible(false);
    }
  }, [wasteAccumulationsSavingStatus]);

  useEffect(() => {
    if (sites && sites.length > 0) {
      const options = sites.map((site: any) => {
        return {
          label: site.name,
          value: site.id,
        };
      });

      setSiteOptions(options);
    }
  }, [sites]);

  useEffect(() => {
    if (wastes && wastes.length > 0) {
      const options = wastes.map(
        (waste: { code: any; waste: any; id: any }) => {
          return {
            label: `${waste.code} ${waste.waste}`,
            value: waste.id,
          };
        }
      );
      setWasteOptions(options);
    }
  }, [wastes]);

  const updateFilter = (objFilter: Partial<accumDefaultFilter>) => {
    setFilter({ ...filter, ...objFilter });
  };

  const editRow = (accumulation: wasteAccumulation) => {
    setIsFormVisible(true);
    setFormData({
      date: accumulation.date,
      id: accumulation.id,
      isEdit: true,
      notes: accumulation.notes,
      siteId: accumulation.siteId,
      wasteId: accumulation.wasteId,
      weight: accumulation.weight,
    });
  };

  const xlsxExport = () => {
    dataExportService.init("Susidarymas", [
      {
        name: "Aikštelė",
        key: "siteName",
        width: 30,
        dataType: defaultDataType.string,
        style: { alignment: alignment.middleLeft },
      },
      {
        name: "Gavimo data",
        key: "date",
        width: 30,
        dataType: defaultDataType.string,
        style: { alignment: alignment.middleCenter },
      },
      {
        name: "Atlieka",
        key: "wasteName",
        width: 90,
        dataType: defaultDataType.string,
        style: { alignment: alignment.middleLeft },
      },
      {
        name: "Gautas kiekis",
        key: "weight",
        width: 30,
        dataType: defaultDataType.string,
        style: { alignment: alignment.middleLeft },
      },
      {
        name: "Pastaba",
        key: "notes",
        width: 30,
        dataType: defaultDataType.string,
        style: { alignment: alignment.middleLeft },
      },
    ]);

    const rows = wasteAccumulations
      ? wasteAccumulations.map((wasteAccumulation) => {
          const _wasteAccumulation = { ...wasteAccumulation };
          _wasteAccumulation.date = moment(_wasteAccumulation.date).format(
            "YYYY-MM-DD"
          );

          return _wasteAccumulation;
        })
      : [];

    dataExportService.export(rows);
  };

  const dist = [2, 3, 4.5, 1.5, 2.5, `4rem`];

  const textConstruct: pageConstructType[] = (wasteAccumulations || []).map(
    (wasteAccumulation, n) => ({
      sizeDist: dist,
      typeDist: [
        textBox(wasteAccumulation.siteName, false),
        textBox(moment(wasteAccumulation.date).format("YYYY-MM-DD"), true),
        textBox(wasteAccumulation.wasteName, false),
        textBox(formatNumber(wasteAccumulation.weightRemains), true),
        textBox(wasteAccumulation.notes, false),
        childField(
          <div className="center" key={n}>
            <IconContainer size="lg">
              <EditLogo onClick={() => editRow(wasteAccumulation)} />
            </IconContainer>
          </div>
        ),
      ],
      hasHeader: false,
      headerText: [],
    })
  );

  const pageConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [
        multiDropField(
          siteOptions,
          "Ieškoti...",
          false,
          filter.siteId,
          (options) => {
            updateFilter({ siteId: options });
          }
        ),
        childField(
          <div
            className="userList_range_wrapper"
            key={"userList_range_wrapper"}
          >
            <DiscreteRangeDatePicker
              key={"userList_range"}
              onChange={(val: range) => {
                if (val.startDate || val.startDate) {
                  updateFilter({
                    date: {
                      startDate: val.startDate
                        ? moment(new Date(val.startDate)).format("YYYY-MM-DD")
                        : "",
                      endDate: val.endDate
                        ? moment(new Date(val.endDate)).format("YYYY-MM-DD")
                        : "",
                    },
                  });
                }
              }}
              clear={!filter.date.startDate && !filter.date.endDate}
              value={undefined}
            />
          </div>
        ),
        multiDropField(
          wasteOptions,
          "Ieškoti...",
          false,
          filter.wasteId,
          (options) => {
            updateFilter({ wasteId: options });
          }
        ),
        numberField(
          "0",
          false,
          () => {},
          `${filter.weight}`,
          (value) => {
            updateFilter({
              weight: parseFloat(value) ? (value as unknown as number) : "",
            });
          }
        ),
        textField(
          "",
          false,
          () => {},
          filter.notes,
          (note) => {
            updateFilter({ notes: note });
          }
        ),
      ],
      hasHeader: true,
      headerText: [
        Header(["Aikštelė"], false, true),
        Header(["Data"], false),
        Header(["Atlieka"], false, true),
        Header(
          [
            <span
              onClick={() => {
                setFilter({ ...defaultFilter });
                setWeightOrder(!weightOrder);
              }}
              className="waste_accum_toggle"
            >
              Kiekis, t
            </span>,
          ],
          false
        ),
        Header(["Pastaba"], false, true),
      ],
    },
  ];

  return (
    <div className="wasteAccumulation">
      <Notification
        reset={resetWasteAccumulationSavingStatus}
        isDone={
          !!(
            wasteAccumulationsSavingStatus &&
            wasteAccumulationsSavingStatus === statuses.STATUS_DONE
          )
        }
        error={false}
        text={
          wasteAccumulationsSavingStatus &&
          wasteAccumulationsSavingStatus === statuses.STATUS_DONE ? (
            <div className={`DATASET__notification-text`}>
              <img
                src={require("../../assets/images/tick.svg").default}
                alt=""
              />
              Waste accumulation list updated
            </div>
          ) : undefined
        }
      />
      <div>
        <div className="DATASET__header-custom">
          <div className="table-action">
            <FilterButtons
              multiFilters={["siteId", "wasteId"]}
              filter={filter}
              setFilter={setFilter}
              defaultFilter={defaultFilter}
              exclude={["order"]}
            />
            <div className="sortHolder">
              <Sorter
                valueOptions={sortOptions}
                onClick={(title) => {
                  const sortValue = sortOptions.column;
                  setWeightOrder(undefined);
                  appendSort(sortValue, true, undefined, {
                    filter,
                    updateFilter,
                  });
                }}
                rotate={!enabled(sortOptions.column, { filter, updateFilter })}
              />
            </div>
            <button
              type="button"
              className="btn-add"
              onClick={() => setIsFormVisible(true)}
            >
              Naujas įrašas
            </button>
            <ExcelDownload type={"wasteAccumulation"} query={Filter} />
            {/* <ButtonXlsx onClick={() => xlsxExport()} /> */}
          </div>
        </div>
      </div>
      <div className="viewExt">
        <GenerateForm loading={wAccumRes.loading}>
          {pageConstruct.map((construct, n) => (
            <FormGenerator
              key={`wasteAccumulation_1_${n}`}
              gridSizeDist={construct.sizeDist}
              gridTypeDist={construct.typeDist}
              hasHeader={construct.hasHeader}
              headerText={construct.headerText}
            />
          ))}
          {textConstruct.map((construct, n) => (
            <FormGenerator
              key={`wasteAccumulation_2_${n}`}
              gridSizeDist={construct.sizeDist}
              gridTypeDist={construct.typeDist}
              hasHeader={construct.hasHeader}
              headerText={construct.headerText}
            />
          ))}
        </GenerateForm>
        <FractionList
          count={count}
          currentStart={currentStart}
          setCurrentPage={setCurrentPage}
          pageRecordNum={pageRecordNum}
          setPageRecordNum={setPageRecordNum}
        />
      </div>
      <NewPopUpFrame
        showShadow={false}
        handleOpen={setIsFormVisible}
        title={``}
        isShown={isFormVisible}
        small
        className="wasteAccumPopUp"
        classNameWrap="wasteAccumForm_wrap"
      >
        <WasteAccumulationForm
          formData={formData as defaultFormData}
          setFormData={setFormData as (data: Partial<defaultFormData>) => void}
          onExit={() => setIsFormVisible(false)}
          isVisible={true}
        />
      </NewPopUpFrame>
    </div>
  );
};

export default WasteAccumulation;
