import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { store } from "../..";
import {
  getBillings as get_billings,
  getInactiveBillings as get_inactive_billings,
} from "../actions";
import { FetchRX } from "../../helpers/misc";

export const getBillings = (action$) =>
  action$.pipe(
    ofType(actions.GET_BILLINGS),
    mergeMap(() =>
      FetchRX("/billings").pipe(
        map((result) => {
          return {
            type: actions.SET_BILLINGS,
            payload: result.billings,
          };
        }),
        catchError((error) => {
          console.log(error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const getInactiveBillings = (action$) =>
  action$.pipe(
    ofType(actions.GET_INACTIVE_BILLINGS),
    mergeMap(() =>
      FetchRX("/billings/inactive").pipe(
        map((result) => {
          return {
            type: actions.SET_INACTIVE_BILLINGS,
            payload: result.billings,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const deactivateBilling = (action$) =>
  action$.pipe(
    ofType(actions.DEACTIVATE_BILLING),
    mergeMap((action) =>
      FetchRX("/billings/deactivate", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_billings());
          store.dispatch(get_inactive_billings());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const hideBilling = (action$) =>
  action$.pipe(
    ofType(actions.HIDE_BILLING),
    mergeMap((action) =>
      FetchRX("/billings/hide", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_billings());
          store.dispatch(get_inactive_billings());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const restoreBilling = (action$) =>
  action$.pipe(
    ofType(actions.RESTORE_BILLING),
    mergeMap((action) =>
      FetchRX("/billings/restore", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_billings());
          store.dispatch(get_inactive_billings());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const createBilling = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_BILLING),
    mergeMap((action) =>
      FetchRX("/billings/create", {
        method: "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_BILLINGS,
            payload: result.billings,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const editBilling = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_BILLING),
    mergeMap((action) =>
      FetchRX("/billings/edit", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_BILLINGS,
            payload: result.billings,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
