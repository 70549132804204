import { Fetch } from "../../helpers/misc";

export const signIn = async (params: { uname: string; paswd: string }) => {
  const { paswd, uname } = params;

  if (!paswd || !uname) {
    return;
  }

  return await Fetch("/auth/signin", {
    method: "POST",
    body: JSON.stringify({ uname, paswd }),
  })
    .then((user) => {
      const response = user.json() as Promise<{
        authInfo: {
          user: {
            id: number;
            firstName: string;
            lastName: string;
            isAdmin: boolean;
            roleId: number | null;
            isActive: boolean;
            isDeleted: boolean;
            userGroupId: number | null;
          };
          authToken: string;
        };
      }>;

      return response;
    })
    .catch((err) => {
      throw err;
    });
};
