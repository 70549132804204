import React, { useEffect, useRef, useState } from "react";
import { billing, company } from "../../../@types/client";
import {
  defaultOption,
  isDigit,
  isLTNum,
  rearrangeMunic,
  shortName,
} from "../../../helpers/misc";
import {
  companyType,
  municipalityType,
} from "../../../pages/WasteAcceptance/@types";
import {
  getFilterCompanies,
  nullPartial,
} from "../../../pages/WasteAcceptance/defaultData";
import { filter_companies_ID } from "../../../pages/WasteAcceptance/WasteAcceptance";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeSVG.svg";
import { dropField, Header, textField } from "../../UI/FormGenerator/formField";
import FormGenerator from "../../UI/FormGenerator/FormGenerator";
import { option, pageConstructType } from "../../UI/FormGenerator/formTypes";

import "./AcceptanceCompany.css";
import { carrier } from "../../../@types/user";

const size7 = [1.5, 1, 1, 1, 1, 1, 1];

const AcceptanceCompany = ({
  details,
  setDetails,
  municipalities,
  isComplete,
  setSearchParams,
  searchParams,
  billings,
  carriers,

  companies,
  companyStandard,
  setCompanyStandard,
  setCompanies,
}: {
  details: companyType;
  setDetails: (data: nullPartial<companyType>) => void;
  municipalities: municipalityType[];
  isComplete: boolean;
  setSearchParams: (
    data: Partial<{ companyName: string; companyCode: number }>
  ) => void;
  searchParams: Partial<{ companyName: string; companyCode: number }>;
  billings: billing[];
  carriers: carrier[];

  companies: company[];
  companyStandard: company | undefined;
  setCompanyStandard: (data: company) => void;
  setCompanies: (data: company[]) => void;
}) => {
  const mountedRef = useRef(true);
  const [municipalityOptions, setMunicipalityOptions] = useState<option[]>([]);
  const [billingOptions, setBillingOptions] = useState<option[]>([]);
  const [carrierOptions, setCarrierOptions] = useState<option[]>([]);

  useEffect(() => {
    if (billings) {
      const option = billings.map((bill) => ({
        label: bill.name,
        value: bill.id,
      }));
      setBillingOptions([...option]);
    }
  }, [billings]);
  useEffect(() => {
    if (carriers) {
      const option = carriers.map((carrier) => ({
        label: carrier.name,
        value: carrier.id,
      }));
      setCarrierOptions([...option]);
    }
  }, [carriers]);

  const set = (obj: Partial<nullPartial<companyType>>) => {
    setDetails({ ...details, ...obj });
  };

  useEffect(() => {
    // useEffect for setting filtered companies

    mountedRef.current = true;
    const shouldSearch = Object.keys(searchParams).some(
      (param) => !!searchParams[param as keyof typeof searchParams]
    );
    if (shouldSearch) {
      getFilterCompanies(searchParams).then(
        ({ companies }) => {
          if (mountedRef.current) {
            setCompanies([...companies]);
          }
        },
        (error) => {
          if (mountedRef.current) {
            setCompanies([]);
          }
        }
      );
    } else {
      setCompanies([]);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [searchParams]);

  useEffect(() => {
    // useEffect for clearing the search params when outside the list container is clicked
    const outsideClick = (e: MouseEvent) => {
      const element = document.getElementById(filter_companies_ID);
      if (element && !element.contains(e.target as Node)) {
        setSearchParams({});
      }
    };

    document.addEventListener("click", outsideClick);
    return () => {
      document.removeEventListener("click", outsideClick);
    };
  }, []);
  useEffect(() => {
    // This useEffect hook is for getting and setting the ID of the company
    mountedRef.current = true;

    // If the standard differs in more than 2 positions, remove ID
    // We are not clearing standard because it just a cache and the edit could be a mistake and
    // be corrected
    const companyName = details.companyName;
    const companyCode = parseInt(details.companyCode) || undefined;
    if (
      // If there is a standard and we have at least two matches (including that of the pin)
      companyStandard &&
      companyCode === parseInt(companyStandard.companyCode)
    ) {
      if (details.id !== companyStandard.id) {
        set({ id: companyStandard.id });
      }
    } else {
      /*
        If the above is not true, try to get the Id when the details are complete
        Or set the id to null pending when the details are complete
      */
      if (isComplete) {
        // Remove searchParams if all the fields are filled
        setSearchParams({});
        /* 
          If there is no ID and we have enough details, 
          get the ID of the user
        */
        if (!details.id) {
          getFilterCompanies({
            companyCode,
            companyName,
            strict: true,
          }).then(({ companies }) => {
            if (mountedRef.current) {
              if (companies && companies.length) {
                const company = companies[0];
                // set a standard here
                // We need to be aware of if it was a clicked standard or not.
                setCompanyStandard(company);
                set({
                  id: company.id,
                });
              }
            }
          });
        } else {
          /* 
        Remove ID if it is not inline with the standard and there exist an unknown ID
        This is to ensure that on rerunning it,a fresh ID can be set if a familiar record is found
        
        Remove ID only if there is an already existing ID to prevent multiple rerenders
        */
          set({ id: null });
        }
      } else {
        /* 
        Only make hidden queries when all the fields are filled.
        If all fields are not filled, set id to null
        The ID will eventually be set when fields are complete
        */
        if (details.id) {
          // Remove ID only if there is an already existing ID to prevent multiple rerenders
          set({ id: null });
        }
      }
    }
    return () => {
      mountedRef.current = false;
    };
  }, [details, companyStandard, isComplete]);

  useEffect(() => {
    if (municipalities.length) {
      const options = rearrangeMunic(
        municipalities.map((munic) => ({
          value: munic.id,
          label: shortName(munic.name),
        }))
      );
      setMunicipalityOptions([...options]);
    }
  }, [municipalities]);

  const municipality_value = (id?: number | string): option => {
    id = parseInt(`${id}`);
    const municOption = municipalityOptions.find((opt) => opt.value === id);

    return municOption || defaultOption;
  };
  const billing_value = (id?: number): option => {
    id = parseInt(`${id}`);
    const option = billingOptions.find((opt) => opt.value === id);

    return option || defaultOption;
  };
  const carrier_value = (id?: number): option => {
    id = parseInt(`${id}`);
    const option = carrierOptions.find((opt) => opt.value === id);

    return option || defaultOption;
  };

  const headConstruct: pageConstructType = {
    sizeDist: size7,
    typeDist: [],
    hasHeader: true,
    headerText: [
      Header(["Įmonės pavadinimas"], false),
      Header(["Įmonės kodas"], false),
      Header(["Automobilio nr."], false),
      Header(["Vežėjas"], false),
      Header(["Sutarties Nr."], false),
      Header(["Atsiskaitymas"], false),
      Header(["Savivaldybė"], false),
    ],
  };

  const rowConstruct = [
    {
      sizeDist: size7,
      typeDist: [
        textField(
          "",
          false,
          () => {},
          details.companyName,
          (companyName) => {
            set({ companyName });

            setSearchParams({
              companyName:
                companyName && companyName.length > 2 ? companyName : undefined,
            });
          }
        ),
        textField(
          "",
          false,
          () => {},
          details.companyCode,
          (companyCode) => {
            if (isLTNum(companyCode) && isDigit(companyCode)) {
              set({ companyCode });
              setSearchParams({
                companyCode:
                  companyCode.length > 2
                    ? parseInt(companyCode) || undefined
                    : undefined,
              });
            }
          }
        ),
        textField(
          "",
          false,
          () => {},
          details.carNr,
          (carNr) => {
            set({ carNr });
          }
        ),
        dropField(
          carrierOptions,
          "",
          false,
          carrier_value(details.carrier || undefined),
          (carrier) => {
            set({ carrier });
          },
          true
        ),
        textField(
          "",
          false,
          () => {},
          details.contractNr,
          (contractNr) => {
            set({ contractNr });
          }
        ),
        dropField(
          billingOptions,
          "",
          false,
          billing_value(details.billing),
          (billing) => {
            set({ billing });
          },
          true
        ),
        dropField(
          municipalityOptions,
          "",
          false,
          municipality_value(details.municipalityId),
          (municipalityId) => {
            set({ municipalityId });
          },
          true
        ),
      ],
      hasHeader: false,
      headerText: [],
    },
  ];

  return (
    <div className="acceptanceForm_wrapper accpt_comp">
      {companies.length ? (
        <div className="waste_person_filter">
          <div className="waste_person_filter_container">
            <div className="inner" id={filter_companies_ID}>
              <div
                onClick={() => {
                  setSearchParams({});
                  setCompanies([]);
                }}
                className="close_person_filter center"
              >
                <CloseIcon className="img_div_contain" />
              </div>
              {companies.map((company, n) => (
                <div
                  className="filter_item"
                  key={n}
                  onClick={() => {
                    setSearchParams({});
                    setCompanies([]);

                    set({
                      id: company.id,
                      carNr: company.carNr,
                      carrier: company.carrier,
                      companyName: company.companyName,
                      companyCode: company.companyCode,
                      contractNr: company.contractNr,
                      billing: company.billing,
                      municipalityId: company.municipalityId,
                      vatcode: company.vatcode,
                      email: company.email,
                      address: company.address,
                    });
                    setCompanyStandard(company);
                  }}
                >
                  {company.companyName} {company.companyCode}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        ``
      )}
      {[
        <FormGenerator
          key={`acceptanceCompany_headerField_1_${0}`}
          gridSizeDist={headConstruct.sizeDist}
          gridTypeDist={headConstruct.typeDist}
          hasHeader={headConstruct.hasHeader}
          headerText={headConstruct.headerText}
        />,
      ]}
      {rowConstruct.map((construct, n) => (
        <FormGenerator
          key={`acceptanceCompany_rowField_1_${n}`}
          gridSizeDist={construct.sizeDist}
          gridTypeDist={construct.typeDist}
          hasHeader={construct.hasHeader}
          headerText={construct.headerText}
        />
      ))}
    </div>
  );
};

export default AcceptanceCompany;
