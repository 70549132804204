import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { FetchRX } from "../../helpers/misc";

export const getWastes = (action$) =>
  action$.pipe(
    ofType(actions.GET_WASTES),
    mergeMap(() =>
      FetchRX("/wastes").pipe(
        map((result) => {
          return {
            type: actions.SET_WASTES,
            payload: result.wastes,
          };
        }),
        catchError((error) => {
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const deleteWaste = (action$) =>
  action$.pipe(
    ofType(actions.DELETE_WASTE),
    mergeMap((action) =>
      FetchRX(`/wastes/${action.payload}`, {
        method: "DELETE",
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_WASTES,
            payload: result.wastes,
          };
        }),
        catchError((error) => {
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
