import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { store } from "../..";
import {
  getRoles as get_roles,
  getInactiveRoles as get_inactive_roles,
} from "../actions";
import { FetchRX } from "../../helpers/misc";

export const getRoles = (action$) =>
  action$.pipe(
    ofType(actions.GET_ROLES),
    mergeMap(() =>
      FetchRX("/roles").pipe(
        map((result) => {
          return {
            type: actions.SET_ROLES,
            payload: result.roles,
          };
        }),
        catchError((error) => {
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const getInactiveRoles = (action$) =>
  action$.pipe(
    ofType(actions.GET_INACTIVE_ROLES),
    mergeMap(() =>
      FetchRX("/roles/inactive").pipe(
        map((result) => {
          return {
            type: actions.SET_INACTIVE_ROLES,
            payload: result.roles,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const deactivateRole = (action$) =>
  action$.pipe(
    ofType(actions.DEACTIVATE_ROLE),
    mergeMap((action) =>
      FetchRX("/roles/deactivate", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_roles());
          store.dispatch(get_inactive_roles());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const hideRole = (action$) =>
  action$.pipe(
    ofType(actions.HIDE_ROLE),
    mergeMap((action) =>
      FetchRX("/roles/hide", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_roles());
          store.dispatch(get_inactive_roles());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const restoreRole = (action$) =>
  action$.pipe(
    ofType(actions.RESTORE_ROLE),
    mergeMap((action) =>
      FetchRX("/roles/restore", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_roles());
          store.dispatch(get_inactive_roles());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const createRole = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_ROLE),
    mergeMap((action) =>
      FetchRX("/roles/create", {
        method: "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_ROLES,
            payload: result.roles,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const editRole = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_ROLE),
    mergeMap((action) =>
      FetchRX("/roles/edit", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_ROLES,
            payload: result.roles,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
