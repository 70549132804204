import { option } from "../../components/UI/FormGenerator/formTypes";
import { alignment, defaultDataType } from "../../services/DataExportService";

export type headerKey =
  | `date`
  | `startDate`
  | `endDate`
  | `weight`
  | `name`
  | `surname`
  | `companyName`
  | `waste`
  | `site`
  | `municipality`
  | `origin`
  | `primarySource`
  | `holderType`
  | `wasteHolder`
  | `companyHolder`
  | `carrier`
  | `recipient`
  | `carrier`
  | `contractNr`
  | `invoice`
  | `year`
  | `source`
  | `managementActivities`
  | `resultingPerform`
  | `billing`
  | `user`
  | "previous_value"
  | "new_value"
  | "paidPrice"
  | "paidPriceVat"
  | "totalPrice"
  | "totalPriceVat";

export type headerQuery =
  | `startDate`
  | `endDate`
  | `name`
  | `surname`
  | `companyName`
  | `wasteId`
  | `siteId`
  | `municipalityId`
  | `originId`
  | `primarySource`
  | `primarySourceWaste`
  | `primaryWasteSource`
  | `holderType`
  | `wasteHolder`
  | `carrier`
  | `recipient`
  | `carrier`
  | `contractNr`
  | `invoice`
  | `year`
  | `source`
  | `managementActivities`
  | `resultingPerform`;

export type headerTitle =
  | "Laikotarpis"
  | "Vardas"
  | "Pavardė"
  | "Įmonės pavadinimas"
  | "Atlieka"
  | "Objektas"
  | "Šaltinis"
  | "Atliekų turėtojo rūšis"
  | "Savivaldybė"
  | "Atliekų turėtojas"
  | "Vežėjas"
  | "Atliekų kilmė"
  | "Pirminis atliekų šaltinis"
  | "Atliekų gavėjas"
  | "Atliekų vežėjas"
  | "Sutarties Nr."
  | "Sąskaita faktūra"
  | "Metai"
  | "Atliekų šaltinis"
  | "Atliekų tvarkymo veikla"
  | "Susidariusi atlieka"
  | `Atsiskaitymas`
  | `Gautas kiekis, t`
  | "Darbuotojas"
  | "Iki korekcijos (kg)"
  | "Po korekcijos (kg)"
  | "Suma be PVM"
  | "Suma su PVM"
  | "Kaina (kg) be PVM"
  | "Kaina (kg) su PVM";

export type declarationType = {
  key: headerKey;
  width?: number;
  dataType?: typeof defaultDataType.string;
  style?: { alignment: alignment.middleLeft };
};

type declarationDefaultType = {
  key: string;
  width?: number;
  dataType?: typeof defaultDataType.string;
  style?: { alignment: alignment.middleLeft };
};
export const reportDeclaration: { [key in headerTitle]: declarationType } = {
  Laikotarpis: {
    key: "date",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Vardas: {
    key: "name",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Pavardė: {
    key: "surname",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Įmonės pavadinimas": {
    key: "companyName",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Atlieka: {
    key: "waste",
    width: 60,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Objektas: {
    key: "site",
    width: 40,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Šaltinis: {
    key: "primarySource",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Atliekų turėtojo rūšis": {
    key: "holderType",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Savivaldybė: {
    key: "municipality",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Atliekų turėtojas": {
    key: "wasteHolder",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Vežėjas: {
    key: "companyHolder",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Atliekų kilmė": {
    key: "origin",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Pirminis atliekų šaltinis": {
    key: "primarySource",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Atliekų gavėjas": {
    key: "recipient",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Atliekų vežėjas": {
    key: "carrier",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Sutarties Nr.": {
    key: "contractNr",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Sąskaita faktūra": {
    key: "invoice",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Metai: {
    key: "year",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Atliekų šaltinis": {
    key: "source",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Atliekų tvarkymo veikla": {
    key: "managementActivities",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Susidariusi atlieka": {
    key: "resultingPerform",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Atsiskaitymas: {
    key: "billing",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Gautas kiekis, t": {
    key: "weight",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  Darbuotojas: {
    key: "user",
    width: 30,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Po korekcijos (kg)": {
    key: "new_value",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Iki korekcijos (kg)": {
    key: "previous_value",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Suma be PVM": {
    key: "totalPrice",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Suma su PVM": {
    key: "totalPriceVat",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Kaina (kg) be PVM": {
    key: "paidPrice",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
  "Kaina (kg) su PVM": {
    key: "paidPriceVat",
    width: 20,
    dataType: defaultDataType.string,
    style: { alignment: alignment.middleLeft },
  },
};

export const getReportDeclaration = (
  queryObj: headerTitle[]
): (
  | (declarationType & { name: headerTitle })
  | (declarationDefaultType & { name: headerTitle })
)[] => {
  const mapper = new Map();
  queryObj.forEach((key) => {
    const declaration = reportDeclaration[key as headerTitle];
    if (declaration) {
      mapper.set(key, { ...declaration, name: key });
    } else {
      mapper.set(key, {
        name: key,
        key: key,
        width: 12,
        dataType: defaultDataType.string,
        style: { alignment: alignment.middleLeft },
      });
    }
  });
  return Array.from(mapper.values());
};

export const getReportDeclarationService = (
  name: headerTitle
):
  | (declarationType & { name: headerTitle })
  | (declarationDefaultType & { name: headerTitle }) => {
  const declaration = reportDeclaration[name];
  if (declaration) {
    return { ...declaration, name };
  } else {
    return {
      name: name,
      key: name,
      width: 18,
      dataType: defaultDataType.string,
      style: { alignment: alignment.middleLeft },
    };
  }
};

export const sanitizeReportBody = (body: {
  [key: string]: any;
}): { [key: string]: any } => {
  let newReportObject: { [key: string]: any } = {};
  Object.keys(body).forEach((key) => {
    const value = body[key];
    if (Array.isArray(value)) {
      if (value.some((val) => typeof val === "object")) {
        newReportObject[key] = value.map((item: option) => item.value);
      } else {
        newReportObject[key] = value;
      }
    } else {
      newReportObject[key] = value;
    }
  });

  return newReportObject;
};
