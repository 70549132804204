import { connect } from "react-redux";

import UserGroupList from "./UserGroupList";
import * as actions from "../../../../../stores/actions";

const mapStoreStateToProps = function (store) {
  return {
    userGroups: store.userGroupReducer.userGroups,
    currentUserId: store.authReducer?.currentUser?.id,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    getUserGroups: function (query) {
      dispatch(actions.getUserGroups(query));
    },
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(UserGroupList);
