import React, { useState, useRef, useEffect } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import SignaturePad from "react-signature-canvas";
import NewPopUpFrame from "../PopupFrame/NewPopUpFrame";
import { ReactComponent as CloseSvg } from "../../../assets/images/closeSVG.svg";

import "./Signature.css";

const Signature = ({
  className,
  dataUrl,
  setDataUrl,
}: {
  className?: string;
  dataUrl: string | undefined;
  setDataUrl: (data?: string) => void;
}) => {
  const sigPad = useRef<ReactSignatureCanvas>(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const empty =
    sigPad.current?.isEmpty() === undefined
      ? true
      : !!sigPad.current?.isEmpty();

  useEffect(() => {
    setIsEmpty(empty);
  }, []);

  const clear = () => {
    sigPad.current?.clear();
    setDataUrl(undefined);
    setIsEmpty(true);
  };

  const open = () => {
    if (isOpen) {
      clear();
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const trim = () => {
    if (sigPad.current && !empty) {
      const dataURL = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
      setDataUrl(dataURL);
    }
    setIsOpen(false);
  };

  return (
    <div className={`sig_wrapper ${className || ""}`}>
      {isOpen ? (
        <NewPopUpFrame
          showShadow={false}
          handleOpen={open}
          title={`Parašas`}
          isShown={isOpen}
          small
        >
          <div className="sig_container_wrapper">
            <div className="sigContainer">
              <SignaturePad
                ref={sigPad}
                onEnd={() => {
                  const empty =
                    sigPad.current?.isEmpty() === undefined
                      ? true
                      : !!sigPad.current?.isEmpty();
                  setIsEmpty(empty);
                }}
              />
            </div>
            <div className="signature_button_wrapper">
              <button onClick={clear} className=" flexBtn btn-default">
                Valyti
              </button>
              <button
                disabled={isEmpty}
                onClick={trim}
                className="flexBtn btn-green"
              >
                Išsaugoti
              </button>
            </div>
          </div>
        </NewPopUpFrame>
      ) : (
        ``
      )}
      {dataUrl ? (
        <div className="close_sig_wrapper">
          <CloseSvg onClick={clear} />
        </div>
      ) : (
        ``
      )}
      <div className="sign_pic_wrapper flex1">
        {dataUrl ? (
          <img className="sig_image img_div_contain" src={dataUrl} />
        ) : null}
      </div>
      {!dataUrl ? (
        <div className="open_sig_wrapper">
          <button
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Pasirašyti
          </button>
        </div>
      ) : (
        ``
      )}
    </div>
  );
};

export default Signature;
