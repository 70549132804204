import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Main } from "./components/containers";
import defaultSubMenu2 from "./subMenu2";
import "./App.css";
import {
  Disposal,
  DisposalJournal,
  Home,
  Settings,
  SignIn,
  WasteAcceptance,
  WasteAccumulation,
  WasteJournal,
  WasteManagement,
  WasteDisposal,
} from "./pages";
import { ConfirmDialog } from "./components/UI";
import { ReactComponent as Slyva } from "./assets/images/slyva.svg";

import * as actions from "./stores/actions";
import Report from "./pages/Report";
import Suggestion from "./pages/Suggestion";
import { SubMenu } from "./components/elements";
import DocView from "./pages/DocView/DocView";
import useVersion from "./helpers/useVersion";
import NavSign from "./pages/SignIn/NavSign";

const wasteUrl = {
  journal: "/waste-journal",
  management: "/waste-management",
  wasteDisposal: "/waste-disposal",
  accumulation: "/waste-accumulation",
  disposal: "/disposal",
  disposalJournal: "/disposal-journal",
};

const App = function ({
  currentUser,
  confirmDialog,
  getUserSites,
  getCompanies,
  getDocuments,
  getFeatures,
  getGroups,
  getMunicipalities,
  getOrigins,
  getRoles,
  getUserGroups,
  getSites,
  getAllSerijas,
  getSuggestions,
  getWastes,
  getCarriers,
  getBillings,
  getWasteActivityTypes,
  getFormations,
  getMaterials,
  getPrimarySources,
  setConfirmDialogYes,
  resetConfirmDialog,
}) {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useVersion();

  useEffect(() => {
    if (currentUser) {
      getCompanies();
      getDocuments();
      getFeatures();
      getGroups();
      getMunicipalities();
      getOrigins();
      getRoles();
      getUserGroups();
      getSites();
      getAllSerijas();
      getSuggestions();
      getWastes();
      getWasteActivityTypes();
      getCarriers();
      getBillings();
      getFormations();
      getMaterials();
      getPrimarySources();
      getUserSites();
    }

    if (window.innerWidth < 1080) {
      var meta = document.createElement("meta");
      meta.name = "viewport";
      meta.content = "width=1080, initial-scale=0.86, maximum-scale=5.0";
      document.getElementsByTagName("head")[0].appendChild(meta);
    }

    window.addEventListener("resize", () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }, [JSON.stringify(currentUser)]);

  const onConfirmDialogExit = (state) => {
    if (state) {
      setConfirmDialogYes();
      return;
    }

    resetConfirmDialog();
  };

  const routes = [
    { path: "/", element: <Home /> },
    { path: "/waste-acceptance", element: <WasteAcceptance /> },
    { path: "/report", element: <Report /> },
    { path: wasteUrl.journal, element: <WasteJournal /> },
    { path: wasteUrl.management, element: <WasteManagement /> },
    { path: wasteUrl.wasteDisposal, element: <WasteDisposal /> },
    { path: wasteUrl.accumulation, element: <WasteAccumulation /> },
    { path: wasteUrl.disposal, element: <Disposal /> },
    { path: wasteUrl.disposalJournal, element: <DisposalJournal /> },
    { path: "/settings/*", element: <Settings /> },
  ];

  return (
    <AuthProvider currentUser={currentUser}>
      <div className="App">
        <ProtectedComp>
          <Suggestion />
        </ProtectedComp>
        <Routes>
          <Route path={"/doc"} element={<DocRoute />} />
          <Route path={"/signin"} element={<SignIn />}></Route>
          {routes.map(({ path, element }, n) => (
            <Route
              key={`${path}-${n}`}
              path={path}
              element={
                <Main>
                  <ProtectedRoute path={path}>{element}</ProtectedRoute>
                </Main>
              }
            ></Route>
          ))}
        </Routes>
        {confirmDialog.txt && (
          <ConfirmDialog
            txt={confirmDialog ? confirmDialog.txt : ""}
            isVisible={!!confirmDialog.txt}
            onExit={onConfirmDialogExit}
          />
        )}
        {process.env.REACT_APP_ENV === "development" && (
          <div
            className="both-error"
            style={{ position: "fixed", bottom: "0", zIndex: "99999" }}
          >
            Window width: {screenSize.width}px, window height:{" "}
            {screenSize.height}px ============= (Debugging purpose only, will be
            removed on production build)
          </div>
        )}
        <div className="plum_logo_wrapper center fdCol">
          <span>Sistemą kūrė:</span>
          <a href="https://slyva.lt" target="_blank">
            <Slyva className="img_div_contain" />
          </a>
        </div>
      </div>
    </AuthProvider>
  );
};

let AuthContext = React.createContext();

function AuthProvider({ currentUser, children }) {
  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useAuth() {
  return React.useContext(AuthContext);
}

function ProtectedComp({ children }) {
  const auth = useAuth();

  return auth.currentUser ? children : ``;
}

const inWasteUrl = (value) => {
  let seen = false;

  for (const key in wasteUrl) {
    if (Object.hasOwnProperty.call(wasteUrl, key)) {
      const url = wasteUrl[key];
      if (url === value) {
        seen = true;
        break;
      }
    }
  }

  return seen;
};

function DocRoute() {
  const query = useQuery();

  const type = query.get("type");
  const id = query.get("id");
  const isCompany = query.get("isCompany");
  const token = query.get("token");

  return <DocView type={type} isCompany={isCompany} id={id} token={token} />;
}

function ProtectedRoute({ children, ...rest }) {
  const auth = useAuth();

  return auth.currentUser ? (
    inWasteUrl(rest.path) ? (
      <div className="waste_summary_container_wrapper">
        <SubMenu menus={defaultSubMenu2} />
        {children}
      </div>
    ) : (
      children
    )
  ) : (
    <NavSign />
  );
}

const mapStoreStateToProps = function (store) {
  return {
    currentUser: store.authReducer.currentUser,
    userSites: store.siteReducer.userSites,
    confirmDialog: store.commonReducer.confirmDialog,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    getCompanies: function () {
      dispatch(actions.getCompanies());
    },
    getDocuments: function () {
      dispatch(actions.getDocuments());
    },
    getFeatures: function () {
      dispatch(actions.getFeatures());
    },
    getGroups: function () {
      dispatch(actions.getGroups());
    },
    getMunicipalities: function () {
      dispatch(actions.getMunicipalities());
    },
    getOrigins: function () {
      dispatch(actions.getOrigins());
    },
    getRoles: function () {
      dispatch(actions.getRoles());
    },
    getUserGroups: function () {
      dispatch(actions.getUserGroups());
    },
    getSites: function () {
      dispatch(actions.getSites());
    },
    getAllSerijas: () => {
      dispatch(actions.getAllSerijas());
    },
    getSuggestions: function () {
      dispatch(actions.getSuggestions());
    },
    getUserSites: function () {
      dispatch(actions.getUserSites());
    },
    getWastes: function () {
      dispatch(actions.getWastes());
    },
    getPrimarySources: function () {
      dispatch(actions.getPrimarySources());
    },
    getCarriers: function () {
      dispatch(actions.getCarriers());
    },
    getBillings: function () {
      dispatch(actions.getBillings());
    },
    getWasteActivityTypes: function () {
      dispatch(actions.getWasteActivityTypes());
    },
    getFormations: function () {
      dispatch(actions.getFormations());
    },
    getMaterials: function () {
      dispatch(actions.getMaterials());
    },
    setConfirmDialogYes: function () {
      dispatch(actions.setConfirmDialogYes());
    },
    resetConfirmDialog: function () {
      dispatch(actions.resetConfirmDialog());
    },
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(App);
