import React, { useEffect, useRef, useState } from "react";
import { site } from "../../@types/sites";
import CheckBox from "../../components/UI/checkBox/checkBox";
import {
  childField,
  dropField,
  Header,
  numberField,
  textField,
} from "../../components/UI/FormGenerator/formField";
import FormGenerator, {
  GenerateForm,
  Input,
} from "../../components/UI/FormGenerator/FormGenerator";
import { ReactComponent as CloseIcon } from "../../assets/images/closeSVG.svg";
import {
  option,
  pageConstructType,
} from "../../components/UI/FormGenerator/formTypes";
import "./Waste.css";
import { defaultOption, Fetch, isNumber } from "../../helpers/misc";
import { groupType } from "../WasteAcceptance/@types";

export type defaultWaste = {
  id: number | null;
  code: string;
  waste: string;
  groupId: number | undefined;
  price: number | string;
  priceVat: number | string;
  compInvoice: boolean;
  uom: "kg" | string;
  kgPerUnit: number;
  criticalLimit: number | string;
  accumulationLimit: number | string;
  threshold: number | string;
  freeLimit: number | string;
  maxLimit: number | string;
  sites: number[];
  existingSites: number[];
  SiteWastes: defaultSiteWaste[];
  direct_vat_calc: boolean;
};

export type defaultSiteWaste = {
  id: number;
  wasteId: number;
  siteId: number;
  threshold: number;
  criticalLimit: number;
  accumulationLimit: number;
  freeLimit: number;
  maxLimit: number;
  price: number;
  priceVat: number;
  direct_vat_calc: boolean;
  compInvoice: boolean;
};

export const getFilterWastes = async (
  params: Partial<{
    code: string;
  }>
) => {
  return await Fetch(`/wastes/search?code=${params.code}`).then(
    (res) => {
      const result: Promise<{ success: boolean; wastes: defaultWaste[] }> =
        res.json();
      return result;
    },
    (error) => {
      throw error;
    }
  );
};

const Waste = ({
  handleOpen,
  sites,
  groups,
  setCreateSuccess,
  setCreateError,
  reset,
}: {
  handleOpen: () => void;
  sites: site[];
  groups: groupType[];
  setCreateSuccess: (data: boolean) => void;
  setCreateError: (data: boolean) => void;
  reset: () => void;
}) => {
  const isUnit = (unit?: string) => unit === "unit";
  const wasteDefault = {
    compInvoice: false,
  };

  const [waste, setWaste] = useState<Partial<defaultWaste>>({
    ...wasteDefault,
  });
  const [creating, setCreating] = useState(false);
  const [limited, setLimited] = useState(false);
  const [grouped, setGrouped] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [wastes, setWastes] = useState<defaultWaste[]>([]);
  const [searchParams, setSearchParams] = useState<Partial<{ code: string }>>(
    {}
  );

  const groupOptions: option[] = groups
    ? groups.map((group) => {
        return {
          label: group.name,
          value: group.id,
        };
      })
    : [];

  useEffect(() => {
    if (!limited) {
      setWaste({ ...waste, freeLimit: undefined });
    }
    if (!grouped) {
      setWaste({ ...waste, groupId: undefined });
    }
  }, [limited, grouped]);

  useEffect(() => {
    if (hasError) {
      if (!getErrorMsgs().length) {
        setHasError(false);
      }
    }
  }, [waste]);

  useEffect(() => {
    return () => {
      setHasError(false);
    };
  }, []);

  const addRemoveSite = (id: number) => {
    const sites = [...(waste.sites || [])];
    const isAvailable = sites.find((siteId) => siteId === id);

    if (isAvailable) {
      const newSites = sites.filter((siteId) => siteId !== id);

      setWaste({ ...waste, sites: [...newSites] });
    } else {
      setWaste({ ...waste, sites: [...sites, id] });
    }
  };

  const dist = [1, 2, 1, 1, "12rem", 1, "5rem"];

  const getErrorMsgs = (): string[] => {
    const errorMsgs: string[] = [];

    const cError =
      isNumber(waste.criticalLimit) && isNumber(waste.accumulationLimit)
        ? parseFloat(`${waste.criticalLimit}`) >
          parseFloat(`${waste.accumulationLimit}`)
        : true;
    if (cError) {
      errorMsgs.push("Kritinė riba negali būti didesnė nei Kaupimo limitas.");
    }

    const tError =
      isNumber(waste.threshold) && isNumber(waste.criticalLimit)
        ? parseFloat(`${waste.threshold}`) >
          parseFloat(`${waste.criticalLimit}`)
        : true;
    if (tError) {
      errorMsgs.push("Įspėjamoji riba negali būti didesnė už Kritinė riba.");
    }

    const tAError =
      isNumber(waste.threshold) && isNumber(waste.accumulationLimit)
        ? parseFloat(`${waste.threshold}`) >
          parseFloat(`${waste.accumulationLimit}`)
        : true;
    if (tAError) {
      errorMsgs.push("Įspėjamoji riba negali būti didesnė už Kaupimo limitas.");
    }

    const fError =
      isNumber(waste.freeLimit) && isNumber(waste.accumulationLimit)
        ? parseFloat(`${waste.freeLimit}`) >
          parseFloat(`${waste.accumulationLimit}`)
        : isNumber(waste.freeLimit)
        ? true
        : false;
    if (fError) {
      errorMsgs.push(
        "Nemokamas metinis limitas negali būti didesnis už Kaupimo limitą."
      );
    }

    const priceError =
      isNumber(waste.price) && isNumber(waste.priceVat)
        ? parseFloat(`${waste.price}`) > parseFloat(`${waste.priceVat}`)
        : true;
    if (priceError) {
      errorMsgs.push(
        "Kaina privaloma. Kaina negali būti didesnė, nei Kaina su PVM."
      );
    }

    if (!waste.sites?.length) {
      errorMsgs.push(
        "Nauja atlieka privalo būti priskirta bent vienai aikštelei."
      );
    }

    if (!waste.code || !waste.waste) {
      errorMsgs.push(
        "Atliekos pavadinimas ar atliekos kodas privalomi laukai."
      );
    }

    if (limited && !waste.freeLimit) {
      errorMsgs.push("Pridėti nemokamai priimamų atliekų limitą.");
    }

    if (isUnit(waste.uom) && !isNumber(waste.kgPerUnit)) {
      errorMsgs.push("Prašome nurodyti vieneto (vnt.) svorį kilogramais (kg).");
    }

    if (grouped && !waste.groupId) {
      errorMsgs.push("Priskirti grupę.");
    }

    return errorMsgs;
  };

  const isComplete = () => {
    // code;
    // waste;
    // price;
    // priceVat;
    // compInvoice: ***;
    // criticalLimit !!!;
    // accumulationLimit: !!!;
    // threshold: !!!;
    // freeLimit: !!!;
    // maxLimit: !!!;
    // sites: len >= 1;
    if (getErrorMsgs().length) {
      return false;
    } else {
      const exclude: (keyof defaultWaste)[] = [
        "accumulationLimit",
        "criticalLimit",
        "freeLimit",
        "maxLimit",
        "price",
        "priceVat",
        "sites",
        "threshold",
        "compInvoice",
        "uom",
        "kgPerUnit",
        "groupId",
        "direct_vat_calc",
      ];

      if (isUnit(waste.uom) && !isNumber(waste.kgPerUnit)) {
        console.log("kg per unit error here");
      } else if (limited && !waste.freeLimit) {
        console.log(!waste.freeLimit, limited, "freeLimit error here");
      } else if (grouped && !waste.groupId) {
        console.log(!waste.groupId, grouped, "Group id error here");
      } else if (!waste.sites?.length) {
        console.log(!waste.sites, "SITE LENGTH error here");
      }

      return !(
        !waste.sites?.length ||
        (limited && !waste.freeLimit) ||
        (isUnit(waste.uom) && !isNumber(waste.kgPerUnit)) ||
        (grouped && !waste.groupId) ||
        Object.keys(waste).some((k) => {
          const key = k as keyof defaultWaste;
          if (exclude.indexOf(key) < 0 && !waste[key]) {
            console.log(key, waste[key], " error here");
          }
          return exclude.indexOf(key) < 0 && !waste[key];
        })
      );
    }
  };

  const selectedGroupOption = (): option => {
    const option = groupOptions.find(
      (option) => option.value === waste.groupId
    );

    return option || defaultOption;
  };

  const resetWaste = () => {
    setWaste({ ...wasteDefault });
    setLimited(false);
    setGrouped(false);
    setHasError(false);
  };

  useEffect(() => {
    if (!searchParams.code) return;

    getFilterWastes(searchParams).then(
      ({ wastes }) => {
        setWastes(wastes);
      },
      (error) => {
        setWastes([]);
      }
    );
  }, [searchParams]);

  const distBottom = ["10rem", 1, 1, 1, 1, 1];

  const pageConstruct: pageConstructType = {
    sizeDist: dist,
    typeDist: [
      textField(
        "",
        false,
        () => {},
        waste.code || "",
        (code) => {
          setWaste({ ...waste, code });
          setSearchParams({ code });
        }
      ),
      textField(
        "",
        false,
        () => {},
        waste.waste || "",
        (wasteName) => {
          setWaste({ ...waste, waste: wasteName });
        }
      ),
      numberField(
        "",
        false,
        () => {},
        `${waste.price || ""}`,
        (price) => {
          setWaste({ ...waste, price: price || undefined });
        }
      ),
      numberField(
        "",
        false,
        () => {},
        `${waste.priceVat || ""}`,
        (priceVat) => {
          setWaste({ ...waste, priceVat: priceVat || undefined });
        }
      ),
      childField(
        <button
          className={`wasteCreate_freeLimitCheck center ${
            grouped ? "limited" : ""
          }`}
          key={`grouped__sitewaste`}
          onClick={() => {
            setGrouped(!grouped);
            if (!grouped) {
              setWaste({ ...waste, groupId: undefined });
            }
          }}
        >
          {grouped ? "Sumuojamas kartu su..." : "Nesumuojamas"}
        </button>
      ),
      !grouped
        ? childField(
            <div
              key={"group_waste_input"}
              className="waste_create_freeLimit_input_wrapper"
            >
              <Input
                key={`waste_freelimit`}
                value={""}
                placeholder={""}
                handler={() => {}}
                error={false}
                type={"number"}
                disabled={true}
                externalValue={""}
                setExternalValue={() => {}}
              />
            </div>
          )
        : dropField(
            groupOptions,
            "",
            false,
            selectedGroupOption(),
            (groupId) => {
              setWaste({ ...waste, groupId });
            }
          ),
      childField(
        <div
          className={`accpt_waste_removal_btn_wrapper center wasteCreateCheck`}
          key={`invoice__sitewaste`}
        >
          <CheckBox
            isChecked={!!waste.compInvoice}
            onChange={(e) => {
              setWaste({ ...waste, compInvoice: !waste.compInvoice });
            }}
          />
        </div>
      ),
      childField(
        wastes.length ? (
          <div
            key={"waste_filter_list"}
            style={{
              gridColumn: `1/${dist.length + 1}`,
              position: "static",
            }}
            className="waste_person_filter"
          >
            <div
              style={{ padding: 0, marginTop: 8 }}
              className="waste_person_filter_container"
            >
              <div className="inner">
                <div
                  onClick={() => {
                    setSearchParams({});
                    setWastes([]);
                  }}
                  className="close_person_filter center"
                >
                  <CloseIcon className="img_div_contain" />
                </div>
                {wastes.map((item, n) => (
                  <div
                    className="filter_item"
                    key={n}
                    onClick={() => {
                      setSearchParams({});
                      setWastes([]);

                      let sites: number[] = [];
                      item.SiteWastes.forEach((site) => {
                        sites.push(site.siteId);
                      });

                      if (item.groupId) {
                        setGrouped(true);
                      }

                      if (item.SiteWastes[0]?.freeLimit) {
                        setLimited(true);
                      }

                      setWaste({
                        ...waste,
                        ...{
                          id: item.id,
                          code: item.code,
                          waste: item.waste,
                          groupId: item.groupId,
                          price: item.SiteWastes[0]?.price,
                          priceVat: item.SiteWastes[0]?.priceVat,
                          compInvoice: item.SiteWastes[0]?.compInvoice,
                          criticalLimit: item.SiteWastes[0]?.criticalLimit,
                          accumulationLimit:
                            item.SiteWastes[0]?.accumulationLimit,
                          threshold: item.SiteWastes[0]?.threshold,
                          freeLimit: item.SiteWastes[0]?.freeLimit,
                          maxLimit: item.SiteWastes[0]?.maxLimit,
                          kgPerUnit: item.kgPerUnit,
                          uom: item.uom,
                          direct_vat_calc: item.SiteWastes[0]?.direct_vat_calc,
                          sites: sites,
                          existingSites: sites,
                        },
                      });
                    }}
                  >
                    {item.code} {item.waste}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )
      ),
    ],
    hasHeader: true,
    headerText: [
      Header(["Kodas"], false),
      Header(["Atlieka"], false),
      Header(["Įkainis be PVM"], false),
      Header(["Įkainis su PVM"], false),
      Header(["Sumavimas"], false),
      Header(["Atliekų grupė"], false),
      Header(["Privaloma sąskaita"], false),
    ],
  };

  const limitConstruct: pageConstructType = {
    sizeDist: distBottom,
    typeDist: [
      childField(
        <button
          className={`wasteCreate_freeLimitCheck center ${
            limited ? "limited" : ""
          }`}
          key={`invoice__sitewaste`}
          onClick={() => {
            setLimited(!limited);
            if (limited) {
              setWaste({ ...waste, freeLimit: undefined });
            }
          }}
        >
          {limited ? "Ribojamas" : "Neribojamas"}
        </button>
      ),
      childField(
        <div
          key={"freeimit_waste_input"}
          className="waste_create_freeLimit_input_wrapper"
        >
          <Input
            key={`waste_freelimit`}
            value={`${waste.freeLimit || ""}`}
            placeholder={""}
            handler={() => {}}
            error={false}
            type={"number"}
            disabled={!limited}
            externalValue={`${waste.freeLimit || ""}`}
            setExternalValue={(freeLimit) => {
              setWaste({ ...waste, freeLimit: freeLimit || undefined });
            }}
          />
        </div>
      ),
      numberField(
        "",
        false,
        () => {},
        `${waste.maxLimit || ""}`,
        (maxLimit) => {
          setWaste({ ...waste, maxLimit: maxLimit || undefined });
        }
      ),
      numberField(
        "",
        false,
        () => {},
        `${waste.accumulationLimit || ""}`,
        (accumulationLimit) => {
          setWaste({
            ...waste,
            accumulationLimit: accumulationLimit || undefined,
          });
        }
      ),
      numberField(
        "",
        false,
        () => {},
        `${waste.threshold || ""}`,
        (threshold) => {
          setWaste({ ...waste, threshold: threshold || undefined });
        }
      ),
      numberField(
        "",
        false,
        () => {},
        `${waste.criticalLimit || ""}`,
        (criticalLimit) => {
          setWaste({
            ...waste,
            criticalLimit: criticalLimit || undefined,
          });
        }
      ),
    ],
    hasHeader: true,
    headerText: [
      Header(["Nemokamas metinis limitas"], false),
      Header(["Nemokamas metinis limitas"], false),
      Header(["Didžiausias vienu metu galimas pristatyti kiekis"], false),
      Header(["Kaupimo limitas"], false),
      Header(["Įspėjamoji riba"], false),
      Header(["Kritinė riba"], false),
    ],
  };

  const directVATConstruct: pageConstructType = {
    sizeDist: [1],
    typeDist: [
      childField(
        <div
          key={"checkboxkgperunit"}
          style={{ justifyContent: "flex-start" }}
          className="unit_waste_creation_wrapper"
        >
          <div
            className={`accpt_waste_removal_btn_wrapper center wasteCreateCheck`}
            key={`invoice__sitewaste`}
          >
            <CheckBox
              isChecked={!!waste.direct_vat_calc}
              onChange={(e) => {
                setWaste({
                  ...waste,
                  direct_vat_calc: !waste.direct_vat_calc,
                });
              }}
            />
          </div>
          <div>Mokėtinos sumos skaičiavimams naudoti kainą su PVM</div>
        </div>
      ),
    ],
    hasHeader: false,
    headerText: [],
  };

  const limitConstruct2: pageConstructType = {
    sizeDist: distBottom,
    typeDist: [
      childField(
        <div key={"checkboxkgperunit"} className="unit_waste_creation_wrapper">
          <div
            className={`accpt_waste_removal_btn_wrapper center wasteCreateCheck`}
            key={`invoice__sitewaste`}
          >
            <CheckBox
              isChecked={!!isUnit(waste.uom)}
              onChange={(e) => {
                setWaste({
                  ...waste,
                  uom: isUnit(waste.uom) ? undefined : "unit",
                  ...(!isUnit(waste.uom) ? { kgPerUnit: undefined } : {}),
                });
              }}
            />
          </div>
          <div>Priėmimas vienetais</div>
        </div>
      ),
      childField(
        isUnit(waste.uom) ? (
          <div key={"kgperunit_wrapper"} className="waste_unit_input_wrapper">
            <Input
              key={`kgperunit`}
              value={""}
              placeholder={"Vieneto (vnt.) svoris kilogramais (kg)"}
              handler={() => {}}
              error={false}
              type={"number"}
              externalValue={`${waste.kgPerUnit || ""}`}
              setExternalValue={(kgPerUnit) => {
                setWaste({
                  ...waste,
                  kgPerUnit: (kgPerUnit as unknown as number) || undefined,
                });
              }}
            />
          </div>
        ) : (
          <div key={"kgperunit_wrapper"}></div>
        )
      ),
    ],
    hasHeader: false,
    headerText: [],
  };

  const siteSelectConstruct = {
    sizeDist: [1],
    typeDist: [
      childField(
        <div key={"site_select_waste"} className="waste_site_select_wrapper">
          {sites.map((site, n) => {
            const selected = waste.sites?.find((siteId) => siteId === site.id);
            const disabled = waste.existingSites?.find(
              (siteId) => siteId === site.id
            );
            return (
              <button
                onClick={() => addRemoveSite(site.id)}
                key={n}
                className={`waste_site_select_item ${
                  selected ? "selected" : ""
                }`}
                disabled={!!disabled}
              >
                {site.name}
              </button>
            );
          })}
        </div>
      ),
    ],
    hasHeader: false,
    headerText: [],
  };

  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const createOrUpdate = async () => {
    setCreating(true);
    reset();

    let url = "/wastes";
    let method = "POST";

    if (waste.id) {
      url += "/" + waste.id;
      method = "PUT";
    }

    const perUnitObj = {
      kgPerUnit:
        isUnit(waste.uom) && isNumber(waste.kgPerUnit)
          ? waste.kgPerUnit
          : undefined,
    };

    await Fetch(url, {
      method: method,
      body: JSON.stringify({
        waste: { ...waste, ...perUnitObj },
        checkedSites: waste.sites || [],
      }),
    })
      .then(() => {
        if (mountedRef.current) {
          setCreating(false);
          setCreateError(false);
          setCreateSuccess(true);
          resetWaste();
          handleOpen();
        }
      })
      .catch((err) => {
        if (mountedRef.current) {
          setCreating(false);
          setCreateError(true);
          setCreateSuccess(false);
        }
      });
  };

  return (
    <div onClick={handleOpen} className="waste_create_wrapper">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="waste_create_container"
      >
        <div className="waste_create_container_header">
          <p className="wasteCreate_para">Nauja atlieka</p>
          <div className="popUpIcon_div center" onClick={handleOpen}>
            <CloseIcon />
          </div>
        </div>
        <div className="acceptanceForm_wrapper accpt_person">
          <GenerateForm classNameWrap="waste_create_det_wrapper">
            {[
              <FormGenerator
                key={`waste_creation_key`}
                gridSizeDist={pageConstruct.sizeDist}
                gridTypeDist={pageConstruct.typeDist}
                hasHeader={pageConstruct.hasHeader}
                headerText={pageConstruct.headerText}
              />,
              <FormGenerator
                key={`waste_creation_key_unit`}
                gridSizeDist={directVATConstruct.sizeDist}
                gridTypeDist={directVATConstruct.typeDist}
                hasHeader={directVATConstruct.hasHeader}
                headerText={directVATConstruct.headerText}
              />,
            ]}
          </GenerateForm>
        </div>
        <p className="wasteCreate_para">Limitai</p>
        <GenerateForm classNameWrap="waste_create_det_wrapper">
          {[
            <FormGenerator
              key={`waste_creation_key_limit`}
              gridSizeDist={limitConstruct.sizeDist}
              gridTypeDist={limitConstruct.typeDist}
              hasHeader={limitConstruct.hasHeader}
              headerText={limitConstruct.headerText}
            />,
            <FormGenerator
              key={`waste_creation_key_unit`}
              gridSizeDist={limitConstruct2.sizeDist}
              gridTypeDist={limitConstruct2.typeDist}
              hasHeader={limitConstruct2.hasHeader}
              headerText={limitConstruct2.headerText}
            />,
          ]}
        </GenerateForm>
        <p className="wasteCreate_para">Aikštelės</p>
        <GenerateForm classNameWrap="waste_create_det_wrapper">
          {[
            <FormGenerator
              key={`waste_creation_key_sites`}
              gridSizeDist={siteSelectConstruct.sizeDist}
              gridTypeDist={siteSelectConstruct.typeDist}
              hasHeader={siteSelectConstruct.hasHeader}
              headerText={siteSelectConstruct.headerText}
            />,
          ]}
        </GenerateForm>
        <ul>
          {hasError &&
            getErrorMsgs().map((text, n) => (
              <li key={n} className="waste_create_error">
                {text}
              </li>
            ))}
        </ul>

        <div className="setting_create_button_wrapper flex">
          <button
            onClick={() => {
              setWaste({ ...wasteDefault });
              handleOpen();
            }}
            className="flexBtn btn-default"
          >
            Atšaukti
          </button>

          <button
            disabled={creating}
            onClick={() => {
              if (creating) {
                return;
              }
              const error = !!getErrorMsgs().length || !isComplete();
              setHasError(error);
              if (!error) {
                createOrUpdate();
              }
            }}
            className="flexBtn btn-green"
          >
            Išsaugoti
          </button>
        </div>
      </div>
    </div>
  );
};

export default Waste;
