import { ofType } from "redux-observable";
import { mergeMap, catchError, map } from "rxjs/operators";
import { of } from "rxjs";

import * as actions from "../actionTypes";
import { store } from "../..";
import {
  getMaterials as get_materials,
  getInactiveMaterials as get_inactive_materials,
} from "../actions";
import { FetchRX } from "../../helpers/misc";

export const getMaterials = (action$) =>
  action$.pipe(
    ofType(actions.GET_MATERIALS),
    mergeMap(() =>
      FetchRX("/materials").pipe(
        map((result) => {
          return {
            type: actions.SET_MATERIALS,
            payload: result.materials,
          };
        }),
        catchError((error) => {
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const getInactiveMaterials = (action$) =>
  action$.pipe(
    ofType(actions.GET_INACTIVE_MATERIALS),
    mergeMap(() =>
      FetchRX("/materials/inactive").pipe(
        map((result) => {
          return {
            type: actions.SET_INACTIVE_MATERIALS,
            payload: result.materials,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const deactivateMaterial = (action$) =>
  action$.pipe(
    ofType(actions.DEACTIVATE_MATERIAL),
    mergeMap((action) =>
      FetchRX("/materials/deactivate", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_materials());
          store.dispatch(get_inactive_materials());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const hideMaterial = (action$) =>
  action$.pipe(
    ofType(actions.HIDE_MATERIAL),
    mergeMap((action) =>
      FetchRX("/materials/hide", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_materials());
          store.dispatch(get_inactive_materials());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const restoreMaterial = (action$) =>
  action$.pipe(
    ofType(actions.RESTORE_MATERIAL),
    mergeMap((action) =>
      FetchRX("/materials/restore", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map(() => {
          store.dispatch(get_materials());
          store.dispatch(get_inactive_materials());
          return { type: actions.EMPTY };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const createMaterial = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_MATERIAL),
    mergeMap((action) =>
      FetchRX("/materials/create", {
        method: "POST",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_MATERIALS,
            payload: result.materials,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );

export const editMaterial = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_MATERIAL),
    mergeMap((action) =>
      FetchRX("/materials/edit", {
        method: "PUT",
        body: action.payload,
      }).pipe(
        map((result) => {
          return {
            type: actions.SET_MATERIALS,
            payload: result.materials,
          };
        }),
        catchError((error) => {
          console.log(error?.error);
          return of({ type: actions.EMPTY });
        })
      )
    )
  );
