import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";

import "./style.scss";
import * as statuses from "../../../../statuses";
import "./WasteManagementForm.css";
import FormGenerator, {
  GenerateForm,
  Input,
} from "../../../../components/UI/FormGenerator/FormGenerator";
import {
  option,
  pageConstructType,
} from "../../../../components/UI/FormGenerator/formTypes";
//SVG Import
import { ReactComponent as PlusIcon } from "../../../../assets/images/plus.svg";
import { ReactComponent as DelIcon } from "../../../../assets/images/del.svg";
import {
  childField,
  dateField,
  dropField,
  Header,
  numberField,
} from "../../../../components/UI/FormGenerator/formField";
import IconContainer from "../../../../components/UI/iconContainer/iconContainer";
import { site } from "../../../../@types/sites";
import {
  formationType,
  materialType,
  primarySource,
  wasteActivityTypeType,
  wasteType,
} from "../../../../@types/waste";
import { defaultOption } from "../../../../helpers/misc";

type wasteActivity = {
  date: string | Date;
  wasteActivityTypeId: number;
  wasteId: number;
  totalWeight: number;
  primarySourceId: number;
  wasteResult?: number;
  wasteResultWeight?: number;
  materialResult?: number;
  materialResultWeight?: number;
  materialUsed?: number;
  materialUsedWeight?: number;
};

const defaultFormError: { [key in keyof wasteActivity]: boolean } & {
  extraProp: boolean;
} = {
  date: false,
  wasteActivityTypeId: false,
  wasteId: false,
  totalWeight: false,
  primarySourceId: false,
  extraProp: false,
};

export const defaultDropDownStatus = {
  siteId: false,
  wasteId: false,
};

const WasteManagementForm = function ({
  makeShadow,
  sites,
  wastes,
  WasteFormData,
  getWastes,
  saveWasteActivity,
  wasteActivitiesSavingStatus,
  resetWasteActivityStatus,
  handleOpen,
  wasteActivityTypes,
  primarySources,
  formations,
  materials,
}: {
  makeShadow: (show: boolean) => void;
  sites: site[];
  wastes: wasteType[];
  WasteFormData: {
    value: number;
    totalWeight: number;
  };
  getWastes: () => void;
  saveWasteActivity: (formData: wasteActivity) => void;
  wasteActivitiesSavingStatus: number;
  resetWasteActivityStatus: () => void;
  handleOpen: (isShown: boolean) => void;
  wasteActivityTypes: wasteActivityTypeType[];
  primarySources: primarySource[];
  formations: formationType[];
  materials: materialType[];
}) {
  const defaultObj: Partial<wasteActivity> = {
    wasteId: WasteFormData.value,
    date: moment().format("YYYY-MM-DD"),
    totalWeight: WasteFormData.totalWeight,
  };

  const unitArray: ("Tonos" | "Vienetai")[] = ["Tonos", "Vienetai"];
  const [formData, setFormData] = useState<Partial<wasteActivity>>({
    ...defaultObj,
  });
  const [wasteActivityTypesOptions, setWasteActivityTypesOptions] = useState<
    option[]
  >([]);
  const [formError, setFormError] = useState({ ...defaultFormError });
  const [wasteOptions, setWasteOptions] = useState<option[]>([]);
  const [primarySourceOptions, setPrimarySourceOptions] = useState<option[]>(
    []
  );
  const [formationOptions, setFormationOptions] = useState<option[]>([]);
  const [materialOptions, setMaterialOptions] = useState<option[]>([]);
  type extraObj = {
    unitIndex: number;
    headerIndex: number;
    selectedItem?: any;
  };
  const [extraPopUpInfo, setExtraPopUpInfo] = useState<extraObj[]>([]); // Row index maps to pageButtons, index of items maps to unitArray

  useEffect(() => {
    document.addEventListener("keyup", closeForm, false);
    resetWasteActivityStatus();

    return () => {
      document.removeEventListener("keyup", closeForm, false);
    };
  }, []);

  useEffect(() => {
    getWastes();
  }, []);

  useEffect(() => {
    if (wasteActivityTypes) {
      const option = wasteActivityTypes.map((item) => {
        return { label: item.name, value: item.id };
      });

      setWasteActivityTypesOptions([...option]);
    }
  }, [wasteActivityTypes]);

  useEffect(() => {
    if (wastes && wastes.length > 0) {
      const options = wastes.map((waste) => {
        return {
          label: `${waste.code} ${waste.waste}`,
          value: waste.id,
        };
      });

      setWasteOptions(options);
    }
  }, [wastes]);

  useEffect(() => {
    if (primarySources && primarySources.length > 0) {
      const options = primarySources.map((ps) => {
        return {
          label: ps.name,
          value: ps.id,
        };
      });

      setPrimarySourceOptions(options);
    }
  }, [primarySources]);

  useEffect(() => {
    if (formations && formations.length > 0) {
      const options = formations.map((formation) => {
        return {
          label: formation.name,
          value: formation.id as number,
        };
      });

      setFormationOptions(options);
    }
  }, [formations]);

  useEffect(() => {
    if (materials && materials.length > 0) {
      const options = materials.map((material) => {
        return {
          label: material.name,
          value: material.id as number,
        };
      });

      setMaterialOptions(options);
    }
  }, [materials]);

  useEffect(() => {
    if (
      wasteActivitiesSavingStatus &&
      wasteActivitiesSavingStatus === statuses.STATUS_DONE
    ) {
      setTimeout(() => {
        resetForm();
        handleOpen(false);
        // set the status back here
        resetWasteActivityStatus();
      }, 1000);
    }
  }, [wasteActivitiesSavingStatus]);

  const closeForm = useCallback((event: KeyboardEvent) => {
    // Event keycode 27 --> Escape key pressed
    if (event.keyCode === 27) {
      resetForm();
      handleOpen(false);
    }
  }, []);

  const updateFormData = (objData: Partial<wasteActivity>) => {
    const _formError = { ...formError };
    for (const k in objData) {
      const key = k as keyof Partial<wasteActivity>;
      _formError[key as keyof typeof _formError] = false;
      if (
        [
          "wasteResult",
          "wasteResultWeight",
          "materialResult",
          "materialResultWeight",
          "materialUsed",
          "materialWeight",
        ].indexOf(key as pageButtonsType) >= 0
      ) {
        _formError.extraProp = false;
      }
    }
    setFormData({ ...formData, ...objData });
    setFormError(_formError);
  };

  const waste_value = useCallback(
    (id?: number): option => {
      if (id) {
        const waste = wasteOptions.find((munic) => munic.value === id);
        if (waste) {
          return waste;
        }
      }
      return defaultOption;
    },
    [formData.wasteId, wasteOptions]
  );
  const errorDetails = () => {
    const keys: (keyof wasteActivity)[] = [
      "date",
      "wasteActivityTypeId",
      "wasteId",
      "totalWeight",
      "primarySourceId",
    ];

    const exclude: {
      [key in "materialResult" | "materialUsed" | "wasteResult"]?:
        | "materialResultWeight"
        | "materialUsedWeight"
        | "wasteResultWeight";
    }[] = [
      { materialResult: "materialResultWeight" },
      { materialUsed: "materialUsedWeight" },
      { wasteResult: "wasteResultWeight" },
    ];

    let error: Partial<typeof formError> = {};

    keys.forEach((key) => {
      const value = formData[key];
      if (!value) {
        error = { ...error, [key]: true };
      }
    });

    let extraPropError = true;

    exclude.forEach((obj) => {
      const key = Object.keys(obj)[0] as
        | "materialResult"
        | "materialUsed"
        | "wasteResult";
      const value = obj[key]!;

      if (formData[key]) {
        extraPropError = false;
        if (!parseFloat(`${formData[value]}`)) {
          error = { ...error, [value]: true };
        }
      }
    });

    error = { ...error, extraProp: extraPropError };

    const hasError = Object.keys(error).some((k) => {
      const key = k as keyof wasteActivity;
      return !!error[key];
    });

    return { hasError, error };
  };

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault();
    const { error, hasError } = errorDetails();

    setFormError({ ...formError, ...error });

    if (hasError) {
      return;
    } else {
      saveWasteActivity(formData as wasteActivity);
    }
  };

  const resetForm = () => {
    setFormData({ ...defaultObj });
    setFormError({ ...defaultFormError });
  };

  const pageConstruct: pageConstructType[] = [
    {
      sizeDist: [1, 3],
      typeDist: [
        dateField("Nuo...", (date) => updateFormData({ date }), formError.date),
        dropField(
          wasteActivityTypesOptions,
          "Ieškoti...",
          formError.wasteActivityTypeId,
          defaultOption,
          (value) => updateFormData({ wasteActivityTypeId: value })
        ),
      ],
      hasHeader: true,
      headerText: [
        Header(["Sutvarkymo data"], formError.date),
        Header(["Atliekų tvarkymo veikla"], formError.wasteActivityTypeId),
      ],
    },
    {
      sizeDist: [1],
      typeDist: [
        dropField(
          wasteOptions,
          "Ieškoti...",
          formError.wasteId,
          waste_value(formData.wasteId),
          (value) => updateFormData({ wasteId: value })
        ),
      ],
      hasHeader: true,
      headerText: [Header(["Sutvarkyta atlieka"], formError.wasteId)],
    },
    {
      sizeDist: [1, 1, 1],
      typeDist: [
        numberField(
          "",
          formError.totalWeight,
          () => {},
          `${formData.totalWeight}`,
          (value) => {
            updateFormData({ totalWeight: value as unknown as number });
          }
        ),
        childField(
          <div
            key={"waste_volume_value"}
            className="wasteAccum_volume_value_wrapper"
          >
            <Input
              value={""}
              placeholder={""}
              handler={() => {}}
              error={false}
              type={"number"}
              disabled={true}
              externalValue={""}
              setExternalValue={() => {}}
            />
          </div>
        ),
        dropField(
          primarySourceOptions,
          "Ieškoti...",
          formError.primarySourceId,
          defaultOption,
          (value) => updateFormData({ primarySourceId: value })
        ),
      ],
      hasHeader: true,
      headerText: [
        Header(["Sutvarkytas kiekis, t"], formError.totalWeight),
        Header(["Sutvarkytas kiekis, vnt."], false),
        Header(["Pirminis atliekų šaltinis"], formError.primarySourceId),
      ],
    },
  ];
  const pageButtons: pageButtonsType[] = [
    "Susidariusi atlieka",
    "Susidariusi medžiaga, daiktas",
    "Naudotos medžiagos, daiktai",
  ];
  type pageButtonsType =
    | "Susidariusi atlieka"
    | "Susidariusi medžiaga, daiktas"
    | "Naudotos medžiagos, daiktai";

  const removeGrid = (gridRowIndex: number) => {
    const infoFiltered = extraPopUpInfo.filter(
      (unitIndex, n) => n !== gridRowIndex
    );

    setExtraPopUpInfo([...infoFiltered]);
  };

  const isSelectedMethod = (headerIndex: number) =>
    extraPopUpInfo.some((extraObj) => extraObj.headerIndex === headerIndex);

  const addGrid = (headerIndex: number) => {
    const exists = isSelectedMethod(headerIndex);

    if (exists) {
      return;
    } else {
      setExtraPopUpInfo([
        {
          headerIndex,
          unitIndex: 0,
          selectedItem: undefined,
        },
        ...extraPopUpInfo,
      ]);
    }
  };

  const weightUnitToggle = (unitIndex: number, gridRowIndex: number): void => {
    const newExtraInfo = extraPopUpInfo.map((info, index) => {
      if (gridRowIndex === index) {
        info.unitIndex = unitIndex;
      }
      return info;
    });

    setExtraPopUpInfo([...newExtraInfo]);
  };

  const detailConstruct = (
    headerText: pageButtonsType,
    rowIndex: number
  ): pageConstructType => ({
    sizeDist: [2.8, 1, `2rem`],
    typeDist: [
      headerText === "Susidariusi atlieka"
        ? dropField(
            // all wastes (resulting perform)
            wasteOptions,
            "Ieškoti...",
            !!formError.wasteResult,
            defaultOption,
            (value) => updateFormData({ wasteResult: value })
          )
        : headerText === "Susidariusi medžiaga, daiktas"
        ? dropField(
            // formed material
            formationOptions,
            "Ieškoti...",
            !!formError.materialResult,
            defaultOption,
            (value) => updateFormData({ materialResult: value })
          )
        : headerText === "Naudotos medžiagos, daiktai"
        ? dropField(
            // material used
            materialOptions,
            "Ieškoti...",
            !!formError.materialUsed,
            defaultOption,
            (value) => updateFormData({ materialUsed: value })
          )
        : childField(<div key={rowIndex + "_empty"}></div>),

      // number field column
      headerText === "Susidariusi atlieka" && formData.wasteResult
        ? numberField(
            "",
            formError.wasteResultWeight,
            () => {},
            `${formData.wasteResultWeight}`,
            (weight) => {
              updateFormData({
                wasteResultWeight: weight as unknown as number,
              });
            }
          )
        : headerText === "Susidariusi medžiaga, daiktas" &&
          formData.materialResult
        ? numberField(
            "",
            formError.materialResultWeight,
            () => {},
            `${formData.materialResultWeight}`,
            (weight) => {
              updateFormData({
                materialResultWeight: weight as unknown as number,
              });
            }
          )
        : headerText === "Naudotos medžiagos, daiktai" && formData.materialUsed
        ? numberField(
            "",
            formError.materialUsedWeight,
            () => {},
            `${formData.materialUsedWeight}`,
            (weight) => {
              updateFormData({
                materialUsedWeight: weight as unknown as number,
              });
            }
          )
        : childField(
            <div
              key={"space_" + rowIndex}
              className="wasteAccum_volume_value_wrapper"
            >
              <Input
                value={""}
                placeholder={""}
                handler={() => {}}
                error={false}
                type={"number"}
                disabled={true}
                externalValue={""}
                setExternalValue={() => {}}
              />
            </div>
          ),
      childField(
        <div className="center wasteManagement_row_btn_wrapper" key={rowIndex}>
          <IconContainer size="lg">
            <DelIcon
              onClick={() => {
                if (headerText === "Susidariusi atlieka") {
                  updateFormData({
                    wasteResultWeight: undefined,
                    wasteResult: undefined,
                  });
                } else if (headerText === "Susidariusi medžiaga, daiktas") {
                  updateFormData({
                    materialResultWeight: undefined,
                    materialResult: undefined,
                  });
                } else if (headerText === "Naudotos medžiagos, daiktai") {
                  updateFormData({
                    materialUsedWeight: undefined,
                    materialUsed: undefined,
                  });
                }
                removeGrid(rowIndex);
              }}
            />
          </IconContainer>
        </div>
      ),
    ],
    hasHeader: true,
    headerText: [
      Header([headerText], false),
      Header(
        [
          <div className="wasteManagement_head_btn">
            {unitArray.map((text, n) => {
              const unitIndex = extraPopUpInfo[rowIndex].unitIndex;
              const Text = unitArray[unitIndex];

              return (
                <div
                  className={`${
                    Text === text ? "selected btn-green" : "btn-default"
                  } ${text === "Vienetai" ? "vol_disabled" : ""}`}
                  key={n}
                >
                  <button
                    onClick={() => {
                      // disable weight-volume toggle
                      // weightUnitToggle(n, rowIndex);
                    }}
                    type="button"
                    className="flex1 ellipsis"
                  >
                    {text}
                  </button>
                </div>
              );
            })}
          </div>,
        ],
        false
      ),
    ],
  });

  const extraConstruct = useMemo(() => {
    return extraPopUpInfo.map((unitIndex, rowIndex) => {
      const title = pageButtons[unitIndex.headerIndex];

      return { ...detailConstruct(title, rowIndex), key: title };
    });
  }, [extraPopUpInfo, formData, formError]);

  return (
    <div
      onScroll={() => {
        const Y: number = document.getElementsByClassName(
          "waste_management_form flex1"
        )[0].scrollTop;
        makeShadow(Y > 2);
      }}
      className="waste_management_form flex1"
    >
      <form autoComplete="off" action="POST" onSubmit={onSubmitHandler}>
        <GenerateForm>
          {pageConstruct.map((construct, n) => (
            <FormGenerator
              key={`wasteManagement_1_${n}`}
              gridSizeDist={construct.sizeDist}
              gridTypeDist={construct.typeDist}
              hasHeader={construct.hasHeader}
              headerText={construct.headerText}
            />
          ))}
        </GenerateForm>
        <div className={`pageButtons ${formError.extraProp ? "hasError" : ""}`}>
          {pageButtons.map((text, n) => (
            <div
              onClick={() => addGrid(n)}
              key={`pgb_key_${n}`}
              className={`pageBtn ${isSelectedMethod(n) ? " selected" : ""}`}
            >
              <button type="button" className="center">
                <IconContainer>
                  <PlusIcon />
                </IconContainer>
              </button>
              <span>{text}</span>
            </div>
          ))}
        </div>

        {extraConstruct.length ? (
          <GenerateForm>
            {extraConstruct.map((construct, n) => (
              <FormGenerator
                key={construct.key}
                gridSizeDist={construct.sizeDist}
                gridTypeDist={construct.typeDist}
                hasHeader={construct.hasHeader}
                headerText={construct.headerText}
              />
            ))}
          </GenerateForm>
        ) : (
          ``
        )}
        <div className="modal_button_container center">
          <button type="button" className="modal_btn center btn-default">
            Atšaukti
          </button>
          <button
            type="submit"
            disabled={!(wasteActivitiesSavingStatus === statuses.STATUS_IDLE)}
            className="modal_btn center btn-green"
          >
            Išsaugoti
          </button>
        </div>
      </form>
    </div>
  );
};

export default WasteManagementForm;
