import React, { useEffect, useState } from "react";
import DropDown from "../../../DropDown/DropDown";
import "./style.scss";
import "./featurePermission.css";
import {
  featureLevels,
  featurePermission,
  userFormData,
} from "../../../../../@types/user";
import { Features } from "../../../../../@types/feature";
import { site, userSite } from "../../../../../@types/sites";
import CheckBox from "../../../../UI/checkBox/checkBox";

type levelObj = {
  [key in keyof Omit<featurePermission, "site">]: {
    title: string;
  };
};
const levels: {
  key: keyof Omit<featurePermission, "site">;
  title: string;
}[] = [
  {
    key: "level1",
    title: "Leidžiama administruoti sistemos nustatymus (I prieigos lygis)",
  },
  { key: "level2", title: "Leidžiama koreguoti duomenis (II prieigos lygis)" },
  { key: "level3", title: "Leidžiama matyti duomenis (III prieigos lygis)" },
];

const FeaturePermission = function ({
  features,
  formData,
  onCancel,
  updateFormData,
  onSave,
}: {
  features: Features[];
  formData: Partial<userFormData>;
  onCancel: () => void;
  updateFormData: (data: {
    [key in keyof Pick<userFormData, "featurePermissions">]: userFormData[key];
  }) => void;
  onSave: () => void;
}) {
  const getOptions = (sitesObj: site[]) => {
    const options = sitesObj.map((site) => ({
      label: site.name,
      value: site.id,
    }));
    return options;
  };
  const getLevels = (Levels: typeof levels) => {
    let _featureCheckboxes: levelObj | {} = {};
    Levels.forEach((level) => {
      const key = level.key;
      (_featureCheckboxes as levelObj)[key] = {
        title: level.title,
      };
    });
    return _featureCheckboxes as levelObj;
  };
  const featureCheckboxes = getLevels(levels);
  const [selectedSite, selectSite] = useState<userSite>(
    getOptions(formData.sites || [])[0] || {
      value: -1,
      label: "",
    }
  );
  const [checkedFeatures, setCheckedFeatures] = useState<featurePermission[]>(
    []
  );

  useEffect(() => {
    setCheckedFeatures(formData.featurePermissions || []);
  }, []);

  const isChecked = (
    level: keyof Omit<featurePermission, "site">,
    featureId: number
  ) => {
    return checkedFeatures.some((cf) => {
      if (cf.site === selectedSite.value) {
        const num: number[] = cf[level];
        if (num.indexOf(featureId) > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  };

  const getIndex = (selectedSite: userSite) => {
    let index: number = -1;

    checkedFeatures.forEach((checked, n) => {
      if (checked.site === selectedSite.value) {
        index = n;
      }
    });
    return index;
  };

  const checkedFeaturesHandler = (
    level: keyof Omit<featurePermission, "site">,
    featureId: number
  ) => {
    if (selectedSite) {
      const _checkedFeatures = [...checkedFeatures];
      // Check whether the selected site has been given features
      const index = getIndex(selectedSite);
      //If there are features, index will be greater than -1
      if (index > -1) {
        const featureIdIndex =
          // Checks if permission has been given to the site for a oarticular level
          // The user might have permissions, but not for that level
          _checkedFeatures[index][level].indexOf(featureId);
        if (featureIdIndex > -1) {
          // Initially had for that particular level
          const levelArray = _checkedFeatures[index][level];
          levelArray.splice(featureIdIndex, 1);
          _checkedFeatures[index][level] = levelArray;
        } else {
          _checkedFeatures[index][level].push(featureId);
        }
      } else {
        //Create a new feature set if not
        const obj: featureLevels = {
          level1: [],
          level2: [],
          level3: [],
        };
        const featurePerm: featurePermission = {
          site: selectedSite.value,
          ...obj,
          [level]: [featureId],
        };
        _checkedFeatures.push(featurePerm);
      }

      setCheckedFeatures(_checkedFeatures);

      updateFormData({ featurePermissions: _checkedFeatures });
    } else {
      return;
    }
  };

  const check = (
    level: keyof Omit<featurePermission, "site">,
    featureId: number
  ) => {
    if (selectedSite && selectedSite.label && selectedSite.value) {
      checkedFeaturesHandler(level, featureId);
    } else {
      return;
    }
  };

  return (
    <div className="feature-permission">
      <div className="profile-summary">
        <div className="profile_info_perm">
          <span>Darbuotojas: </span>
          {formData.uname}
        </div>
        <div className="profile_info_perm">
          <span>Pareigos: </span>
          {formData.position}
        </div>
        <div className="site-select">
          <span>Darbuotojui priskirto objekto valdymas</span>
          <div className="feature_drop_down_container">
            <DropDown
              options={getOptions(formData.sites || [])}
              value={selectedSite}
              onSelect={(site: userSite) => {
                selectSite(site);
              }}
              border={""}
              title={"Ieškoti..."}
              error={false}
            />
          </div>
        </div>
      </div>

      <div className="feature-lists">
        <div className="feature_titles">
          {Object.keys(featureCheckboxes).map((l, n) => {
            const level = l as keyof levelObj;
            return (
              <div key={n} className="feature-group-title">
                {featureCheckboxes[level] && featureCheckboxes[level].title}
              </div>
            );
          })}
        </div>
        <div className="feature_list_options">
          {features &&
            features.map((feature, n) => (
              <div key={n} className="feature_list_option">
                {Object.keys(featureCheckboxes).map((l, nd) => {
                  const level = l as keyof levelObj;
                  return (
                    <label key={`cb-${feature.id}-${nd}`}>
                      <CheckBox
                        readOnly={
                          selectedSite &&
                          selectedSite.label &&
                          selectedSite.value
                            ? false
                            : true
                        }
                        isChecked={isChecked(level, feature.id)}
                        onChange={() => check(level, feature.id)}
                      />
                      <span>{feature.name}</span>
                    </label>
                  );
                })}
              </div>
            ))}
        </div>
        {/* <div className="feature-list">
          <div className="feature-group-title">
            {featureCheckboxes.level3 && featureCheckboxes.level3.title}
          </div>
        </div>

        <div className="feature-list">
          <div className="feature-group-title">
            {featureCheckboxes.level2 && featureCheckboxes.level2.title}
          </div>
          <div className="feature-group-list">
            {features &&
              features.map((feature) => (
                <label key={`cb-${feature.id}`}>
                  <CheckBox
                    readOnly={
                      selectedSite && selectedSite.label && selectedSite.value
                        ? false
                        : true
                    }
                    isChecked={isChecked("level2", feature.id)}
                    onChange={() => check("level2", feature.id)}
                  />
                  <span>{feature.name}</span>
                </label>
              ))}
          </div>
        </div>

        <div className="feature-list">
          <div className="feature-group-title">
            {featureCheckboxes.level1 && featureCheckboxes.level1.title}
          </div>
          <div className="feature-group-list">
            {features &&
              features.map((feature) => (
                <label key={`cb-${feature.id}`}>
                  <CheckBox
                    readOnly={
                      selectedSite && selectedSite.label && selectedSite.value
                        ? false
                        : true
                    }
                    isChecked={isChecked("level1", feature.id)}
                    onChange={() => check("level1", feature.id)}
                  />
                  <span>{feature.name}</span>
                </label>
              ))}
          </div>
        </div> */}
      </div>

      <div className="actions">
        <button type="button" className="btn btn-custom" onClick={onCancel}>
          Atšaukti
        </button>
        <button
          type="button"
          className="btn btn-green"
          onClick={() => {
            onSave();
          }}
        >
          Išsaugoti
        </button>
      </div>
    </div>
  );
};

export default FeaturePermission;
