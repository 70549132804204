import React, { useEffect, useState } from "react";
import "./Declaration.css";
import { pageConstructType } from "../FormGenerator/formTypes";
import { childField, Header } from "../FormGenerator/formField";
import FormGenerator, { GenerateForm } from "../FormGenerator/FormGenerator";
import { wasteItem } from "../../../pages/WasteAcceptance/defaultData";
import {
  companyType,
  documentType,
  personType,
} from "../../../pages/WasteAcceptance/@types";
import { connect } from "react-redux";
import moment from "moment";
import { municipality } from "../../../@types/municipality";
import {
  docNumTransform,
  Fetch,
  isNumber,
  shortName,
  transform,
} from "../../../helpers/misc";
import Signature from "../Signature/Signature";
import { carrier } from "../../../@types/user";
import { site } from "../../../@types/sites";

const DeclarationDoc = ({
  waste_list,
  isCompany,
  date,
  carriers,
  documents,
  hasInvoice,
  next,
  close,
  details,
  setDetails,
  setSubmitConfirmation,
  acceptanceId,
  readOnly,
  imageUrl,
  sites,
}:
  | {
      waste_list: Partial<wasteItem>[];
      isCompany: boolean;
      date: Date;
      carriers: carrier[];
      documents: documentType[];
      hasInvoice?: never;
      next?: never;
      close?: never;
      details: Omit<personType | companyType, "signature">;
      setDetails?: never;
      setSubmitConfirmation?: never;
      acceptanceId: number;
      readOnly: true;
      imageUrl: string;
      sites: site[];
    }
  | {
      waste_list: Partial<wasteItem>[];
      isCompany: boolean;
      date: Date;
      carriers: carrier[];
      documents: documentType[];
      hasInvoice: boolean;
      close: () => void;
      next: () => void;
      details: personType | companyType;
      setDetails: (data: personType | companyType) => void;
      setSubmitConfirmation: (data: boolean) => void;
      acceptanceId?: never;
      readOnly: false;
      imageUrl?: never;
      sites: site[];
    }) => {
  const [allMunicipalities, setAllMunicipalities] = useState<municipality[]>(
    []
  );
  const siteId = waste_list[0]?.siteId;
  const setImageUrl = (dataUrl?: string) => {
    const signature = dataUrl || null;
    if (setDetails) {
      setDetails({ ...details, signature } as typeof details);
    }
  };

  useEffect(() => {
    Fetch("/municipalities/all")
      .then(async (res) => {
        const munics = (await res.json()) as {
          municipalities: municipality[];
        };
        setAllMunicipalities([...munics.municipalities]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const personDetails = details as personType;
  const companyDetails = details as companyType;

  const name = `${personDetails.name || ``} ${personDetails.surname || ``}`;

  const getSite = () => {
    if (siteId) {
      const site = sites.find((site) => site.id === siteId);
      return site;
    } else {
      return undefined;
    }
  };

  const getCarrier = (id?: number): string | undefined => {
    return carriers.find((carrier) => carrier.id === id)?.name;
  };

  const munic_value = (id: number): string => {
    if (id) {
      const municipality = allMunicipalities.find((munic) => munic.id === id);
      if (municipality) {
        return shortName(municipality.name);
      }
    }
    return "";
  };

  const doc_value = (id?: number): string => {
    if (id) {
      const Doc = documents.find((doc) => doc.id === id);
      if (Doc) {
        return Doc.name;
      }
    }
    return "";
  };

  const headClientConstruct: pageConstructType = {
    sizeDist: [1],
    typeDist: [],
    hasHeader: true,
    headerText: [Header(["Duomenys apie atliekų turėtoją"], false)],
  };
  const rowClientConstruct: pageConstructType = {
    sizeDist: [1],
    typeDist: [
      childField(
        <div
          key={`rowWasteConstruct`}
          className="dec_client_details_tab_wrapper"
        >
          <div>
            <div className="cell">
              {isCompany ? `Įmonės pavadinimas` : `Vardas, pavardė`}
            </div>
            <div className="cell value_cell">
              {isCompany ? companyDetails.companyName || `` : name}
            </div>
          </div>
          <div>
            <div className="cell">
              {isCompany ? `Įmonės kodas` : `Asmens dokumento nr.`}
            </div>
            {isCompany ? (
              <div className="cell value_cell">
                {companyDetails.companyCode || ``}
              </div>
            ) : (
              <div className="cell dec_doc_row">
                <div>{doc_value(personDetails.documentId)}</div>
                <div className="value_cell">
                  {personDetails.documentNr || ``}
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="cell">Savivaldybė, kurioje susidarė atliekos</div>
            <div className="cell dec_munic_row">
              <div className="value_cell">
                {munic_value(details.municipalityId as number)}
              </div>
              <div className="munic_cell">
                Savivaldybė, kurioje mokama rinkliava
              </div>
              <div className="value_cell">
                {munic_value(details.municipalityId as number)}
              </div>
            </div>
          </div>
          {isCompany ? (
            <div>
              <div className="cell">Vežėjas</div>
              <div className="cell dec_munic_row">
                <div className="value_cell">
                  {getCarrier(companyDetails.carrier || undefined) || ``}
                </div>
                <div className="munic_cell">Automobilio nr.</div>
                <div className="value_cell">{companyDetails.carNr || ``}</div>
              </div>
            </div>
          ) : (
            <div>
              <div className="cell span_full">
                Elektrėnų sav. - 42, Šalčininkų r. sav. - 85, Širvintų r. sav. -
                89, Švenčionių r. sav. - 86, Trakų r. sav. - 79, Ukmergės r.
                sav. - 81, Vilniaus m. sav. - 13, Vilniaus r. sav. - 41
              </div>
            </div>
          )}
        </div>
      ),
    ],
    hasHeader: false,
    headerText: [],
  };

  const headWasteConstruct: pageConstructType = {
    sizeDist: [1],
    typeDist: [],
    hasHeader: true,
    headerText: [Header(["Priimtos atliekos"], false)],
  };
  const rowWasteConstruct: pageConstructType = {
    sizeDist: [1],
    typeDist: [
      childField(
        <div
          key={`rowWasteConstruct`}
          className="dec_waste_details_tab_wrapper"
        >
          <div>
            <div className="cell">Atliekos kodas</div>
            <div className="cell">Atliekos pavadinimas</div>
            <div className="cell">Priimta mokamai kg/vnt.</div>
            <div className="cell">Priimta nemokamai kg/vnt.</div>
            <div className="cell">Viso: kg/vnt.</div>
          </div>
          {waste_list.map((waste, n) => {
            const isQuantified = waste.quantity && isNumber(waste.kgPerUnit);

            const paidQuantity = isQuantified
              ? transform(
                  Math.ceil((waste.paidQuantity || 0) / waste.kgPerUnit!)
                )
              : transform(waste.paidQuantity || 0);

            const freeQuantity = isQuantified
              ? transform(
                  parseFloat(waste.quantity as string) -
                    Math.ceil((waste.paidQuantity || 0) / waste.kgPerUnit!)
                )
              : transform(
                  parseFloat((waste.weight || 0) as string) -
                    (waste.paidQuantity || 0)
                );

            const totalQuantity = isQuantified
              ? transform(waste.quantity || 0)
              : transform(waste.weight || 0);

            const code = waste.code || "";
            const wasteName = waste.name || "";

            return (
              <div key={n}>
                <div className="cell">{code}</div>
                <div className="cell">{wasteName}</div>
                <div className="cell">{paidQuantity}</div>
                <div className="cell">{freeQuantity}</div>
                <div className="cell">{totalQuantity}</div>
              </div>
            );
          })}
        </div>
      ),
    ],
    hasHeader: false,
    headerText: [],
  };

  return (
    <div className={`flex1 declaration_container fdCol declaration_scroller`}>
      <div className="declaration_popup_container">
        <div className="dec_title_wrapper">
          <div className="dec_title_head">
            Didelių gabaritų atliekų ir/ar žaliųjų atliekų surinkimo aikštelė
          </div>
          <div className="dec_sub">
            <p className="dec_subtitle_head">Aikštelės adresas</p>
            <p className="dec_subtitle_txt">
              {getSite()?.address || "Lvivo 89-75, 08104 Vilnius"}
            </p>
          </div>

          <div className="dec_sub">
            <p className="dec_subtitle_head">Data</p>
            <p className="dec_subtitle_txt">
              {moment(date).format(`YYYY-MM-DD`)}
            </p>
          </div>

          <div className="dec_title_head dec_body_head">
            ATLIEKŲ DEKLARACIJA Nr.{" "}
            {acceptanceId || typeof acceptanceId === "number"
              ? docNumTransform(acceptanceId)
              : `--`}
          </div>
        </div>
        <div className="dec_table_wrap">
          <GenerateForm>
            {[
              <FormGenerator
                key={`dec_table_head_1`}
                gridSizeDist={headClientConstruct.sizeDist}
                gridTypeDist={headClientConstruct.typeDist}
                hasHeader={headClientConstruct.hasHeader}
                headerText={headClientConstruct.headerText}
              />,
            ]}
            {[
              <FormGenerator
                key={`dec_table_row_1`}
                gridSizeDist={rowClientConstruct.sizeDist}
                gridTypeDist={rowClientConstruct.typeDist}
                hasHeader={rowClientConstruct.hasHeader}
                headerText={rowClientConstruct.headerText}
              />,
            ]}
          </GenerateForm>

          <GenerateForm>
            {[
              <FormGenerator
                key={`dec_table_head_2`}
                gridSizeDist={headWasteConstruct.sizeDist}
                gridTypeDist={headWasteConstruct.typeDist}
                hasHeader={headWasteConstruct.hasHeader}
                headerText={headWasteConstruct.headerText}
              />,
            ]}
            {[
              <FormGenerator
                key={`dec_table_row_2`}
                gridSizeDist={rowWasteConstruct.sizeDist}
                gridTypeDist={rowWasteConstruct.typeDist}
                hasHeader={rowWasteConstruct.hasHeader}
                headerText={rowWasteConstruct.headerText}
              />,
            ]}
          </GenerateForm>
        </div>
        <div className="dec_bottom_text">
          Patvirtinu, kad deklaracijoje nurodyti duomenys yra teisingi.
        </div>
        <div className="dec_sign_wrapper">
          <div className="dec_sign_container">
            {readOnly ? (
              <div className="dec_sign_box sig_wrapper">
                <div className="sign_pic_wrapper flex1">
                  {imageUrl ? (
                    <img className="sig_image img_div_contain" src={imageUrl} />
                  ) : (
                    ``
                  )}
                </div>
              </div>
            ) : (
              <Signature
                className="dec_sign_box"
                dataUrl={details.signature || undefined}
                setDataUrl={setImageUrl}
              />
            )}
            <div className="dec_sign_holder">
              {isCompany ? `Atstovo parašas` : `atliekų turėtojo parašas`}
            </div>
          </div>
        </div>
        <div className="dec_footer_wrapper">
          Informuojame Jus, kad ataskaitų pateikimo valstybės institucijoms
          tikslu ir siekiant užtikrinti, kad nebutų viršyti nemokamai primamų
          atliekų kiekiai, mes tvarkysime Jūsu asmens duomenis. Informaciją apie
          tai, kaip bus tvarkomi Jūsu asmens duomenys, rasite informaciniame
          pranešime, kuris skelbiamas atliekų surinkimo aikštelės informaciniame
          stende. Jei kiltų klausimų, galite kreiptis į mus informaciniame
          pranešime nurodytais kontaktais. Daugiau informacijos apie asmens
          duomenų tvarkymą galite rasti mūsų interneto svetainėje adresu{" "}
          <a href="https://www.vaatc.lt" target={"_blank"}>
            www.vaatc.lt
          </a>
        </div>
        {readOnly ? (
          ``
        ) : (
          <div className="dec_button_wrapper">
            <button onClick={close} className=" flexBtn btn-default">
              Atšaukti
            </button>
            <button
              disabled={!details.signature}
              onClick={() => {
                if (hasInvoice) {
                  next();
                } else {
                  if (setSubmitConfirmation) {
                    setSubmitConfirmation(true);
                  }
                }
              }}
              className=" flexBtn btn-green"
            >
              Patvirtinti
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  documents: state.documentReducer.documents,
  origins: state.originReducer.origins,
  carriers: state.carrierReducer.carriers,
});

export default connect(mapStateToProps)(DeclarationDoc);
