export const TEST_EPIC = "TEST_EPIC";
export const IS_LOADING = "IS_LOADING";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_CONFIRM_DIALOG = "SET_CONFIRM_DIALOG";
export const SET_CONFIRM_DIALOG_YES = "SET_CONFIRM_DIALOG_YES";
export const RESET_CONFIRM_DIALOG = "RESET_CONFIRM_DIALOG";

export const GET_NEXT_FORM_NR = "GET_NEXT_FORM_NR";
export const GET_NEXT_FORM_NR_SUCCEED = "GET_NEXT_FORM_NR_SUCCEED";

export const SAVE_PERSON = "SAVE_PERSON";
export const SAVE_PERSON_SUCCEED = "SAVE_PERSON_SUCCEED";
export const SAVE_PERSON_FAILED = "SAVE_PERSON_FAILED";
export const RESET_PERSON_SAVING_STATUS = "RESET_PERSON_SAVING_STATUS";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_FAILED = "GET_COMPANIES_FAILED";
export const SET_COMPANIES = "SET_COMPANIES";
export const SAVE_COMPANY = "SAVE_COMPANY";
export const SAVE_COMPANY_SUCCEED = "SAVE_COMPANY_SUCCEED";
export const SAVE_COMPANY_FAILED = "SAVE_COMPANY_FAILED";
export const RESET_COMPANY_SAVING_STATUS = "RESET_COMPANY_SAVING_STATUS";

export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENTS_FAILED = "GET_DOCUMENTS_FAILED";
export const SET_DOCUMENTS = "SET_DOCUMENTS";

export const GET_REPORT = "GET_REPORT";

export const GET_FEATURES = "GET_FEATURES";
export const GET_FEATURES_FAILED = "GET_FEATURES_FAILED";
export const GET_FEATURES_SUCCEED = "GET_FEATURES_SUCCEED";

export const GET_FORMATIONS = "GET_FORMATIONS";
export const GET_INACTIVE_FORMATIONS = "GET_INACTIVE_FORMATIONS";
export const DEACTIVATE_FORMATION = "DEACTIVATE_FORMATION";
export const RESTORE_FORMATION = "RESTORE_FORMATION";
export const CREATE_FORMATION = "CREATE_FORMATION";
export const EDIT_FORMATION = "EDIT_FORMATION";
export const HIDE_FORMATION = "HIDE_FORMATION";
export const SET_FORMATIONS = "SET_FORMATIONS";
export const SET_INACTIVE_FORMATIONS = "SET_INACTIVE_FORMATIONS";

export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_FAILED = "GET_GROUPS_FAILED";
export const SET_GROUPS = "SET_GROUPS";
export const SET_INACTIVE_GROUPS = "SET_INACTIVE_GROUPS";
export const HIDE_GROUP = "HIDE_GROUP";
export const CREATE_GROUP = "CREATE_GROUP";
export const EDIT_GROUP = "EDIT_GROUP";
export const GET_INACTIVE_GROUPS = "GET_INACTIVE_GROUPS";
export const DEACTIVATE_GROUP = "DEACTIVATE_GROUP";
export const RESTORE_GROUP = "RESTORE_GROUP";

export const GET_MATERIALS = "GET_MATERIALS";
export const GET_INACTIVE_MATERIALS = "GET_INACTIVE_MATERIALS";
export const DEACTIVATE_MATERIAL = "DEACTIVATE_MATERIAL";
export const RESTORE_MATERIAL = "RESTORE_MATERIAL";
export const CREATE_MATERIAL = "CREATE_MATERIAL";
export const EDIT_MATERIAL = "EDIT_MATERIAL";
export const HIDE_MATERIAL = "HIDE_MATERIAL";
export const SET_MATERIALS = "SET_MATERIALS";
export const SET_INACTIVE_MATERIALS = "SET_INACTIVE_MATERIALS";

export const GET_MUNICIPALITIES = "GET_MUNICIPALITIES";
export const HIDE_MUNICIPALITY = "HIDE_MUNICIPALITY";
export const GET_MUNICIPALITIES_FAILED = "GET_MUNICIPALITIES_FAILED";
export const SET_MUNICIPALITIES = "SET_MUNICIPALITIES";
export const CREATE_MUNICIPALITY = "CREATE_MUNICIPALITY";
export const EDIT_MUNICIPALITY = "EDIT_MUNICIPALITY";
export const GET_INACTIVE_MUNICIPALITIES = "GET_INACTIVE_MUNICIPALITIES";
export const SET_INACTIVE_MUNICIPALITIES = "SET_INACTIVE_MUNICIPALITIES";
export const DEACTIVATE_MUNICIPALITY = "DEACTIVATE_MUNICIPALITY";
export const RESTORE_MUNICIPALITY = "RESTORE_MUNICIPALITY";

export const GET_ORIGINS = "GET_ORIGINS";
export const HIDE_ORIGIN = "HIDE_ORIGIN";
export const GET_ORIGINS_FAILED = "GET_ORIGINS_FAILED";
export const SET_ORIGINS = "SET_ORIGINS";
export const CREATE_ORIGIN = "CREATE_ORIGIN";
export const EDIT_ORIGIN = "EDIT_ORIGIN";
export const GET_INACTIVE_ORIGINS = "GET_INACTIVE_ORIGINS";
export const SET_INACTIVE_ORIGINS = "SET_INACTIVE_ORIGINS";
export const DEACTIVATE_ORIGIN = "DEACTIVATE_ORIGIN";
export const RESTORE_ORIGIN = "RESTORE_ORIGIN";

export const GET_ROLES = "GET_ROLES";
export const GET_INACTIVE_ROLES = "GET_INACTIVE_ROLES";
export const DEACTIVATE_ROLE = "DEACTIVATE_ROLE";
export const RESTORE_ROLE = "RESTORE_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const HIDE_ROLE = "HIDE_ROLE";
export const SET_ROLES = "SET_ROLES";
export const SET_INACTIVE_ROLES = "SET_INACTIVE_ROLES";

export const GET_BILLINGS = "GET_BILLINGS";
export const GET_INACTIVE_BILLINGS = "GET_INACTIVE_BILLINGS";
export const DEACTIVATE_BILLING = "DEACTIVATE_BILLING";
export const RESTORE_BILLING = "RESTORE_BILLING";
export const CREATE_BILLING = "CREATE_BILLING";
export const EDIT_BILLING = "EDIT_BILLING";
export const HIDE_BILLING = "HIDE_BILLING";
export const SET_BILLINGS = "SET_BILLINGS";
export const SET_INACTIVE_BILLINGS = "SET_INACTIVE_BILLINGS";

export const GET_CARRIERS = "GET_CARRIERS";
export const GET_INACTIVE_CARRIERS = "GET_INACTIVE_CARRIERS";
export const DEACTIVATE_CARRIER = "DEACTIVATE_CARRIER";
export const RESTORE_CARRIER = "RESTORE_CARRIER";
export const CREATE_CARRIER = "CREATE_CARRIER";
export const EDIT_CARRIER = "EDIT_CARRIER";
export const HIDE_CARRIER = "HIDE_CARRIER";
export const SET_CARRIERS = "SET_CARRIERS";
export const SET_INACTIVE_CARRIERS = "SET_INACTIVE_CARRIERS";

export const GET_WASTEACTIVITYTYPES = "GET_WASTEACTIVITYTYPES";
export const GET_INACTIVE_WASTEACTIVITYTYPES =
  "GET_INACTIVE_WASTEACTIVITYTYPES";
export const DEACTIVATE_WASTEACTIVITYTYPE = "DEACTIVATE_WASTEACTIVITYTYPE";
export const RESTORE_WASTEACTIVITYTYPE = "RESTORE_WASTEACTIVITYTYPE";
export const CREATE_WASTEACTIVITYTYPE = "CREATE_WASTEACTIVITYTYPE";
export const EDIT_WASTEACTIVITYTYPE = "EDIT_WASTEACTIVITYTYPE";
export const HIDE_WASTEACTIVITYTYPE = "HIDE_WASTEACTIVITYTYPE";
export const SET_WASTEACTIVITYTYPES = "SET_WASTEACTIVITYTYPES";
export const SET_INACTIVE_WASTEACTIVITYTYPES =
  "SET_INACTIVE_WASTEACTIVITYTYPES";

export const GET_SITES = "GET_SITES";
export const GET_SITES_FAILED = "GET_SITES_FAILED";
export const SET_SITES = "SET_SITES";
export const CREATE_SITE = "CREATE_SITE";
export const EDIT_SITE = "EDIT_SITE";
export const GET_USER_SITES = "GET_USER_SITES";
export const GET_USER_SITES_FAILED = "GET_USER_SITES_FAILED";
export const SET_USER_SITES = "SET_USER_SITES";
export const GET_INACTIVE_SITES = "GET_INACTIVE_SITES";
export const HIDE_SITE = "HIDE_SITE";
export const SET_INACTIVE_SITES = "SET_INACTIVE_SITES";
export const DEACTIVATE_SITE = "DEACTIVATE_SITE";
export const RESTORE_SITE = "RESTORE_SITE";

export const GET_WASTES = "GET_WASTES";
export const DELETE_WASTE = "DELETE_WASTE";
export const SET_WASTES = "SET_WASTES";

export const GET_JOURNAL_FAILED = "GET_JOURNAL_FAILED";
export const SET_JOURNAL = "SET_JOURNAL";

export const SAVE_WASTE_ACCUMULATION = "SAVE_WASTE_ACCUMULATION";
export const SAVE_WASTE_ACCUMULATION_SUCCEED =
  "SAVE_WASTE_ACCUMULATION_SUCCEED";
export const RESET_WASTE_ACCUMULATION_SAVING_STATUS =
  "RESET_WASTE_ACCUMULATION_SAVING_STATUS";

export const GET_SITE_WASTES = "GET_SITE_WASTES";
export const GET_SITE_WASTES_FAILED = "GET_SITE_WASTES_FAILED";
export const SET_SITE_WASTES = "SET_SITE_WASTES";

export const SET_CURRENT_SITE = "SET_CURRENT_SITE";

export const GET_QUANTITY_DELIVERIES = "GET_QUANTITY_DELIVERIES";
export const GET_QUANTITY_DELIVERIES_FAILED = "GET_QUANTITY_DELIVERIES_FAILED";
export const SET_QUANTITY_DELIVERIES = "SET_QUANTITY_DELIVERIES";
export const RESET_QUANTITY_DELIVERIES = "RESET_QUANTITY_DELIVERIES";
export const SAVE_QUANTITY_DELIVERIES = "SAVE_QUANTITY_DELIVERIES";
export const SAVE_QUANTITY_DELIVERIES_SUCCEED =
  "SAVE_QUANTITY_DELIVERIES_SUCCEED";
export const SAVE_QUANTITY_DELIVERIES_FAILED =
  "SAVE_QUANTITY_DELIVERIES_FAILED";
export const RESET_QUANTITY_DELIVERIES_SAVING_STATUS =
  "RESET_QUANTITY_DELIVERIES_SAVING_STATUS";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCEED = "GET_USER_SUCCEED";
export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_SUCCEED = "SAVE_USER_SUCCEED";
export const DEL_USER = "DEL_USER";
export const DEL_USER_SUCCEED = "DEL_USER_SUCCEED";
export const RESET_USER_STATUS = "RESET_USER_STATUS";
export const SIGNIN_SUCCEED = "SIGNIN_SUCCEED";
export const SIGNOUT = "SIGNOUT";

export const GET_USER_ACTIVITIES = "GET_USER_ACTIVITIES";
export const GET_USER_ACTIVITIES_FAILED = "GET_USER_ACTIVITIES_FAILED";
export const GET_USER_ACTIVITIES_SUCCEED = "GET_USER_ACTIVITIES_SUCCEED";

export const GET_USER_GROUPS = "GET_USER_GROUPS";
export const GET_USER_GROUPS_FAILED = "GET_USER_GROUPS_FAILED";
export const GET_USER_GROUPS_SUCCEED = "GET_USER_GROUPS_SUCCEED";
export const GET_USER_GROUP = "GET_USER_GROUP";
export const GET_USER_GROUP_FAILED = "GET_USER_GROUP_FAILED";
export const GET_USER_GROUP_SUCCEED = "GET_USER_GROUP_SUCCEED";
export const SAVE_USER_GROUP = "SAVE_USER_GROUP";
export const SAVE_USER_GROUP_FAILED = "SAVE_USER_GROUP_FAILED";
export const SAVE_USER_GROUP_SUCCEED = "SAVE_USER_GROUP_SUCCEED";
export const DEL_USER_GROUP = "DEL_USER_GROUP";
export const DEL_USER_GROUP_FAILED = "DEL_USER_GROUP_FAILED";
export const DEL_USER_GROUP_SUCCEED = "DEL_USER_GROUP_SUCCEED";
export const RESET_USER_GROUP_STATUS = "RESET_USER_GROUP_STATUS";

export const GET_PRIMARY_SOURCES = "GET_PRIMARY_SOURCES";
export const GET_PRIMARY_SOURCES_FAILED = "GET_PRIMARY_SOURCES_FAILED";
export const GET_PRIMARY_SOURCES_SUCCEED = "GET_PRIMARY_SOURCES_SUCCEED";

export const SAVE_WASTE_ACTIVITY = "SAVE_WASTE_ACTIVITY";
export const SAVE_WASTE_ACTIVITY_FAILED = "SAVE_WASTE_ACTIVITY_FAILED";
export const SAVE_WASTE_ACTIVITY_SUCCEED = "SAVE_WASTE_ACTIVITY_SUCCEED";
export const DEL_WASTE_ACTIVITY = "DEL_WASTE_ACTIVITY";
export const DEL_WASTE_ACTIVITY_FAILED = "DEL_WASTE_ACTIVITY_FAILED";
export const DEL_WASTE_ACTIVITY_SUCCEED = "DEL_WASTE_ACTIVITY_SUCCEED";
export const RESET_WASTE_ACTIVITY_STATUS = "RESET_WASTE_ACTIVITY_STATUS";

export const CREATE_SERIJA = "CREATE_SERIJA";
export const GET_ALL_SERIJAS = "GET_ALL_SERIJAS";
export const SET_SERIJAS = "SET_SERIJAS";
export const MAKE_SERIJA_ACTIVE = "MAKE_SERIJA_ACTIVE";
export const DELETE_SERIJA = "DELETE_SERIJA";

export const SET_SUGGESTIONS = "SET_SUGGESTION";
export const CREATE_SUGGESTION = "CREATE_SUGGESTION";
export const GET_ALL_SUGGESTIONS = "GET_ALL_SUGGESTIONS";
export const READ_SUGGESTION = "READ_SUGGESTIONS";
export const SET_SUGGESTION_TEXT = "SET_SUGGESTION_TEXT";

export const EMPTY = "EMPTY";
