import * as actionTypes from "../actionTypes";

export const getMunicipalities = function () {
  return { type: actionTypes.GET_MUNICIPALITIES };
};

export const getInactiveMunicipalities = () => ({
  type: actionTypes.GET_INACTIVE_MUNICIPALITIES,
});

export const deactivateMunicipality = (payload) => ({
  type: actionTypes.DEACTIVATE_MUNICIPALITY,
  payload,
});

export const hideMunicipality = (payload) => ({
  type: actionTypes.HIDE_MUNICIPALITY,
  payload,
});

export const restoreMunicipality = (payload) => ({
  type: actionTypes.RESTORE_MUNICIPALITY,
  payload,
});

export const createMunicipality = (payload) => ({
  type: actionTypes.CREATE_MUNICIPALITY,
  payload,
});

export const editMunicipality = (payload) => ({
  type: actionTypes.EDIT_MUNICIPALITY,
  payload,
});
