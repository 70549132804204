import React, { useEffect, useState } from "react";
import WasteManagementForm from "./elements/WasteManagementForm";
import { formatNumber } from "../../helpers";

import "./style.scss";
import NewPopUpFrame from "../../components/UI/PopupFrame/NewPopUpFrame";
import FractionList from "../../components/UI/fractionList/FractionList";
import {
  appendSort,
  enabled,
  isNumber,
  makeLabelInt,
  pageSettingOptions,
} from "../../helpers/misc";
import Sorter from "../../components/elements/Sorter/Sorter";
import { DataExportService } from "../../services";
import {
  childField,
  Header,
  multiDropField,
  numberField,
  textBox,
  textField,
} from "../../components/UI/FormGenerator/formField";
import {
  option,
  pageConstructType,
} from "../../components/UI/FormGenerator/formTypes";
import FilterButtons from "../../components/UI/filterButtons/FilterButtons";
import FormGenerator, {
  GenerateForm,
} from "../../components/UI/FormGenerator/FormGenerator";
import { primarySource } from "../../@types/waste";
import { useAsync } from "../../helpers/asyncFunc";
import { getAllWastes } from "./getter";
import { site } from "../../@types/sites";
import ExcelDownload from "../../components/UI/DownloadPopUp/DownloadPopUp";

const sortOptions = {
  column: "createdAt",
  active: "Naujausi viršuje",
  inactive: "Naujausi apačioje",
  main: "Naujausi viršuje",
};

export type wasteMangementRecordType = {
  id: number;
  groupId: number | null;
  code: string;
  waste: string;
  site: string;
  primarySourceName: string;
  uom: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  kgPerUnit: number | null;
  totalQuantity: number | null;
  totalWeight: number;
  totalVolume: number | null;
};

type wMgtFilter = {
  order: string[];
  code: string;
  waste: string;
  totalWeight: number | "";
  totalQuantity: number | "";
  primarySources: option[];
  sites: option[];
};

const WasteManagement = ({
  primarySources,
  sites,
}: {
  primarySources: primarySource[];
  sites: site[];
}) => {
  const defaultFilter: wMgtFilter = {
    order: ["createdAt DESC"],
    code: "",
    waste: "",
    primarySources: [],
    sites: [],
    totalQuantity: "",
    totalWeight: "",
  };
  const dataExportService = new DataExportService();

  const [filter, setFilter] = useState(defaultFilter);
  const [showShadow, makeShadow] = useState(false);
  const [wasteManagementFormShown, setWasteManagementFormShown] =
    useState(false);
  const [primarySourceOptions, setPrimarySourceOptions] = useState<option[]>(
    []
  );
  const [siteOptions, setSiteOptions] = useState<option[]>([]);

  const [formData, setFormData] = useState<{
    label: string;
    value: number;
    totalQuantity: number;
    totalWeight: number;
  }>();

  const [currentStart, setCurrentPage] = useState(1);
  const [allWastes, setAllWastes] = useState<wasteMangementRecordType[]>([]);
  const [count, setCount] = useState(0);

  const [pageRecordNum, setPageRecordNum] = useState(
    makeLabelInt(pageSettingOptions[1])
  );
  const limit = pageRecordNum.label;
  const offset = currentStart - 1;

  useEffect(() => {
    if (primarySources) {
      const options = primarySources.map((ps) => ({
        label: ps.name,
        value: ps.id,
      }));
      setPrimarySourceOptions([...options]);
    }
  }, [primarySources]);

  useEffect(() => {
    if (sites) {
      const options = sites.map((site) => ({
        label: site.name,
        value: site.id,
      }));
      setSiteOptions([...options]);
    }
  }, [sites]);

  const multiFilterKeys: (keyof wMgtFilter)[] = ["primarySources", "sites"];
  const Filter = (() => {
    return Object.keys(filter)
      .map((Key) => {
        const key = Key as keyof wMgtFilter;
        if (multiFilterKeys.indexOf(key) >= 0) {
          return (
            key + "=" + (filter[key] as option[]).map((i) => i.value).join(",")
          );
        } else {
          return key + "=" + filter[key];
        }
      })
      .join("&");
  })();

  const wMgtRes = useAsync(
    {
      asyncFunc: getAllWastes,
      funcParams: { query: Filter, limit, offset },
      immediate: true,
      clearOnError: true,
    },
    [Filter, limit, offset]
  );

  useEffect(() => {
    const allWastes = wMgtRes.data?.allWastes || [];
    const count = wMgtRes.data?.count || 0;

    setAllWastes([...allWastes]);
    setCount(count);
  }, [wMgtRes.data]);

  const updateFilter = (objFilter: Partial<wMgtFilter>) => {
    setFilter({ ...filter, ...objFilter });
  };

  const dist = [1, 1, 1, 1, 1, 1, `8rem`];

  const textConstruct: pageConstructType[] = (allWastes || []).map(
    (record, n) => ({
      sizeDist: dist,
      typeDist: [
        textBox(record.site, false),
        textBox(record.code, false),
        textBox(record.waste, false),
        textBox(
          `${formatNumber(record.totalWeight ? record.totalWeight / 1000 : 0)}`,
          true
        ),
        textBox(`${formatNumber(record.totalQuantity || 0)}`, true),
        textBox(record.primarySourceName, false),
        childField(
          <div key={n} className="wMgtBtn">
            <button
              className="btn btn-green"
              onClick={() => {
                setFormData({
                  label: `${record.code} ${record.waste}`,
                  value: record.id,
                  totalQuantity: record.totalQuantity || 0,
                  totalWeight: record.totalWeight,
                });
                setWasteManagementFormShown(true);
              }}
            >
              Tvarkyti
            </button>
          </div>
        ),
      ],
      hasHeader: false,
      headerText: [],
    })
  );

  const total = () => {
    let weight = 0;
    let quantity = 0;
    allWastes.forEach((record) => {
      weight += record.totalWeight;
      quantity += record.totalQuantity || 0;
    });

    return { weight, quantity };
  };

  const tailConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [
        childField(<div key={"space1"}></div>),
        childField(<div key={"space2"}></div>),
        childField(
          <div key={"title"} className="bold_tail_wMgt">
            Bendras kiekis
          </div>
        ),
        childField(
          <div key={"weight"} className="bold_tail_wMgt amt">
            {formatNumber(total().weight / 1000)} t
          </div>
        ),
        childField(
          <div key={"quantity"} className="bold_tail_wMgt amt">
            {formatNumber(total().quantity)} vnt.
          </div>
        ),
        childField(<div key={"space3"}></div>),
        childField(<div key={"space4"}></div>),
      ],
      hasHeader: false,
      headerText: [],
    },
  ];

  const pageConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [
        multiDropField(
          siteOptions,
          "Ieškoti...",
          false,
          filter.sites,
          (options) => {
            updateFilter({ sites: options });
          }
        ),
        textField(
          "",
          false,
          () => {},
          filter.code,
          (code) => {
            updateFilter({ code });
          }
        ),
        textField(
          "",
          false,
          () => {},
          filter.waste,
          (waste) => {
            updateFilter({ waste });
          }
        ),
        numberField(
          "0",
          false,
          () => {},
          `${filter.totalWeight}`,
          (value) => {
            updateFilter({
              totalWeight: isNumber(parseFloat(value))
                ? (value as unknown as number)
                : "",
            });
          }
        ),
        numberField(
          "0",
          false,
          () => {},
          `${filter.totalQuantity}`,
          (value) => {
            updateFilter({
              totalQuantity: isNumber(parseFloat(value))
                ? (value as unknown as number)
                : "",
            });
          }
        ),
        multiDropField(
          primarySourceOptions,
          "Ieškoti...",
          false,
          filter.primarySources,
          (options) => {
            updateFilter({ primarySources: options });
          }
        ),
      ],
      hasHeader: true,
      headerText: [
        Header(["Objektas"], false, true),
        Header(["Atliekos kodas"], false, true),
        Header(["Atliekos pavadinimas"], false),
        Header(
          [
            <span
              onClick={() =>
                appendSort(`totalWeight`, true, true, { filter, updateFilter })
              }
              className="linkable"
            >
              Atliekų kiekis, t
            </span>,
          ],
          false
        ),
        Header(["Atliekų kiekis, vnt."], false, true),
        Header(["Pirminis atliekų šaltinis"], false, true),
        Header([""], false, true),
      ],
    },
  ];

  return (
    <div className="waste-management">
      <div>
        <div className="sortFilterHeader">
          <div className="table-action">
            <FilterButtons
              multiFilters={multiFilterKeys}
              filter={filter}
              setFilter={setFilter}
              defaultFilter={defaultFilter}
              exclude={["order", "totalQuantity", "totalWeight"]}
            />
            <div className="sortHolder">
              <Sorter
                valueOptions={sortOptions}
                onClick={() => {
                  const sortValue = sortOptions.column;
                  appendSort(sortValue, true, true, { filter, updateFilter });
                }}
                rotate={!enabled(sortOptions.column, { filter, updateFilter })}
              />
            </div>
            <ExcelDownload type={"wasteManagement"} query={Filter} />
            {/* <ButtonXlsx onClick={() => xlsxExport()} /> */}
          </div>
        </div>
        <div className="viewExt">
          <GenerateForm loading={wMgtRes.loading}>
            {pageConstruct.map((construct, n) => (
              <FormGenerator
                key={`wasteAccumulation_1_${n}`}
                gridSizeDist={construct.sizeDist}
                gridTypeDist={construct.typeDist}
                hasHeader={construct.hasHeader}
                headerText={construct.headerText}
              />
            ))}
            {textConstruct.map((construct, n) => (
              <FormGenerator
                key={`wasteAccumulation_t1_${n}`}
                gridSizeDist={construct.sizeDist}
                gridTypeDist={construct.typeDist}
                hasHeader={construct.hasHeader}
                headerText={construct.headerText}
              />
            ))}
            {tailConstruct.map((construct, n) => (
              <FormGenerator
                key={`wasteAccumulation_t2_${n}`}
                gridSizeDist={construct.sizeDist}
                gridTypeDist={construct.typeDist}
                hasHeader={construct.hasHeader}
                headerText={construct.headerText}
              />
            ))}
          </GenerateForm>
          <FractionList
            count={count}
            currentStart={currentStart}
            setCurrentPage={setCurrentPage}
            pageRecordNum={pageRecordNum}
            setPageRecordNum={setPageRecordNum}
          />
        </div>
      </div>

      {wasteManagementFormShown && formData && (
        <NewPopUpFrame
          full={true}
          title={"TVARKYMAS"}
          showShadow={showShadow}
          noVertPadding
          isShown={wasteManagementFormShown}
          handleOpen={setWasteManagementFormShown}
        >
          <WasteManagementForm
            WasteFormData={formData}
            makeShadow={makeShadow}
            handleOpen={setWasteManagementFormShown}
          />
        </NewPopUpFrame>
      )}
    </div>
  );
};

export default WasteManagement;
