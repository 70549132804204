import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { company, person, personDocuments } from "../../@types/client";
import { Input } from "../../components/UI/FormGenerator/FormGenerator";
import NewPopUpFrame from "../../components/UI/PopupFrame/NewPopUpFrame";
import Limitia from "../Limitia/Limitia";
import ProfileMessageBox from "../ProfileMessage/ProfileMessageBox";
import ProfileRecords from "../ProfileRecords/ProfileRecords";
import { addDeficit, fetchClient } from "./getter";
import "./Profile.css";
import { STATUS_DONE, STATUS_IN_PROGRESS } from "../../statuses";
import Notification from "../../components/UI/Notification/Notification";
import { documentType } from "../WasteAcceptance/@types";
import { useQuery } from "../../App";
import { useAsync } from "../../helpers/asyncFunc";
import IconContainer from "../../components/UI/iconContainer/iconContainer";
import { decPlaces, isNumber } from "../../helpers/misc";
import { formatNumber } from "../../helpers";

type extPerson = person & { PersonDocuments: personDocuments[] };

const Profile = ({
  savePerson,
  savingStatus,
  documents,
}: {
  savePerson: (person: extPerson) => void;
  savingStatus: number;
  documents: documentType[];
}) => {
  const query = useQuery();
  const id = Number(query.get("id")) || null;

  const navigate = useNavigate();
  const parentUrl = "/settings/clients";
  const [formData, setFormData] = useState<extPerson>();
  const [changed, setChanged] = useState<boolean>(false);
  const [debtPopUpVisibility, setDebtPopUpVisibility] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [mailSendSuccess, setMailSendSuccess] = useState(false);
  const [hardReminder, setHardReminder] = useState(false);

  const mountedRef = useRef(true);
  useEffect(() => {
    mountedRef.current = true;
    const isDone = savingStatus === STATUS_DONE;
    const inProgress = savingStatus === STATUS_IN_PROGRESS;
    if (inProgress) {
      setChanged(false);
    } else if (isDone) {
      setChanged(false);

      setEditSuccess(true);
    }
  }, [savingStatus]);

  const resetEditStatus = () => {
    setEditSuccess(false);
  };

  const resetMailStatus = () => {
    setMailSendSuccess(false);
  };

  const profileRes = useAsync({
    asyncFunc: fetchClient,
    funcParams: { id, isCompany: false },
    immediate: true,
    clearOnError: true,
  });

  useEffect(() => {
    const profileData = profileRes.data;

    if (profileData) {
      setFormData(profileData.client as extPerson);
    }
  }, [profileRes.data]);

  useEffect(() => {
    const profileError = profileRes.error;

    if (profileError) {
      navigate(parentUrl);
    }
  }, [profileRes.error]);

  const updateFormData = <T extends Partial<extPerson> = Partial<extPerson>>(
    objData: T
  ) => {
    if (formData) {
      setChanged(true);
      const _formData = { ...formData };

      for (const key in objData) {
        (_formData as T)[key as keyof T] = objData[key];
      }
      setFormData(_formData);
    }
  };

  const updateDoc = (details: { documentId: number; documentNr: string }) => {
    const { documentId, documentNr } = details;

    if (formData) {
      const docs = [...formData.PersonDocuments];
      const doc = docs.find((d) => d.documentId === documentId);

      if (doc) {
        const newDocs = docs.map((d) => {
          if (d.documentId === details.documentId) {
            return { documentId, documentNr };
          } else {
            return d;
          }
        });
        setChanged(true);
        setFormData({ ...formData, PersonDocuments: [...newDocs] });
      } else {
        setChanged(true);
        setFormData({
          ...formData,
          PersonDocuments: [
            ...formData.PersonDocuments,
            { documentId, documentNr },
          ],
        });
      }
    }
  };

  const getDocId = (label: string) => {
    const doc = documents.find(
      (doc) => doc.name.toLocaleLowerCase() === label.toLocaleLowerCase()
    );

    return doc;
  };

  const getPersonDocValue = (label: string): string => {
    const Doc = getDocId(label);
    const doc = formData?.PersonDocuments.find(
      (doc) => doc.documentId === Doc?.id
    );

    return doc?.documentNr || "";
  };

  const docLabels = [
    "Asmens tapatybės kortelė",
    "Pasas",
    "Vairuotojo pažymėjimas",
    "Leidimas laikinai gyventi",
    "Leidimas nuolat gyventi",
  ];

  return formData ? (
    <div className="profile">
      <Notification
        reset={resetEditStatus}
        isDone={editSuccess}
        error={false}
        text="Pakeitimai asmens paskyroje sėkmingai išsaugoti"
      />
      <Notification
        reset={resetMailStatus}
        isDone={mailSendSuccess}
        error={false}
        text="Priminimas sėkmingai išsiųstas"
      />
      {debtPopUpVisibility && (
        <NewPopUpFrame
          showShadow={false}
          handleOpen={setDebtPopUpVisibility}
          isShown={debtPopUpVisibility}
          title={hardReminder ? "Griežtas priminimas" : "Priminimas"}
        >
          <ProfileMessageBox
            onCancel={() => setDebtPopUpVisibility(false)}
            onContinue={() => {
              setDebtPopUpVisibility(false);
              setMailSendSuccess(true);
            }}
            defEmail={formData.email}
            hard={hardReminder}
          />
        </NewPopUpFrame>
      )}
      <>
        <div className="profile_wrapper">
          <div className="profile_header_wrapper">
            <div>
              <h3>Kliento ID {formData.uniqueId || `---`}</h3>
            </div>
            <div>
              <h5>
                Paskyra sukurta{" "}
                {moment(formData.createdAt).format(`YYYY-MM-DD HH:mm`)}
              </h5>
            </div>
          </div>
          <div className="profile_container">
            <form className="profile_form">
              <div className="profile_form_wrapper">
                <div className="profile_form_record">
                  <div className="profile_form_header split">
                    <span>Vardas</span>
                    <span>Pavardė</span>
                  </div>
                  <div className="profile_form_header">{docLabels[0]}</div>
                  <div className="profile_form_container split">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => updateFormData({ name: value })}
                        type={"text"}
                        value={formData.name}
                        placeholder={"Vardas"}
                        error={false}
                      />
                    </div>
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => updateFormData({ surname: value })}
                        type={"text"}
                        value={formData.surname}
                        placeholder={"Pavardė"}
                        error={false}
                      />
                    </div>
                  </div>

                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => {
                          const doc = getDocId(docLabels[0]);

                          if (doc) {
                            updateDoc({
                              documentId: doc.id,
                              documentNr: `${value}`,
                            });
                          }
                        }}
                        type={"number"}
                        value={getPersonDocValue(docLabels[0])}
                        placeholder={"Dokumento Nr."}
                        error={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="profile_form_record">
                  <div className="profile_form_header">Adresas</div>
                  <div className="profile_form_header split">
                    <span>{docLabels[1]}</span>
                    <span>{docLabels[2]}</span>
                  </div>

                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => updateFormData({ address: value })}
                        type={"text"}
                        value={formData.address}
                        placeholder={"Adresas"}
                        error={false}
                      />
                    </div>
                  </div>
                  <div className="profile_form_container split">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => {
                          const doc = getDocId(docLabels[1]);

                          if (doc) {
                            updateDoc({
                              documentId: doc.id,
                              documentNr: `${value}`,
                            });
                          }
                        }}
                        type={"number"}
                        value={getPersonDocValue(docLabels[1])}
                        placeholder={"Dokumento Nr."}
                        error={false}
                      />
                    </div>
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => {
                          const doc = getDocId(docLabels[2]);

                          if (doc) {
                            updateDoc({
                              documentId: doc.id,
                              documentNr: `${value}`,
                            });
                          }
                        }}
                        type={"number"}
                        value={getPersonDocValue(docLabels[2])}
                        placeholder={"Dokumento Nr."}
                        error={false}
                      />
                    </div>
                  </div>
                </div>

                <div className="profile_form_record">
                  <div className="profile_form_header">El. pašto adresas</div>
                  <div className="profile_form_header">{docLabels[3]}</div>
                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(email) => {
                          updateFormData({ email: email });
                        }}
                        type={"email"}
                        value={formData.email || ""}
                        placeholder={"El. p. adresas"}
                        error={false}
                      />
                    </div>
                  </div>

                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => {
                          const doc = getDocId(docLabels[3]);

                          if (doc) {
                            updateDoc({
                              documentId: doc.id,
                              documentNr: `${value}`,
                            });
                          }
                        }}
                        type={"number"}
                        value={getPersonDocValue(docLabels[3])}
                        placeholder={"Dokumento Nr."}
                        error={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="profile_form_record">
                  <div className="profile_form_header">Telefono nr.</div>
                  <div className="profile_form_header">{docLabels[4]}</div>
                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => updateFormData({ mobile: value })}
                        type={"number"}
                        value={formData.mobile || ""}
                        placeholder={"Tel. nr."}
                        error={false}
                      />
                    </div>
                  </div>

                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => {
                          const doc = getDocId(docLabels[4]);

                          if (doc) {
                            updateDoc({
                              documentId: doc.id,
                              documentNr: `${value}`,
                            });
                          }
                        }}
                        type={"number"}
                        value={getPersonDocValue(docLabels[4])}
                        placeholder={"Dokumento Nr."}
                        error={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="profile_debt_wrapper">
              <div className="profile_debt_title">
                <p>Įsiskolinimai</p>
              </div>
              <DebtPanel
                reload={() => {
                  profileRes.execute({ id, isCompany: false });
                }}
                isCompany={false}
                formData={formData}
              />
              <div className="profile_debt_label">
                Informuoti apie įsiskolinimą
              </div>
              <div className="profile_debt_btn_wrapper">
                <button
                  onClick={() => {
                    setDebtPopUpVisibility(true);
                    setHardReminder(false);
                  }}
                  className="flexBtn btn-orange"
                >
                  Priminimas
                </button>
                <button
                  onClick={() => {
                    setDebtPopUpVisibility(true);
                    setHardReminder(true);
                  }}
                  className="flexBtn btn-red"
                >
                  Griežtas priminimas
                </button>
              </div>
              <div className="profile_debt_wrapper_input_div">
                <div>
                  <div className="profile_form_header">
                    4 paskutiniai a. k. sk.
                  </div>
                  <Input
                    placeholder={"Dokumento Nr."}
                    type="number"
                    value={formData.pin}
                    externalValue={formData.pin}
                    setExternalValue={(value) => {
                      if (value.toString().length > 4) {
                        return;
                      }
                      updateFormData({ pin: value });
                    }}
                    error={false}
                    handler={() => {}}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="profile_btn_wrapper">
            <button
              type="button"
              className="flexBtn btn-default profile_btn"
              onClick={() => {
                navigate(parentUrl);
              }}
            >
              Atšaukti
            </button>
            <button
              disabled={!changed}
              type="button"
              className="flexBtn btn-green profile_btn"
              onClick={() => {
                if (changed) {
                  savePerson(formData);
                }
              }}
            >
              Išsaugoti
            </button>
          </div>
        </div>
        <ProfileRecords isCompany={false} />
        <Limitia isCompany={false} />
      </>
    </div>
  ) : (
    <div />
  );
};

export default Profile;

export const DebtPanel = ({
  formData,
  isCompany,
  reload,
}: {
  formData: extPerson | company;
  isCompany: boolean;
  reload: () => void;
}) => {
  const cntID = "profile_debt_amount_id";
  const [deficit, setDeficit] = useState<number>();
  const [editDeficit, setEditDeficit] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const element = document.getElementById(cntID);

      if (!element || !element.contains(e.target as Node)) {
        setEditDeficit(false);
        setDeficit(undefined);
      }
    };
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.addEventListener("click", handleOutsideClick);
    };
  }, []);

  const addDeficitRes = useAsync({
    asyncFunc: addDeficit,
    funcParams: {} as never,
    immediate: false,
    clearOnError: true,
  });

  useEffect(() => {
    const response = addDeficitRes.data;

    if (response) {
      reload();
      setEditDeficit(false);
      setDeficit(undefined);
    }
  }, [addDeficitRes.data]);

  const formatNumber = (value: number): string => {
    const val = `${value}`.replace(".", ",");

    return val;
  };

  return (
    <div id={cntID} className="profile_debt_amount">
      <div
        className="addNewWaste"
        style={{ marginBottom: 16 }}
        onClick={() => {
          setEditDeficit(!editDeficit);
        }}
      >
        <IconContainer>
          <img src={require("../../assets/images/plus.svg").default} alt={``} />
        </IconContainer>
        <div>Atnaujinti informaciją</div>
      </div>
      {editDeficit ? (
        <div className=" profle_deficit_input_wrapper">
          <Input
            placeholder={""}
            type="number"
            value={`${deficit || ""}`}
            externalValue={`${deficit || ""}`}
            setExternalValue={(value) => {
              if (decPlaces(parseFloat(value)) > 2) {
                return;
              }

              setDeficit(value as unknown as number);
            }}
            error={false}
            handler={() => {}}
          />
          <button
            key={`headConstructBtnKey`}
            onClick={() => {
              addDeficitRes.execute({
                id: formData.id,
                isCompany,
                deficit,
              });
            }}
            disabled={!isNumber(deficit) || addDeficitRes.loading}
            className={`${
              isNumber(deficit) ? `btn-green` : `btn-disabled`
            } flexBtn`}
          >
            Išsaugoti
          </button>
        </div>
      ) : (
        <h3>
          {formData.deficit ? formatNumber(formData.deficit) : "0,00"} Eur
        </h3>
      )}
    </div>
  );
};
