import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import ExtraBar from "../../components/UI/ExtraBar/ExtraBar";
import {
  childField,
  Header,
  textBox,
} from "../../components/UI/FormGenerator/formField";
import FormGenerator, {
  GenerateForm,
  Input,
} from "../../components/UI/FormGenerator/FormGenerator";
import {
  option,
  pageConstructType,
} from "../../components/UI/FormGenerator/formTypes";
import IconContainer from "../../components/UI/iconContainer/iconContainer";
import { ReactComponent as DeleteLogo } from "../../assets/images/del.svg";
import "../ProfileRecords/ProfileRecords.css";
import NewConfirmDialog from "../../components/UI/ConfirmDialog/NewConfirmDialog";
import { useAsync } from "../../helpers/asyncFunc";
import { createContract, deleteContract, getContracts } from "./getSet";
import NewPopUpFrame from "../../components/UI/PopupFrame/NewPopUpFrame";
import _ from "lodash";
import "./Contract.css";
import DropDown from "../../components/elements/DropDown/DropDown";
import { connect } from "react-redux";
import { wasteType } from "../../@types/waste";
import { getDropDownValue } from "../../helpers/misc";
import { DateTimePicker } from "../../components/UI";

export type contract = {
  id: number;
  createdAt: string;
  clientId: number;
  validityDate: string;
  conclusionDate: string;
  contractNr: string;
  wasteId: number;
  quantity: number;
  uom: string;
};

export type createContractType = {
  clientId: number;
  validityDate: string;
  conclusionDate: string;
  contractNr: string;
  wasteId: number;
  quantity: number;
  uom: string;
};

const isComplete = (formData: Partial<createContractType>) => {
  return !Object.keys(formData).some((Key) => {
    const key = Key as keyof createContractType;
    const value =
      key === "quantity" ? parseFloat(`${formData[key]}`) : formData[key];
    if (!value) {
      console.log(`Error here: ${key} | ${value}`, formData[key]);
      return true;
    } else {
      return false;
    }
  });
};

export default function Contract({ clientId }: { clientId: number }) {
  const maxCount = 5;
  const defaultFormData: Partial<createContractType> = {
    clientId,
    uom: "kg",
    quantity: undefined,
    conclusionDate: undefined,
    contractNr: undefined,
    validityDate: undefined,
    wasteId: undefined,
  };
  const [formData, setFormData] = useState<Partial<createContractType>>();
  const [contractToDel, setContractToDel] = useState<{ id: number }>();
  const [contracts, setContracts] = useState<contract[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const getContractRes = useAsync({
    asyncFunc: getContracts,
    funcParams: { clientId, limit: maxCount },
    immediate: true,
  });

  useEffect(() => {
    const contractData = getContractRes.data;
    const contracts = contractData?.contracts;
    const count = contractData?.count;
    if (count !== undefined && contracts) {
      setContracts([...contractData.contracts]);
      setTotalCount(parseInt(`${count}`) || 0);
    }
  }, [getContractRes.data]);

  const deleteContractRes = useAsync({
    asyncFunc: deleteContract,
    funcParams: {} as unknown as { id: number },
    immediate: false,
  });

  useEffect(() => {
    const params = deleteContractRes.params;
    const response = deleteContractRes.data;

    if (params.id === contractToDel?.id) {
      setContractToDel(undefined);
    }

    if (response?.success) {
      getContractRes.execute({
        clientId,
        limit: maxCount < contracts.length ? contracts.length : maxCount,
      });
    }
  }, [deleteContractRes.data]);

  const createContractRes = useAsync({
    asyncFunc: createContract,
    funcParams: {} as unknown as createContractType,
    immediate: false,
  });

  useEffect(() => {
    const params = createContractRes.params;
    const response = createContractRes.data;

    if (_.isEqual(params, formData)) {
      setFormData(undefined);
    }

    if (response?.success) {
      getContractRes.execute({
        clientId,
        limit: maxCount < contracts.length ? contracts.length : maxCount,
      });
    }
  }, [createContractRes.data]);

  const dist = [1, 1, 1, 2, "5rem"];

  const headConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [],
      hasHeader: true,
      headerText: [
        Header(["Sudarymo data"], false, false),
        Header(["Galiojimo terminas"], false, false),
        Header(["Numeris"], false, false),
        Header([""], false, false),
      ],
    },
  ];

  const createRecordConstruct = () => {
    return contracts.map((record, n) => textConstruct(record, n));
  };

  const textConstruct = (record: contract, n: number): pageConstructType => ({
    sizeDist: dist,
    typeDist: [
      textBox(moment(record.createdAt).format(`YYYY-MM-DD`), true),
      textBox(moment(record.validityDate).format(`YYYY-MM-DD`), true),
      textBox(record.contractNr, true),
      childField(<div key={"space"}></div>),
      childField(
        <div className="center" key={n}>
          <IconContainer size="lg">
            <DeleteLogo
              onClick={() => {
                setContractToDel({
                  id: record.id,
                });
              }}
            />
          </IconContainer>
        </div>
      ),
    ],
    hasHeader: false,
    headerText: [],
  });

  const updateFormData = (obj: Partial<createContractType>) => {
    setFormData({ ...formData, ...obj });
  };

  return (
    <div className="profileRecords_wrapper">
      <NewPopUpFrame
        showShadow={false}
        handleOpen={() => {
          setFormData(undefined);
        }}
        title={"Nauja sutartis"}
        isShown={!!formData}
        small
        className="wasteAccumPopUp"
        classNameWrap="wasteAccumForm_wrap"
      >
        <CreateContract
          loading={createContractRes.loading}
          close={() => {
            setFormData(undefined);
          }}
          create={() => {
            if (!formData) {
              return;
            }

            if (isComplete(formData)) {
              createContractRes.execute(formData as createContractType);
            }
          }}
          formData={formData!}
          updateFormData={updateFormData}
        />
      </NewPopUpFrame>
      {contractToDel && (
        <NewConfirmDialog
          txt="Ar tikrai norite ištrinti šią sutartį?"
          isVisible={!!contractToDel}
          onExit={() => {
            setContractToDel(undefined);
          }}
          isLoading={deleteContractRes.loading}
          onContinue={() => {
            deleteContractRes.execute(contractToDel);
          }}
        />
      )}
      <div className="profileRecords_header">
        <p className="flex1">Sutartys</p>
        <div
          className="addNewWaste"
          onClick={() => {
            setFormData({ ...defaultFormData });
          }}
        >
          <IconContainer>
            <img
              src={require("../../assets/images/plus.svg").default}
              alt={``}
            />
          </IconContainer>
          <div>Pridėti naują sutartį</div>
        </div>
      </div>
      <GenerateForm>
        {headConstruct.map((construct, n) => (
          <FormGenerator
            key={`seria_header_1_${n}`}
            gridSizeDist={construct.sizeDist}
            gridTypeDist={construct.typeDist}
            hasHeader={construct.hasHeader}
            headerText={construct.headerText}
          />
        ))}
        {createRecordConstruct().map((construct, n) => (
          <FormGenerator
            key={`seria_header_1_${n}`}
            gridSizeDist={construct.sizeDist}
            gridTypeDist={construct.typeDist}
            hasHeader={construct.hasHeader}
            headerText={construct.headerText}
          />
        ))}
      </GenerateForm>
      <ExtraBar
        currentAvailableCount={contracts.length}
        expandFunc={() => {
          getContractRes.execute({
            clientId,
            limit: contracts.length + maxCount,
          });
        }}
        totalCount={totalCount}
      />
    </div>
  );
}

const mapStatetToProps = (state: any) => ({
  wastes: state.wasteReducer.wastes,
});

const CreateContract = connect(mapStatetToProps)(
  ({
    wastes,
    loading,
    close,
    create,
    formData,
    updateFormData,
  }: {
    wastes: wasteType[];
    loading: boolean;
    close: () => void;
    create: () => void;
    formData: Partial<createContractType>;
    updateFormData: (data: Partial<createContractType>) => void;
  }) => {
    const complete = isComplete(formData);
    const [wasteOptions, setWasteOptions] = useState<option[]>([]);

    useEffect(() => {
      const options = wastes.map((opt) => ({
        value: opt.id,
        label: `${opt.code ? `${opt.code} - ` : ""}${opt.waste}`,
      }));

      setWasteOptions([...options]);
    }, [wastes]);

    const wasteValue = (id?: number) => getDropDownValue(id, wasteOptions);

    return (
      <div className="contract_popup">
        <div className="contract_form_wrapper">
          <>
            <div>
              <p>Sutarties numeris</p>
              <Input
                value={formData.contractNr || ""}
                placeholder={""}
                handler={() => {}}
                error={false}
                type={"text"}
                externalValue={formData.contractNr || ""}
                setExternalValue={(contractNr) => {
                  updateFormData({ contractNr });
                }}
              />
            </div>
            <div>
              <p>Sutarties sudarymo data</p>
              <DateTimePicker
                // @ts-ignore
                value={formData.disposalDate}
                onChange={(_, conclusionDate: string) => {
                  updateFormData({ conclusionDate });
                }}
                placeholder="Pasirinkti..."
                style={{ minWidth: "120px" }}
                options={{
                  minDate: new Date(),
                  maxDate: new Date().setDate(new Date().getDate() + 7),
                }}
              />
            </div>
            <div>
              <p>Sutarties galiojimo terminas</p>
              <DateTimePicker
                onChange={(_, validityDate: string) => {
                  updateFormData({ validityDate });
                }}
                placeholder="Pasirinkti..."
                style={{ minWidth: "120px" }}
                options={{
                  minDate: new Date(),
                  maxDate: new Date().setDate(new Date().getDate() + 7),
                }}
              />
            </div>
          </>
          <>
            <div>
              <p>Atliekos tipas</p>
              <DropDown
                options={wasteOptions}
                value={wasteValue(formData.wasteId)}
                onSelect={(wasteId) => {
                  updateFormData({ wasteId: wasteId.value });
                }}
                border={""}
                title={""}
                error={false}
              />
            </div>
            <div>
              <p>Limitas pagal sutartį, t</p>
              <Input
                value={`${
                  formData.quantity !== undefined ? formData.quantity : ""
                }`}
                placeholder={""}
                handler={() => {}}
                error={false}
                type={"number"}
                externalValue={`${
                  formData.quantity !== undefined ? formData.quantity : ""
                }`}
                setExternalValue={(quantity) => {
                  updateFormData({ quantity: quantity as unknown as number });
                }}
              />
            </div>
          </>
        </div>
        <div className="setting_create_button_wrapper flex">
          <button onClick={close} className="flexBtn btn-default">
            Atšaukti
          </button>

          <button
            onClick={create}
            className="flexBtn btn-green"
            disabled={loading || !complete}
          >
            {loading ? "Loading ..." : "Išsaugoti"}
          </button>
        </div>
      </div>
    );
  }
);
