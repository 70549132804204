import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import * as actions from "../../../stores/actions";

import { formatNumber } from "../../../helpers";
import { DateTimePicker } from "../../UI";
import { defaultFormData, defaultFormError, disposalInit } from "./defaultData";

import "./style.scss";
import NewConfirmDialog from "../../UI/ConfirmDialog/NewConfirmDialog";
import { connect } from "react-redux";
import { isEmail } from "../../../helpers/validate";
import CheckBox from "../../UI/checkBox/checkBox";
import { site } from "../../../@types/sites";
import { company } from "../../../@types/client";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeSVG.svg";
import {
  carrierType,
  currentUserType,
} from "../../../pages/WasteAcceptance/@types";
import { disposal } from "../../../pages/Disposal/Disposal";
import { option, pageConstructType } from "../../UI/FormGenerator/formTypes";
import {
  childField,
  dropField,
  Header,
  textBox,
  textField,
} from "../../UI/FormGenerator/formField";
import { getDropDownValue } from "../../../helpers/misc";
import FormGenerator, {
  GenerateForm,
  Input,
} from "../../UI/FormGenerator/FormGenerator";
import "./DisposalForm.css";

const selectedRowId = `editing__disposalRecordID`;

const DisposalForm = function ({
  nextFormNr,
  sites,
  companies,
  clientWastes,
  selectedDisposal,
  saveDisposal,
  currentUser,
  onExit,
  disposalError,
  carriers,
  formData,
  setFormData,
  loading,
  reload,
}: {
  nextFormNr: number | null;
  sites: site[];
  companies: company[];
  clientWastes: {
    [key: number]: number[];
  };
  selectedDisposal: disposal;
  saveDisposal: (disposal: disposalInit) => void;
  currentUser: currentUserType;
  onExit: () => void;
  disposalError: string | null;
  carriers: carrierType[];
  formData: disposalInit;
  setFormData: (data: disposalInit) => void;
  loading: boolean;
  reload: boolean;
}) {
  useEffect(() => {
    return () => {
      setFormData({ ...defaultFormData, id: currentUser.id });
    };
  }, []);

  const [formError, setFormError] = useState<
    Partial<{ [key in keyof disposalInit]: boolean }>
  >({ ...defaultFormError });
  const [siteOptions, setSiteOptions] = useState<option[]>([]);
  const [companyOptions, setCompanyOptions] = useState<option[]>([]);
  const [theDate, setTheDate] = useState("");
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [carrierOptions, setCarrierOptions] = useState<option[]>([]);
  const [disposalEditing, setDisposalEditing] = useState<{
    index: number;
    amount: number;
  }>();

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    const outsideActiveRowClick = (e: MouseEvent) => {
      const target = e.target as Node;
      const element = document.getElementById(selectedRowId);

      if (element && !element.contains(target)) {
        setDisposalEditing(undefined);
      }
    };

    document.addEventListener("click", outsideActiveRowClick);
    return () => {
      document.addEventListener("click", outsideActiveRowClick);
    };
  }, []);

  useEffect(() => {
    if (sites && sites.length > 0) {
      const options = sites.map((site) => {
        return {
          label: site.name,
          value: site.id,
        };
      });

      setSiteOptions(options);
    }
  }, [sites]);

  useEffect(() => {
    if (companies && companies.length > 0) {
      const options = companies.map((company) => {
        return {
          label: company.companyName,
          value: company.id,
        };
      });

      setCompanyOptions(options);
    }
  }, [companies]);

  useEffect(() => {
    if (carriers && carriers.length > 0) {
      const options = carriers.map((carrier) => {
        return {
          label: carrier.name,
          value: carrier.id,
          companyId: carrier.companyId,
        };
      });

      setCarrierOptions(options);
    }
  }, [carriers]);

  useEffect(() => {
    if (theDate) {
      const dateObj = moment(new Date(theDate));
      updateFormData({ disposalDate: dateObj.format("YYYY-MM-DD HH:mm") });
    }
  }, [theDate]);

  useEffect(() => {
    if (nextFormNr) {
      updateFormData({ formNr: nextFormNr });
    }
  }, [nextFormNr]);

  useEffect(() => {
    if (selectedDisposal && formData.formNr) {
      updateFormData({
        disposalSite: selectedDisposal.siteId,
        disposalSiteName: selectedDisposal.siteName,
        disposalAddress: selectedDisposal.address || "",
        disposalCompanyCode: selectedDisposal.companyCode || "",
      });
    }
  }, [selectedDisposal, formData.formNr]);

  useEffect(() => {
    if (
      selectedDisposal &&
      clientWastes[selectedDisposal.siteId] &&
      clientWastes[selectedDisposal.siteId].length > 0
    ) {
      const _disposalWastes = selectedDisposal
        ? selectedDisposal.wastes.filter(
            (waste) =>
              clientWastes[selectedDisposal.siteId].indexOf(waste.id) >= 0
          )
        : null;
      updateFormData({ disposalWastes: _disposalWastes });
    }
  }, [selectedDisposal.siteId]);

  const updateFormData = (objData: Partial<disposalInit>) => {
    const _formError = { ...formError };
    for (const key in objData) {
      _formError[key as keyof disposalInit] = false;
    }
    setFormData({ ...formData, ...objData, formNr: nextFormNr || "" });
    setFormError(_formError);
  };

  const updateCarrierData = (id: number) => {
    const carrier = carriers.find((obj) => obj.id === id);
    const company = companies.find((obj) => obj.id === carrier?.companyId);

    const [driverFirstName, driverLastName] = company?.driver?.split(" ") || [
      null,
      null,
    ];

    updateFormData({
      carrierId: id,
      carrierName: company?.companyName || formData.carrierName || "",
      licensePlate: company?.carNr || formData.licensePlate || "",
      contractNr: company?.contractNr || formData.contractNr || "",
      carrierCompanyCode:
        company?.companyCode || formData.carrierCompanyCode || "",
      recipientEmail: company?.email || formData.recipientEmail || "",
      driverFirstName: driverFirstName || formData.driverFirstName || "",
      driverLastName: driverLastName || formData.driverLastName || "",
    });
  };

  const updateRecipientData = (id: number | "") => {
    const recipient =
      formData.transferType === "INNER"
        ? sites.find((obj) => obj.id === id)
        : companies.find((obj) => obj.id === id);

    const recipientName =
      (formData.transferType === "INNER"
        ? (recipient as site)?.name
        : (recipient as company)?.companyName) || "";

    updateFormData({
      recipientId: id,
      recipientCompanyCode: recipient?.companyCode || "",
      recipientAddress: recipient?.address || "",
      recipientName: recipientName,
    });
  };

  const escFunction = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      onExitHandler();
    }
  };

  const onExitHandler = () => {
    setFormData({ ...defaultFormData });
    setFormError({ ...defaultFormError });
    onExit();
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !formData.recipientId ||
      !formData.contractNr ||
      !formData.disposalSite ||
      !formData.recipientEmail ||
      !isEmail(formData.recipientEmail) ||
      !formData.licensePlate ||
      !formData.driverFirstName ||
      !formData.driverLastName ||
      !formData.recipientCompanyCode ||
      !formData.recipientAddress ||
      !formData.carrierId ||
      !formData.carrierCompanyCode ||
      !formData.disposalCompanyCode ||
      !formData.disposalAddress ||
      !formData.disposalDate
    ) {
      const _formError = { ...formError };

      if (!formData.recipientId) {
        _formError.recipientId = true;
      }

      if (!formData.contractNr) {
        _formError.contractNr = true;
      }

      if (!formData.disposalSite) {
        _formError.disposalSite = true;
      }

      if (!formData.disposalDate) {
        _formError.disposalDate = true;
      }

      if (!formData.recipientEmail || !isEmail(formData.recipientEmail)) {
        _formError.recipientEmail = true;
      }

      if (!formData.licensePlate) {
        _formError.licensePlate = true;
      }

      if (!formData.driverFirstName) {
        _formError.driverFirstName = true;
      }

      if (!formData.driverLastName) {
        _formError.driverLastName = true;
      }

      if (!formData.recipientCompanyCode) {
        _formError.recipientCompanyCode = true;
      }

      if (!formData.recipientAddress) {
        _formError.recipientAddress = true;
      }

      if (!formData.carrierId) {
        _formError.carrierId = true;
      }

      if (!formData.carrierCompanyCode) {
        _formError.carrierCompanyCode = true;
      }

      if (!formData.disposalCompanyCode) {
        _formError.disposalCompanyCode = true;
      }

      if (!formData.disposalAddress) {
        _formError.disposalAddress = true;
      }

      setFormError(_formError);

      return;
    }

    setDialogVisibility(true);
  };

  const createDisposal = async () => {
    saveDisposal(formData);
  };

  useEffect(() => {
    if (reload) {
      setDialogVisibility(false);
      resetForm();
      onExitHandler();
    }
  }, [reload]);

  useEffect(() => {
    if (disposalError) {
      setDialogVisibility(false);
    }
  }, [disposalError]);

  const resetForm = () => {
    setFormData({ ...defaultFormData });
    setFormError({ ...defaultFormError });
  };

  const updateAmount = (wasteId: number, value: number, index: number) => {
    const _disposalWastes = (formData.disposalWastes || []).map((waste, n) => {
      const _waste = { ...waste };
      if (waste.wasteId === wasteId && index === n) {
        _waste.totalWeightAdjusted = value;
      }
      return _waste;
    });

    updateFormData({ disposalWastes: _disposalWastes });
    setDisposalEditing(undefined);
  };

  const carrier_value = (id?: number) => getDropDownValue(id, carrierOptions);
  const recipient_value = (id: number | undefined) => {
    return getDropDownValue(
      id,
      formData.transferType === "INNER" ? siteOptions : companyOptions
    );
  };

  const originConstruct: pageConstructType[] = [
    {
      sizeDist: [1],
      typeDist: [
        childField(
          <Input
            error={!!formError.disposalSite}
            readOnly
            handler={() => {}}
            placeholder=""
            type="text"
            value={formData.disposalSiteName}
          />
        ),
      ],
      hasHeader: true,
      headerText: [Header(["Atliekų siuntėjas"], false, true)],
    },
    {
      sizeDist: [1],
      typeDist: [
        textField(
          "",
          !!formError.disposalCompanyCode,
          () => {},
          formData.disposalCompanyCode,
          (disposalCompanyCode) => {
            updateFormData({ disposalCompanyCode });
          }
        ),
      ],
      hasHeader: true,
      headerText: [Header(["Įmonės numeris"], false, true)],
    },
    {
      sizeDist: [1],
      typeDist: [
        textField(
          "",
          formError.disposalAddress,
          () => {},
          formData.disposalAddress,
          (disposalAddress) => {
            updateFormData({ disposalAddress });
          }
        ),
      ],
      hasHeader: true,
      headerText: [Header(["Atliekų siuntėjo adresas"], false, true)],
    },
    {
      sizeDist: [1],
      typeDist: [
        childField(
          <textarea
            rows={5}
            className={formError.additionalInfo ? "both-error" : ""}
            value={formData.additionalInfo}
            onChange={(e) => updateFormData({ additionalInfo: e.target.value })}
          ></textarea>
        ),
      ],
      hasHeader: true,
      headerText: [Header(["Papildoma informacija"], false, true)],
    },
  ];

  const transportConstruct: pageConstructType[] = [
    {
      sizeDist: [1],
      typeDist: [
        dropField(
          carrierOptions,
          "",
          !!formError.carrierId,
          carrier_value(formData.carrierId || undefined),
          (carrierId) => {
            updateCarrierData(carrierId);
          },
          true
        ),
      ],
      hasHeader: true,
      headerText: [Header(["Atliekų vežėjas"], false, true)],
    },
    {
      sizeDist: [1],
      typeDist: [
        textField(
          "",
          !!formError.carrierCompanyCode,
          () => {},
          formData.carrierCompanyCode,
          (carrierCompanyCode) => {
            updateFormData({ carrierCompanyCode });
          }
        ),
      ],
      hasHeader: true,
      headerText: [Header(["Įmonės numeris"], false, true)],
    },
    {
      sizeDist: [1, 1],
      typeDist: [
        childField(
          <Input
            error={!!formError.licensePlate}
            handler={() => {}}
            placeholder=""
            type="text"
            value=""
            className="licenceInput_wrap"
            externalValue={formData.licensePlate}
            setExternalValue={(licensePlate) => {
              updateFormData({ licensePlate });
            }}
          />
        ),

        childField(
          <DateTimePicker
            value={formData.disposalDate}
            onChange={(_, dt) => setTheDate(dt)}
            placeholder="Pasirinkti..."
            style={{ minWidth: "120px" }}
            className={formError.disposalDate ? "both-error" : ""}
            options={{
              minDate: new Date(),
              maxDate: new Date().setDate(new Date().getDate() + 7),
              enableTime: true,
              dateFormat: "Y-m-d H:i",
            }}
          />
        ),
      ],
      hasHeader: true,
      headerText: [
        Header(["T.P. valstybinis numeris"], false, true),
        Header(["Pageidaujama išvežimo data"], false, true),
      ],
    },
    {
      sizeDist: [1, 1],
      typeDist: [
        textField(
          "",
          formError.driverFirstName,
          () => {},
          formData.driverFirstName,
          (driverFirstName) => {
            updateFormData({ driverFirstName });
          }
        ),
        textField(
          "",
          formError.driverLastName,
          () => {},
          formData.driverLastName,
          (driverLastName) => {
            updateFormData({ driverLastName });
          }
        ),
      ],
      hasHeader: true,
      headerText: [
        Header(["Vairuotojo vardas"], false, true),
        Header(["Vairuotojo pavardė"], false, true),
      ],
    },
    {
      sizeDist: [1],
      typeDist: [
        textField(
          "",
          formError.recipientEmail,
          () => {},
          formData.recipientEmail,
          (recipientEmail) => {
            updateFormData({ recipientEmail });
          }
        ),
      ],
      hasHeader: true,
      headerText: [Header(["Užsakymo gavėjo el. p."], false, true)],
    },
  ];

  const receiverConstruct: pageConstructType[] = [
    {
      sizeDist: [1],
      typeDist: [
        dropField(
          formData.transferType === "INNER" ? siteOptions : companyOptions,
          "",
          !!formError.recipientId,
          recipient_value(formData.recipientId || undefined),
          (recipientId) => {
            updateRecipientData(recipientId);
          },
          true
        ),
      ],
      hasHeader: true,
      headerText: [
        Header(
          [
            <div style={{ gap: "1rem", flexWrap: "wrap" }} className="flex">
              <span>Atliekų gavėjas</span>
              <div className="unit_waste_creation_wrapper flex">
                <div>
                  <CheckBox
                    isChecked={formData.transferType === "INNER"}
                    onChange={() => {
                      updateFormData({
                        recipientId: "",
                        recipientCompanyCode: "",
                        recipientAddress: "",
                        recipientName: "",
                        transferType:
                          formData.transferType === "INNER" ? "OUTER" : "INNER",
                      });
                    }}
                  />
                </div>
                <div>Vidinis pervežimas</div>
              </div>
            </div>,
          ],
          false,

          true
        ),
      ],
    },
    {
      sizeDist: [1],
      typeDist: [
        textField(
          "",
          formError.recipientCompanyCode,
          () => {},
          formData.recipientCompanyCode,
          (recipientCompanyCode) => {
            updateFormData({ recipientCompanyCode });
          }
        ),
      ],
      hasHeader: true,
      headerText: [Header(["Įmonės numeris"], false, true)],
    },
    {
      sizeDist: [1],
      typeDist: [
        textField(
          "",
          formError.recipientAddress,
          () => {},
          formData.recipientAddress,
          (recipientAddress) => {
            updateFormData({ recipientAddress });
          }
        ),
      ],
      hasHeader: true,
      headerText: [Header(["Atliekų gavėjo adresas"], false, true)],
    },
    {
      sizeDist: [1],
      typeDist: [
        textField(
          "",
          formError.contractNr,
          () => {},
          formData.contractNr,
          (contractNr) => {
            updateFormData({ contractNr });
          }
        ),
      ],
      hasHeader: true,
      headerText: [Header(["Sutarties Nr."], false, true)],
    },
  ];

  const dist = [1, 1.5, 1, 1, 1];

  const pageConstruct: pageConstructType = {
    sizeDist: dist,
    typeDist: [],
    hasHeader: true,
    headerText: [
      Header(["Atliekų tvarkymo vieta"], false, true),
      Header(["Atliekos kodas ir pavadinimas"], false, true),
      Header(["Limitas %"], false, true),
      Header(["Svoris t"], false, true),
    ],
  };

  const textConstruct: pageConstructType[] = (
    formData.disposalWastes || []
  ).map((record, n) => {
    const editing = disposalEditing && disposalEditing.index === n;

    return {
      sizeDist: dist,
      typeDist: [
        textBox(record.siteName, false),
        textBox(record.wasteName, false),
        textBox(`${formatNumber(record.limitPctgAdjusted, false)} %`, false),
        editing
          ? childField(
              <Input
                error={false}
                handler={() => {}}
                placeholder=""
                type="number"
                value=""
                className="alignRight_disposal_input"
                externalValue={`${disposalEditing.amount}`}
                setExternalValue={(amount) => {
                  setDisposalEditing({
                    ...disposalEditing,
                    amount: amount as unknown as number,
                  });
                }}
              />
            )
          : textBox(`${formatNumber(record.totalWeightAdjusted || 0)}`, false),
        childField(
          <div key={n} className="flexEnd">
            <button
              type="button"
              className="btn btn-green dispAmtBtn"
              onClick={() => {
                if (editing) {
                  if (disposalEditing.amount > record.totalWeightAdjusted) {
                    return;
                  } else {
                    updateAmount(record.wasteId, disposalEditing.amount, n);
                  }
                } else {
                  setDisposalEditing({
                    index: n,
                    // amount: parseFloat(record.totalWeightAdjusted.toFixed(3)),
                    amount: record.totalWeightAdjusted,
                  });
                }
              }}
              disabled={
                editing
                  ? disposalEditing.amount > record.totalWeightAdjusted ||
                    !parseFloat(disposalEditing.amount as unknown as string)
                  : false
              }
            >
              {editing ? "Išsaugoti" : "Redaguoti"}
            </button>
          </div>
        ),
      ],
      hasHeader: false,
      headerText: [],
    };
  });

  const total = () => {
    let weight = 0;
    (formData.disposalWastes || []).forEach((record, n) => {
      if (disposalEditing?.index === n) {
        weight += parseFloat(`${disposalEditing.amount}`);
      } else {
        weight += parseFloat(`${record.totalWeightAdjusted}`);
      }
    });

    return weight;
  };

  const tailConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [
        childField(<div key={"space1"}></div>),
        childField(<div key={"space2"}></div>),
        childField(
          <div key={"title"} className="bold_tail_wMgt">
            Bendras kiekis
          </div>
        ),
        childField(
          <div key={"weight"} className="bold_tail_wMgt">
            {formatNumber(total())} t
          </div>
        ),
        childField(<div key={"space3"}></div>),
      ],
      hasHeader: false,
      headerText: [],
    },
  ];

  const isEditing = (index: number) => {
    return !!(index === disposalEditing?.index);
  };

  return (
    <div onClick={onExitHandler} className="disposalForm_wrapper">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="waste_create_container"
      >
        {dialogVisibility && (
          <NewConfirmDialog
            txt="Ar tikrai norite pateikti atliekų išvežimo užsakymą?"
            isVisible={dialogVisibility}
            onExit={() => {
              setDialogVisibility(false);
            }}
            isLoading={loading}
            onContinue={() => createDisposal()}
          />
        )}
        <div>
          <div className="waste_create_container_header">
            <div>
              <h4>Atliekų išvežimo užsakymas Nr. {formData.formNr}</h4>
              <h4>Data: {moment().format("YYYY-MM-DD")}</h4>
            </div>
            <div className="popUpIcon_div center" onClick={onExitHandler}>
              <CloseIcon />
            </div>
          </div>

          <form onSubmit={onSubmitHandler}>
            <div
              className="disposalForm_table_holder"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: "2em",
                marginTop: "30px",
              }}
            >
              <GenerateForm>
                {originConstruct.map((construct, n) => (
                  <FormGenerator
                    key={n}
                    gridSizeDist={construct.sizeDist}
                    gridTypeDist={construct.typeDist}
                    hasHeader={construct.hasHeader}
                    headerText={construct.headerText}
                  />
                ))}
              </GenerateForm>
              <GenerateForm>
                {transportConstruct.map((construct, n) => (
                  <FormGenerator
                    key={n}
                    gridSizeDist={construct.sizeDist}
                    gridTypeDist={construct.typeDist}
                    hasHeader={construct.hasHeader}
                    headerText={construct.headerText}
                  />
                ))}
              </GenerateForm>
              <GenerateForm>
                {receiverConstruct.map((construct, n) => (
                  <FormGenerator
                    key={n}
                    gridSizeDist={construct.sizeDist}
                    gridTypeDist={construct.typeDist}
                    hasHeader={construct.hasHeader}
                    headerText={construct.headerText}
                  />
                ))}
              </GenerateForm>
            </div>

            <div
              className="btn-action disposalForm_btn_wrapper"
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                className="btn btn-default"
                onClick={() => onExitHandler()}
              >
                Atšaukti
              </button>
              <button className="btn btn-green">Pateikti užsakymą</button>
            </div>

            <p>Išvežamų atliekų sąrašas</p>
            {/* <h4>Išvežamų atliekų sąrašas</h4> */}

            <GenerateForm classNameWrap={"disposalAmount_table_wrap"}>
              {[pageConstruct].map((construct, n) => (
                <FormGenerator
                  key={n}
                  gridSizeDist={construct.sizeDist}
                  gridTypeDist={construct.typeDist}
                  hasHeader={construct.hasHeader}
                  headerText={construct.headerText}
                />
              ))}
              {textConstruct.map((construct, n) => (
                <FormGenerator
                  key={n}
                  id={isEditing(n) ? selectedRowId : undefined}
                  gridSizeDist={construct.sizeDist}
                  gridTypeDist={construct.typeDist}
                  hasHeader={construct.hasHeader}
                  headerText={construct.headerText}
                />
              ))}
              {tailConstruct.map((construct, n) => (
                <FormGenerator
                  key={`disposalSummary_${n}`}
                  gridSizeDist={construct.sizeDist}
                  gridTypeDist={construct.typeDist}
                  hasHeader={construct.hasHeader}
                  headerText={construct.headerText}
                />
              ))}
            </GenerateForm>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStoreStateToProps = (store: any) => ({
  currentUser: store.authReducer.currentUser,
});

export default connect(mapStoreStateToProps)(DisposalForm);
