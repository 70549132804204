import { connect } from 'react-redux';

import UserProfile from "./UserProfile";
import * as actions from '../../../stores/actions';

const mapStoreStateToProps = function (store) {
	return {};
};

const mapDispatchToProps = function (dispatch) {
	return {
		signOut: function () {
			dispatch(actions.signOut());
		},
	};
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(UserProfile);
