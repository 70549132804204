import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { wastesAccepted } from "../../@types/client";
import DropDown from "../../components/elements/DropDown/DropDown";
import {
  childField,
  Header,
  textBox,
} from "../../components/UI/FormGenerator/formField";
import FormGenerator, {
  GenerateForm,
} from "../../components/UI/FormGenerator/FormGenerator";
import {
  option,
  pageConstructType,
} from "../../components/UI/FormGenerator/formTypes";
import "./ProfileRecords.css";
import { Link } from "react-router-dom";
import { docNumTransform, serijaNumTransform } from "../../helpers/misc";
import ExtraBar from "../../components/UI/ExtraBar/ExtraBar";
import { useAsync } from "../../helpers/asyncFunc";
import { fetchWastes } from "../Profile/getter";
import { useQuery } from "../../App";

export default function ProfileRecords({ isCompany }: { isCompany: boolean }) {
  const query = useQuery();
  const id = Number(query.get("id")) || null;

  const getYears = (): option[] => {
    const startDate = 2022;
    const endDate = new Date().getFullYear();
    const diff = endDate - startDate;
    const options = Array(diff + 1)
      .fill(0)
      .map((arr, n) => {
        return {
          label: `${startDate + n}`,
          value: n,
        };
      });

    return options;
  };

  const yearOption = getYears();
  const [selectedYear, setSelectedYear] = useState<option>(
    getYears()[getYears().length - 1]
  );
  const [wastesAccepted, setWastesAccepted] = useState<wastesAccepted[]>([]);
  const [totalCount, setTotalCount] = useState<number>();

  const dist = [1, 1, 1.4, 1, 1, "6rem", "6rem"];
  const limit = 5;

  const wasteRes = useAsync(
    {
      asyncFunc: fetchWastes,
      funcParams: {
        id,
        year: selectedYear?.label,
        isCompany,
        limit,
      },
      immediate: true,
    },
    [selectedYear]
  );

  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const getWasteList = async () => {
    await fetchWastes({
      id,
      year: selectedYear?.label,
      isCompany,
      limit: wastesAccepted.length + limit,
    }).then(
      (record) => {
        if (record && mountedRef.current) {
          setWastesAccepted(record.wastesAccepted);
          setTotalCount(record.count);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    const record = wasteRes.data;
    if (record) {
      setWastesAccepted(record.wastesAccepted);
      setTotalCount(record.count);
    }
  }, [wasteRes.data]);

  const headConstruct: pageConstructType[] = [
    {
      sizeDist: dist,
      typeDist: [],
      hasHeader: true,
      headerText: [
        Header(["Data"], false),
        Header(["Atliekos kodas"], false),
        Header(["Atliekos pavadinimas"], false),
        Header(["Kiekis"], false),
        Header(["Aikštelė / Savartynas"], false),
        Header(["Sąskaita"], false),
        Header(["Deklaracija"], false),
      ],
    },
  ];

  const createRecordConstruct = (wastesAccepted: wastesAccepted[]) => {
    return wastesAccepted.map((data, n) => textConstruct(data, n));
  };

  const docLink = (
    id: number,
    isInvoice: boolean,
    isCompany: boolean
  ): string => {
    return `/doc?type=${
      isInvoice ? `invoice` : `declaration`
    }&id=${id}&isCompany=${isCompany}`;
  };

  const textConstruct = (
    data: wastesAccepted,
    n: number
  ): pageConstructType => ({
    sizeDist: dist,
    typeDist: [
      textBox(moment(data.date).format(`YYYY-MM-DD`), true),
      textBox(data.waste_code, true),
      textBox(data.waste_name, true),
      textBox(`${data.weight}`, true),
      textBox(data.site_name, true),
      childField(
        data.invoice ? (
          <Link
            className="profileRecord_dec_link"
            target={"_blank"}
            to={docLink(data.id, true, isCompany)}
            key={`inv${n}`}
          >
            {serijaNumTransform({
              acceptanceId: data.acceptanceId,
              serija: data.serija,
              serija_doc_nr: data.serija_doc_nr || undefined,
            })}
          </Link>
        ) : (
          <div key={`inv${n}`}></div>
        )
      ),
      childField(
        <Link
          className="profileRecord_dec_link"
          target={"_blank"}
          to={docLink(data.id, false, isCompany)}
          key={`dec${n}`}
        >
          Nr. {docNumTransform(data.acceptanceId)}
        </Link>
      ),
    ],
    hasHeader: false,
    headerText: [],
  });

  return (
    <div className="profileRecords_wrapper">
      <div className="profileRecords_header">
        <p>Pristatytos / Perduotos atliekos</p>
        <div className="center">
          <p className="profileRecords_header_date">Pasirinkite metus</p>
          <div className="profileRecords_header_dropD_container">
            <DropDown
              options={yearOption}
              value={selectedYear}
              onSelect={(details) => {
                setSelectedYear(details);
              }}
              border={""}
              title={""}
              error={false}
            />
          </div>
        </div>
      </div>
      <GenerateForm>
        {headConstruct.map((construct, n) => (
          <FormGenerator
            key={`seria_header_1_${n}`}
            gridSizeDist={construct.sizeDist}
            gridTypeDist={construct.typeDist}
            hasHeader={construct.hasHeader}
            headerText={construct.headerText}
          />
        ))}
        {createRecordConstruct(wastesAccepted).map((construct, n) => (
          <FormGenerator
            key={`seria_header_1_${n}`}
            gridSizeDist={construct.sizeDist}
            gridTypeDist={construct.typeDist}
            hasHeader={construct.hasHeader}
            headerText={construct.headerText}
          />
        ))}
      </GenerateForm>
      <ExtraBar
        currentAvailableCount={wastesAccepted.length}
        expandFunc={() => {
          getWasteList();
        }}
        totalCount={totalCount || 0}
      />
    </div>
  );
}
