import * as actionTypes from "../actionTypes";

const defaultNotification = {
  type: "",
  txt: "",
};

const defaultConfirmDialog = {
  txt: "",
};

const initialState = {
  currentSiteId: null,
  isLoading: false,
  notification: { ...defaultNotification },
  confirmDialog: { ...defaultConfirmDialog },
  isConfirmYes: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CURRENT_SITE:
      return {
        ...state,
        currentSiteId: action.payload,
      };
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case actionTypes.SET_CONFIRM_DIALOG:
      return {
        ...state,
        confirmDialog: action.payload,
      };
    case actionTypes.SET_CONFIRM_DIALOG_YES:
      return {
        ...state,
        isConfirmYes: true,
      };
    case actionTypes.RESET_CONFIRM_DIALOG:
      return {
        ...state,
        isConfirmYes: false,
        confirmDialog: { ...defaultConfirmDialog },
      };
    default:
      return state;
  }
}

export default reducer;
