import React, { useEffect, useState } from "react";

import InputText from "../InputText";

import "./style.scss";

const Suggestion = function ({ options, onSelect }) {
  const [processedOptions, setProcessedOptions] = useState([]);
  const [selectedText, setSelectedText] = useState("");

  useEffect(() => {
    if (options) {
      setProcessedOptions(options);
    }
  }, [options]);

  const textOnChange = (txt) => {
    setSelectedText(txt);
    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(txt.toLowerCase())
    );
    if (filteredOptions && filteredOptions.length > 0) {
      setProcessedOptions(filteredOptions);
    }
  };

  const selectOption = (val) => {
    const filteredOptions = options.filter((option) => option.value === val);
    if (filteredOptions && filteredOptions.length > 0) {
      setSelectedText(filteredOptions[0].label);
      onSelect(val);
    }
  };

  const generatedOptions =
    processedOptions && processedOptions.length > 0
      ? processedOptions.map((option, i) => {
          return (
            <li
              key={`option-${i}`}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => selectOption(option.value)}
            >
              {option.label}
            </li>
          );
        })
      : null;

  return (
    <div className="suggestion-wrapper">
      <InputText
        className="suggestion-text"
        value={selectedText}
        onChange={(e) => textOnChange(e.target.value)}
      />
      <ul className={`suggestion-options`}>{generatedOptions}</ul>
    </div>
  );
};

export default Suggestion;
