import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { serija, site, userSite } from "../../@types/sites";
import {
  childField,
  dropField,
  Header,
  textBox,
  textField,
} from "../../components/UI/FormGenerator/formField";
import FormGenerator, {
  GenerateForm,
} from "../../components/UI/FormGenerator/FormGenerator";
import { pageConstructType } from "../../components/UI/FormGenerator/formTypes";
import IconContainer from "../../components/UI/iconContainer/iconContainer";
import { ReactComponent as DeleteLogo } from "../../assets/images/del.svg";
import "./Serija.css";
import { RadioButton } from "../../components/UI/checkBox/checkBox";
import moment from "moment";
import floatInfo from "../../components/UI/floatInfo/floatInfo";

const Seria = ({
  sites,
  serijas,
  createSerija,
  getAllSerijas,
  makeSerijaActive,
  deleteSerija,
}: {
  sites: site[];
  serijas: serija[];
  createSerija: (createObj: { siteId: number; serija: string }) => void;
  getAllSerijas: () => void;
  makeSerijaActive: (activeObj: { siteId: number; serija: string }) => void;
  deleteSerija: (serija: { serija: string }) => void;
}) => {
  const [siteOptions, setSiteOptions] = useState<userSite[]>([]);
  const [serija, setSerija] = useState("");
  const [selectedSite, selectSite] = useState<number>();

  const hasError = (newSerija: string) => {
    return serijas.some(
      (ser) => ser.name.toLocaleUpperCase() === newSerija.toLocaleUpperCase()
    );
  };

  const wrongLengthSerija = (newSerija: string) => {
    return newSerija.length !== 4;
  };

  useEffect(() => {
    getAllSerijas();
  }, [sites]);

  useEffect(() => {
    if (sites) {
      const options: userSite[] = [];
      sites.forEach((site) => {
        options.push({ value: site.id, label: site.name });
      });
      setSiteOptions(options);
    }
  }, [sites]);

  const pageConstruct: pageConstructType[] = [
    {
      sizeDist: [1, 1, `minmax(auto, 1fr)`],
      typeDist: [
        dropField(siteOptions, "", false, { label: "", value: 0 }, (value) => {
          selectSite(value);
        }),
        textField(
          `Įrašykite sąskaitos faktūros seriją`,
          false,
          (txt) => setSerija(txt),
          serija,
          setSerija
        ),
        childField(
          <button
            key={`headConstructBtnKey`}
            onClick={() => {
              if (
                selectedSite &&
                serija &&
                !hasError(serija) &&
                !wrongLengthSerija(serija)
              ) {
                setSerija("");
                createSerija({
                  siteId: selectedSite,
                  serija: serija.toLocaleUpperCase(),
                });
              }
            }}
            onMouseOver={(e) => {
              if (
                !(
                  selectedSite &&
                  serija &&
                  !hasError(serija) &&
                  !wrongLengthSerija(serija)
                )
              ) {
                floatInfo.subscribe(
                  e,
                  !selectedSite
                    ? `Pasirinkite objektą.`
                    : !serija
                    ? `Pridėkite seriją.`
                    : hasError(serija)
                    ? `Ši serija jau yra arba jau buvo, turite sukurti kitą seriją.`
                    : `PVM sąskaitos faktūros serija turi būti sudaryta iš 4 simbolių.`
                );
              } else {
                floatInfo.unsubscribe(e);
              }
            }}
            onMouseOut={(e) => {
              if (
                !(
                  selectedSite &&
                  serija &&
                  !hasError(serija) &&
                  !wrongLengthSerija(serija)
                )
              ) {
                floatInfo.unsubscribe(e);
              }
            }}
            className={`${
              selectedSite &&
              serija &&
              !hasError(serija) &&
              !wrongLengthSerija(serija)
                ? `btn-green`
                : `btn-disabled`
            } seria_btn`}
          >
            Išsaugoti
          </button>
        ),
      ],
      hasHeader: true,
      headerText: [
        Header(["Pasirinkite objektą"], false),
        Header(["PVM sąskaitos faktūros serija"], false),
      ],
    },
  ];

  const headConstruct: pageConstructType[] = [
    {
      sizeDist: [`6rem`, 1, 1, 1],
      typeDist: [],
      hasHeader: true,
      headerText: [
        Header(["Naudojama"], false, true),
        Header(["Serija"], false, true),
        Header(["Sukūrimo data"], false, true),
      ],
    },
  ];

  const createSerijaConstruct = (serija: serija, site: site) =>
    textConstruct(serija, site);

  const textConstruct = (serija: serija, site: site): pageConstructType => ({
    sizeDist: [`6rem`, 1, 1, 1],
    typeDist: [
      childField(
        <div
          key={`${serija.name}_${site.name}`}
          className="seria_radio_btn_container"
        >
          <RadioButton
            name={`${site.name}_seriaRadioButton`}
            isChecked={serija.name === site.active_serija}
            onChange={() => {
              makeSerijaActive({ serija: serija.name, siteId: serija.site });
            }}
          />
        </div>
      ),
      textBox(serija.name, false),
      textBox(moment(serija.createdAt).format("YYYY-MM-DD"), false),
      childField(
        <div key={`${serija.name}_del_btn`} className="flex1 jus_flex_end">
          {serija.name !== site.active_serija && (
            <IconContainer size="lg">
              <DeleteLogo
                onClick={() => {
                  deleteSerija({ serija: serija.name });
                }}
              />
            </IconContainer>
          )}
        </div>
      ),
    ],
    hasHeader: false,
    headerText: [],
  });

  return (
    <div className="serija_wrapper">
      <GenerateForm>
        {pageConstruct.map((construct, n) => (
          <FormGenerator
            key={`seria_headerField_1_${n}`}
            gridSizeDist={construct.sizeDist}
            gridTypeDist={construct.typeDist}
            hasHeader={construct.hasHeader}
            headerText={construct.headerText}
          />
        ))}
      </GenerateForm>
      <div className="seria_site_container">&nbsp;</div>
      <GenerateForm noMargin={true}>
        {headConstruct.map((construct, n) => (
          <FormGenerator
            key={`seria_header_1_${n}`}
            gridSizeDist={construct.sizeDist}
            gridTypeDist={construct.typeDist}
            hasHeader={construct.hasHeader}
            headerText={construct.headerText}
          />
        ))}
      </GenerateForm>

      {sites.map((site, nd) =>
        site.Serijas.length > 0 && site.active_serija ? (
          <React.Fragment key={`${site.name}_${nd}_serija_mapper`}>
            <div className="seria_site_container">{site.name}</div>
            <GenerateForm>
              {site.Serijas.map((siteSerija, n) => {
                const construct = createSerijaConstruct(siteSerija, site);
                return (
                  construct && (
                    <FormGenerator
                      key={`seria_details_1_${n}_${nd}`}
                      gridSizeDist={construct.sizeDist}
                      gridTypeDist={construct.typeDist}
                      hasHeader={construct.hasHeader}
                      headerText={construct.headerText}
                    />
                  )
                );
              })}
            </GenerateForm>
          </React.Fragment>
        ) : (
          ``
        )
      )}
    </div>
  );
};

export default Seria;
