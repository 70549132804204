import React, { useEffect, useState } from "react";

import { formatNumber } from "../../helpers";
import { DataExportService } from "../../services";
import { alignment, defaultDataType } from "../../services/DataExportService";
import { ReactComponent as EditLogo } from "../../assets/images/edit.svg";
import { ReactComponent as TickLogo } from "../../assets/images/tick.svg";
import "./style.scss";
import "./disposal.css";
import WarningIcon from "../../assets/images/info.svg";
import IconContainer from "../../components/UI/iconContainer/iconContainer";
import CheckBox from "../../components/UI/checkBox/checkBox";
import { site } from "../../@types/sites";
import { option } from "../../components/UI/FormGenerator/formTypes";
import ExtraBar from "../../components/UI/ExtraBar/ExtraBar";
import ButtonXlsx from "../../components/elements/ButtonXlsx/ButtonXlsx";
import InputNumber from "../../components/UI/InputNumber";
import { saveAdjustedWasteWeight } from "./setter";
import { carrierType, currentUserType } from "../WasteAcceptance/@types";
import { wasteType } from "../../@types/waste";
import DisposalForm from "../../components/elements/DisposalForm/DisposalForm";
import { company } from "../../@types/client";
import FilterButtons from "../../components/UI/filterButtons/FilterButtons";
import NewMultiDropDown from "../../components/UI/NewMultiDropDown/NewMultiDropDown";
import { useAsync } from "../../helpers/asyncFunc";
import { getDisposals, saveDisposal, singleDisposals } from "./getSet";
import {
  defaultFormData,
  disposalInit,
} from "../../components/elements/DisposalForm/defaultData";
import ExcelDownload from "../../components/UI/DownloadPopUp/DownloadPopUp";
import Notification from "../../components/UI/Notification/Notification";
import { LoadingGenerator } from "../../components/UI/FormGenerator/FormGenerator";

export type delivery = {
  id: number;
  siteId: number;
  wasteId: number;
  siteName: string;
  wasteName: string;
  threshold: number | null;
  criticalLimit: number | null;
  totalWeight: number;
  totalWeightAdjusted: number;
  limitPctg: number;
  limitPctgAdjusted: number;
};

export type disposal = {
  siteId: number;
  siteName: string;
  wastes: delivery[];
  count: number;
  address: string;
  companyCode: string;
};

const Disposal = function ({
  sites,
  wastes,
  nextFormNr,
  getNextFormNr,
  companies,
  currentUser,
  isAdmin,
  carriers,
}: {
  sites: site[];
  wastes: wasteType[];
  nextFormNr: number | null;
  getNextFormNr: () => void;
  companies: company[];
  currentUser: currentUserType;
  isAdmin: boolean;
  carriers: carrierType[];
}) {
  const [formData, setFormData] = useState<disposalInit>({
    ...defaultFormData,
    id: currentUser.id,
  });

  const maxCount = 2;
  type defaultFilterType = {
    siteId: option[];
    wasteId: option[];
    general_limit: number;
  };
  const defaultFilter = {
    siteId: [],
    wasteId: [],
    general_limit: maxCount,
  };

  const dataExportService = new DataExportService();

  // const cacheFunction = (array: [string, string]) =>
  //   Object.fromEntries(new Map<string, string>([array]));
  //CacheFilter to create independence between sort types
  const [filterCache, cacheFilter] = useState<{ [key: string]: string }>({
    limitPctg: "DESC",
  });
  const [disposals, setDisposals] = useState<
    {
      [key: string]: disposal;
    }[]
  >([]);
  const [filter, setFilter] = useState<defaultFilterType>(defaultFilter);
  const [siteOptions, setSiteOptions] = useState<option[]>([]);
  const [wasteOptions, setWasteOptions] = useState<option[]>([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [clientWastes, setClientWastes] = useState<{ [key: number]: number[] }>(
    {}
  );
  const [selectedDisposal, setSelectedDisposal] = useState<disposal | null>(
    null
  );
  const [orderFilter, setOrderFilter] = useState({
    orderColumn: "limitPctg",
    orderType: "DESC",
  });
  const [editWeightAdjustment, setEditWeightAdjustment] = useState<{
    wasteId: number;
    siteId: number;
  }>();
  const [editWeightAdjustmentValue, setEditWeightAdjustmentValue] =
    useState<number>(0);
  const [disposalFormSuccess, setDisposalFormSuccess] = useState(false);
  const [disposalFormError, setDisposalFormError] = useState(false);

  const filterOut = (
    multi: (keyof defaultFilterType)[],
    optionalObj?: {
      siteId: number;
      site_limit: number;
      order: string[];
      wasteId: option[];
    }
  ) => {
    const filterObj = optionalObj ? optionalObj : filter;

    const query = Object.keys(filterObj)
      .map((Key) => {
        const multiFilterKeys: (keyof defaultFilterType)[] = multi;

        const key = Key as keyof typeof filterObj;

        if (multiFilterKeys.indexOf(key) >= 0) {
          return (
            key +
            "=" +
            (filterObj[key] as option[]).map((i) => i.value).join(",")
          );
        } else {
          return key + "=" + filterObj[key];
        }
      })
      .join("&");

    return query;
  };

  const Filter = filterOut(["siteId", "wasteId"]);

  const saveDisposalRes = useAsync(
    {
      asyncFunc: saveDisposal,
      funcParams: formData,
      immediate: false,
    },
    []
  );

  const getSingleDisposalsRes = useAsync(
    {
      asyncFunc: singleDisposals,
      funcParams: { query: Filter, disposals },
      immediate: false,
    },
    []
  );

  useEffect(() => {
    const disposalResponse = getSingleDisposalsRes.data;

    if (disposalResponse) {
      setDisposals([...disposalResponse]);
    }
  }, [getSingleDisposalsRes.data]);

  const getDisposalRes = useAsync(
    {
      asyncFunc: getDisposals,
      funcParams: { query: Filter },
      immediate: true,
    },
    [saveDisposalRes.data, Filter]
  );

  useEffect(() => {
    const disposalResponse = getDisposalRes.data;

    setDisposals(disposalResponse ? [...disposalResponse.disposals] : []);
  }, [getDisposalRes.data]);

  useEffect(() => {
    if (sites && sites.length > 0) {
      const options = sites.map((site) => {
        return {
          label: site.name,
          value: site.id,
        };
      });

      setSiteOptions(options);
    }
  }, [sites]);

  useEffect(() => {
    if (wastes && wastes.length > 0) {
      const options = wastes.map((waste) => {
        return {
          label: `${waste.code} ${waste.waste}`,
          value: waste.id,
        };
      });

      setWasteOptions(options);
    }
  }, [wastes]);

  const viewMore = (siteId: number, site_limit: number) => {
    //siteId, order,site_limit
    const order = [orderFilter.orderColumn, orderFilter.orderType];
    const Filter = { siteId, site_limit, order, wasteId: filter.wasteId };
    const queryString = filterOut(["wasteId"], Filter);

    getSingleDisposalsRes.execute({ query: queryString, disposals });
  };

  const updateFilter = (objFilter: Partial<defaultFilterType>) => {
    setFilter({ ...filter, ...objFilter });
  };

  const selectSite = (_disposal: disposal) => {
    if (
      clientWastes[_disposal.siteId as keyof typeof clientWastes] &&
      clientWastes[_disposal.siteId as keyof typeof clientWastes].length > 0
    ) {
      setSelectedDisposal({ ..._disposal });
      getNextFormNr();
      setIsFormVisible(true);
    }
  };

  const clientWasteOnClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    siteId: number,
    id: number
  ) => {
    let _clientWastes = { ...clientWastes };

    if (!_clientWastes[siteId]) {
      _clientWastes[siteId] = [];
    }

    if (e.target.checked) {
      if (_clientWastes[siteId].indexOf(id) === -1) {
        _clientWastes[siteId].push(id);
      }
    } else {
      if (_clientWastes[siteId].indexOf(id) >= 0) {
        _clientWastes[siteId] = _clientWastes[siteId].filter((x) => x !== id);
      }
    }

    setClientWastes(_clientWastes);
  };

  const siteTitle = (disposal: disposal) => {
    return disposal.wastes.some((waste) =>
      waste.threshold
        ? waste.totalWeightAdjusted >= waste.threshold
        : waste.criticalLimit
        ? waste.totalWeightAdjusted >= waste.criticalLimit
        : false
    );
  };

  const limitPctgColor = (delivery: delivery) => {
    let color = "";
    if (
      delivery.threshold &&
      delivery.totalWeightAdjusted >= delivery.threshold
    ) {
      color = "clrOrange";
    }
    if (
      delivery.criticalLimit &&
      delivery.totalWeightAdjusted >= delivery.criticalLimit
    ) {
      color = "clrRed";
    }

    return color;
  };

  const disposalFormOnExit = () => {
    setIsFormVisible(false);
    setClientWastes({});
    getDisposalRes.execute({ query: Filter });
  };

  const sortWaste = (deliveries: delivery[]) => {
    return deliveries.sort((a, b) => {
      const order = orderFilter.orderType === "ASC" ? -1 : 1;
      let columnSort;
      if (orderFilter.orderColumn === "totalWeightAdjusted") {
        columnSort = "totalWeightAdjusted";
      } else {
        columnSort = "limitPctgAdjusted";
      }
      return (
        order *
        ((b[columnSort as "limitPctgAdjusted" | "totalWeightAdjusted"] -
          a[
            columnSort as "limitPctgAdjusted" | "totalWeightAdjusted"
          ]) as number)
      );
    });
  };

  const min = (num1: number) => {
    if (num1 < maxCount) {
      return num1;
    } else {
      return maxCount;
    }
  };

  const sortToggle = (type: string) => {
    const orderType = filterCache[type];
    const newType = orderType === "DESC" ? "ASC" : "DESC";
    setOrderFilter({
      orderColumn: type,
      orderType: newType,
    });

    cacheFilter({ ...filterCache, [type]: newType });
  };

  const setAdjustedWasteWeight = (
    siteId: number,
    wasteId: number,
    totalWeight: number,
    totalWeightAdjusted: number
  ) => {
    saveAdjustedWasteWeight({
      siteId,
      wasteId,
      totalWeight,
      totalWeightAdjusted,
      adjustedWeight: parseFloat(
        formatNumber(editWeightAdjustmentValue, false)
      ),
      userId: currentUser.id,
    }).then(() => {
      setEditWeightAdjustment(undefined);
      disposalFormOnExit();
    });
  };

  useEffect(() => {
    const done = saveDisposalRes.called && !!saveDisposalRes.data;
    setDisposalFormSuccess(done);
  }, [saveDisposalRes.called && !!saveDisposalRes.data]);
  useEffect(() => {
    const error = !!saveDisposalRes.error;
    setDisposalFormError(error);
  }, [saveDisposalRes.error]);

  const reset = () => {
    setDisposalFormSuccess(false);
    setDisposalFormError(false);
  };

  return (
    <div className="disposal">
      <div>
        <div>
          <div className="table-action">
            <FilterButtons
              multiFilters={["siteId", "wasteId"]}
              filter={filter}
              setFilter={setFilter}
              defaultFilter={defaultFilter}
              exclude={["general_limit"]}
            />
            <ExcelDownload type={"disposal"} query={Filter} />
            {/* <ButtonXlsx onClick={() => xlsxExport()} /> */}
          </div>
        </div>
        <div className="disposal_body_wrapper">
          <div
            style={{ display: "block", padding: 0 }}
            className="disposal_filter_wrapper"
          >
            <LoadingGenerator
              gridDist={[5.5, 5.5, 9]}
              loading={getDisposalRes.loading}
            />
            <div style={{ border: "none" }} className="disposal_filter_wrapper">
              <div className="disposal_title_col">
                <NewMultiDropDown
                  options={siteOptions}
                  selectedOptions={filter.siteId}
                  onSelect={(siteId) => {
                    updateFilter({ siteId });
                  }}
                  border={""}
                  placeholder={"Ieškoti..."}
                />
              </div>
              <div className="disposal_title_col">
                <NewMultiDropDown
                  options={wasteOptions}
                  selectedOptions={filter.wasteId}
                  onSelect={(wasteId) => {
                    updateFilter({ wasteId });
                  }}
                  border={""}
                  placeholder={"Ieškoti..."}
                />
              </div>
              <div className="disposal_vol_col">
                <div>
                  <span
                    className="sorting-link"
                    onClick={() => sortToggle("limitPctgAdjusted")}
                  >
                    Limito dalis, %
                  </span>
                </div>
                <div>
                  <span
                    className="sorting-link"
                    onClick={() => sortToggle("totalWeightAdjusted")}
                  >
                    Esamas svoris, t
                  </span>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="site_disposal_pane viewExt">
            {disposals
              ? disposals.map((siteDeliveryObj, index) => {
                  const siteName = Object.keys(siteDeliveryObj)[0];
                  const disposal = siteDeliveryObj[siteName];
                  return (
                    <div key={`site-waste-disposal-${index}`}>
                      <div className="site-wrapper">
                        <span>
                          <span className="icon-warning">
                            {siteTitle(disposal) && (
                              <img
                                src={WarningIcon}
                                alt=""
                                className="img_div_contain"
                              />
                            )}
                          </span>
                          {disposal.siteName}
                        </span>

                        <div className="flex1 flexEnd">
                          <button
                            type="button"
                            className={`btn ${
                              clientWastes[disposal.siteId] &&
                              clientWastes[disposal.siteId].length > 0
                                ? "btn-green"
                                : "btn-disabled"
                            }`}
                            onClick={() => selectSite(disposal)}
                          >
                            Formuoti užsakymą
                          </button>
                        </div>
                      </div>
                      <div className="waste-wrapper">
                        {disposal.wastes
                          ? sortWaste(disposal.wastes).map((delivery, n) => (
                              <div
                                key={`site-waste-disposal-item-${index}-${n}`}
                                className={`waste_container ${limitPctgColor(
                                  delivery
                                )} with-border`}
                              >
                                <div className="disposal_title_col">
                                  {disposal.siteName}
                                </div>
                                <div className="disposal_title_col">
                                  {delivery.wasteName}
                                </div>
                                <div className="disposal_vol_col">
                                  <div>
                                    {`${formatNumber(
                                      delivery.limitPctgAdjusted,
                                      false
                                    )} %`}
                                  </div>
                                  <div>
                                    <div
                                      className={`flex1 center weight_adjustment_wrapper`}
                                    >
                                      {delivery.totalWeight.toFixed(3)}
                                      &nbsp;|&nbsp;
                                      {editWeightAdjustment?.wasteId ==
                                        delivery.wasteId &&
                                      editWeightAdjustment.siteId ===
                                        disposal.siteId ? (
                                        <div className="input_wrapper flex">
                                          <span className="unit_title">kg</span>
                                          <InputNumber
                                            value={(
                                              delivery.totalWeightAdjusted *
                                              1000
                                            ).toFixed(0)}
                                            style={{}}
                                            onChange={() => {}}
                                            onBlur={(e: any) => {
                                              setEditWeightAdjustmentValue(
                                                e.target.value
                                              );
                                            }}
                                            className="weight_adjustment_input"
                                            placeholder=""
                                          />
                                        </div>
                                      ) : (
                                        delivery.totalWeightAdjusted.toFixed(3)
                                      )}
                                      {isAdmin ? (
                                        <IconContainer size="lg">
                                          {editWeightAdjustment?.wasteId ==
                                            delivery.wasteId &&
                                          editWeightAdjustment.siteId ===
                                            disposal.siteId ? (
                                            <TickLogo
                                              onClick={(e) =>
                                                setAdjustedWasteWeight(
                                                  delivery.siteId,
                                                  delivery.wasteId,
                                                  delivery.totalWeight,
                                                  delivery.totalWeightAdjusted
                                                )
                                              }
                                            />
                                          ) : (
                                            <EditLogo
                                              onClick={(e) => {
                                                setEditWeightAdjustment({
                                                  wasteId: delivery.wasteId,
                                                  siteId: disposal.siteId,
                                                });
                                                setEditWeightAdjustmentValue(
                                                  delivery.totalWeightAdjusted *
                                                    1000
                                                );
                                              }}
                                            />
                                          )}
                                        </IconContainer>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>

                                  <CheckBox
                                    isChecked={
                                      !!clientWastes[disposal.siteId] &&
                                      clientWastes[disposal.siteId].indexOf(
                                        delivery.id
                                      ) > -1
                                    }
                                    onChange={(e) =>
                                      clientWasteOnClick(
                                        e,
                                        disposal.siteId,
                                        delivery.id
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            ))
                          : ``}
                      </div>
                      <ExtraBar
                        totalCount={disposal.count}
                        currentAvailableCount={disposal.wastes.length}
                        expandFunc={() => {
                          viewMore(
                            disposal.siteId,
                            disposal.wastes.length +
                              min(disposal.count - disposal.wastes.length)
                          );
                        }}
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      <Notification
        reset={reset}
        isDone={disposalFormError || disposalFormSuccess}
        error={disposalFormError}
        text={
          disposalFormError || disposalFormSuccess ? (
            <div className={`DATASET__notification-text`}>
              {disposalFormSuccess ? (
                <>
                  <img
                    src={require("../../assets/images/tick.svg").default}
                    alt=""
                  />{" "}
                  Užsakymas atliekų išvežimui sukurtas sėkmingai
                </>
              ) : (
                <>Nepavyko sukurti atliekų išvežimo užsakymo</>
              )}
            </div>
          ) : undefined
        }
      />

      {isFormVisible && selectedDisposal && (
        <DisposalForm
          disposalError={saveDisposalRes.error}
          formData={formData}
          setFormData={setFormData}
          saveDisposal={saveDisposalRes.execute}
          loading={saveDisposalRes.loading}
          reload={saveDisposalRes.called && !!saveDisposalRes.data}
          sites={sites}
          clientWastes={clientWastes}
          companies={companies}
          selectedDisposal={selectedDisposal}
          nextFormNr={nextFormNr}
          onExit={disposalFormOnExit}
          carriers={carriers}
        />
      )}
    </div>
  );
};

export default Disposal;
