import { store } from "../..";
import * as actionTypes from "../actionTypes";

const getUserSiteQuery = () => {
  const id = store.getState()?.authReducer?.currentUser?.id || ``;
  if (id) {
    return `userId=${id}`;
  } else {
    return ``;
  }
};
export const getSites = function () {
  return { type: actionTypes.GET_SITES };
};
export const getUserSites = () => ({
  type: actionTypes.GET_USER_SITES,
  payload: getUserSiteQuery(),
});

export const getInactiveSites = () => ({
  type: actionTypes.GET_INACTIVE_SITES,
});

export const deactivateSite = (payload) => ({
  type: actionTypes.DEACTIVATE_SITE,
  payload,
});

export const hideSite = (payload) => ({
  type: actionTypes.HIDE_SITE,
  payload,
});

export const restoreSite = (payload) => ({
  type: actionTypes.RESTORE_SITE,
  payload,
});

export const createSite = (payload) => ({
  type: actionTypes.CREATE_SITE,
  payload,
});

export const editSite = (payload) => ({
  type: actionTypes.EDIT_SITE,
  payload,
});
