import React, { useEffect, useRef } from "react";
import { clientWasteType } from "../../../@types/waste";
import { formatNumber } from "../../../helpers";
import { isNumber, makeInt } from "../../../helpers/misc";
import { getClientWastesList } from "../../../pages/WasteAcceptance/defaultData";
import ExtraBar from "../../UI/ExtraBar/ExtraBar";
import "./WasteAcceptedList.css";

const WasteAcceptedList = ({
  clientWastes,
  clientId,
  isCompany,
  setClientWaste,
  clientWasteCount,
  setClientWasteCount,
  currentSiteId,
}: {
  clientWastes: clientWasteType[];
  clientId: number | null;
  isCompany: boolean;
  setClientWaste: (data: clientWasteType[]) => void;
  clientWasteCount: number;
  setClientWasteCount: (data: number) => void;
  currentSiteId: number;
}) => {
  const mountedRef = useRef(true);

  // Get client waste
  const getWasteList = async (params: {
    id: number;
    isCompany: boolean;
    limit: number;
    siteId: number;
  }) => {
    if (!params.id) return;
    getClientWastesList(params)
      .then((result) => {
        if (mountedRef.current) {
          setClientWaste(result?.clientWastes || []);
          setClientWasteCount(result?.count || 0);
        }
      })
      .catch((err) => {
        console.log(err);
        if (mountedRef.current) {
          setClientWaste([]);
          setClientWasteCount(0);
        }
      });
  };

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const min = (num: number) => {
    if (num > 100) {
      return 100;
    } else {
      return num;
    }
  };

  const maxCount = 5;

  return !clientId ||
    !clientWastes ||
    (clientWastes && clientWastes.length === 0) ? (
    <div />
  ) : (
    <div>
      <div className="wasteacceptance_title_header">Jau priimta</div>

      <div className="form_management_wrapper">
        <div className="form_management_container">
          <div className="fgHead limitia_box_tab waste_accpt_tab">
            <div>Kodas</div>
            <div>Atlieka</div>
            <div>Pristatymas</div>
            <div>Limitas</div>
          </div>
          <div className="limit_box_wrapper">
            {/* { uom, title*, total*, freeLimit*, unlimited } */}
            {clientWastes.map((record, n) => (
              <div key={n} className="fgRow limitia_box_tab waste_accpt_tab">
                <div className="limitia_box">{record.code}</div>
                <div className="limitia_box">{record.waste}</div>
                <div className="limitia_box">
                  <div className="limitia_box_progress">
                    <div className="limitia_box_progress_text">
                      {record.weight ? formatNumber(record.weight) : `0`} kg /{" "}
                      {record.freeLimitSourceId ? (
                        isNumber(record.freeLimit) ? (
                          `${formatNumber(record.freeLimit)} kg`
                        ) : (
                          <>&infin; kg</>
                        )
                      ) : (
                        <div className="blackLimit midAlign"></div>
                      )}
                    </div>
                    <div
                      style={{
                        width: `${min(
                          (makeInt(
                            record.groupId
                              ? record.groupTotalWeight || 0
                              : record.weight || 0
                          ) *
                            100) /
                            makeInt(
                              isNumber(record.freeLimit)
                                ? record.freeLimit || 0
                                : Infinity
                            )
                        )}%`,
                      }}
                      className="limitia_box_progress_handle"
                    ></div>
                    {(record.groupTotalWeight || 0) > (record.weight || 0) ? (
                      <div
                        style={{
                          width: `${min(
                            (makeInt(record.weight || 0) * 100) /
                              makeInt(
                                isNumber(record.groupTotalWeight)
                                  ? record.groupTotalWeight || 0
                                  : Infinity
                              )
                          )}%`,
                        }}
                        className="limitia_box_progress_handle2"
                      ></div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="limitia_box">
                  {record.freeLimitSourceId ? (
                    isNumber(record.freeLimit) ? (
                      `${formatNumber(record.freeLimit)} kg`
                    ) : (
                      <>&infin; kg</>
                    )
                  ) : (
                    <div className="blackLimit"></div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <ExtraBar
          currentAvailableCount={clientWastes.length}
          expandFunc={() => {
            getWasteList({
              isCompany,
              id: clientId,
              limit: clientWastes.length + maxCount,
              siteId: currentSiteId,
            });
          }}
          totalCount={clientWasteCount}
        />
      </div>
    </div>
  );
};

export default WasteAcceptedList;
