import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { defaultFormData, defaultFormError } from "./defaultData";
import { FeaturePermission, SiteSelection } from "..";

import "./style.scss";
import { Input } from "../../../../UI/FormGenerator/FormGenerator";
import DropDown from "../../../DropDown/DropDown";
import { defaultOption, rearrangeRole } from "../../../../../helpers/misc";
import { site } from "../../../../../@types/sites";
import { Features } from "../../../../../@types/feature";
import { option } from "../../../../UI/FormGenerator/formTypes";
import { userFormData, userGroup } from "../../../../../@types/user";
import NewPopUpFrame from "../../../../UI/PopupFrame/NewPopUpFrame";

const activeOptions = [
  { label: "Aktyvi", value: 1 },
  { label: "Neaktyvi", value: 2 },
];

export type userData = Omit<Partial<userFormData>, "sites"> & {
  sites: number[];
};

const UserForm = function ({
  sites,
  features,
  roles,
  userGroups,
  id,
  saveUser,
}: {
  sites: site[];
  features: Features[];
  roles: { id: number; name: string }[];
  userGroups: userGroup[];
  id: number;
  saveUser: (type: "edit" | "new", data: userData) => void;
}) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<
    Omit<Partial<userFormData>, "id"> & { id: number }
  >({
    ...defaultFormData,
    id,
  });
  const [formError, setFormError] = useState<{
    [key in keyof userFormData]?: boolean;
  }>({ ...defaultFormError });
  const [roleOptions, setRoleOptions] = useState<option[]>([]);
  const [userGroupOptions, setUserGroupOptions] = useState<option[]>([]);
  const [siteSelectionShown, setSiteSelectionShown] = useState(false);
  const [featurePermissionShown, setFeaturePermissionShown] = useState(false);
  const [paswdConfirm, setPaswdConfirm] = useState("");

  useEffect(() => {
    if (roles && roles.length > 0) {
      const _options = rearrangeRole(
        roles.map((role) => {
          return {
            label: role.name,
            value: role.id,
          };
        })
      );

      setRoleOptions(_options);
    }
  }, [roles]);

  useEffect(() => {
    if (userGroups && userGroups.length > 0) {
      const _options = userGroups.map((userGroup) => {
        return {
          label: userGroup.name,
          value: userGroup.id,
        };
      });

      setUserGroupOptions(_options);
    }
  }, [userGroups]);

  const updateFormData = (objData: Partial<userFormData>) => {
    const _formError = { ...formError };

    for (const k in objData) {
      const key = k as keyof userFormData;
      _formError[key] = false;
    }

    setFormData({ ...formData, ...objData });
    setFormError(_formError);
  };

  const isActiveChanged = (status: "active" | "inactive") => {
    const isActive = status === "active";
    updateFormData({ isActive });
  };

  const errorDetails = (
    keys: (keyof userFormData)[] = [
      "id",
      "uname",
      "paswd",
      "firstName",
      "lastName",
      "roleId",
    ]
  ) => {
    let error: Partial<typeof formError> = {};

    keys.forEach((key) => {
      const value = formData[key];
      if (key === "paswd") {
        if (formData.paswd !== paswdConfirm || !formData.paswd) {
          error = { ...error, [key]: true };
        }
      } else {
        if (!value) {
          error = { ...error, [key]: true };
        }
      }
    });

    error = { ...error };

    const hasError = Object.keys(error).some((k) => {
      const key = k as keyof userFormData;
      return !!error[key];
    });

    return { hasError, error };
  };

  const showSiteSelection = () => {
    const { hasError, error } = errorDetails();
    if (hasError) {
      setFormError({ ...error });
      return;
    } else {
      setSiteSelectionShown(true);
    }
  };

  const compKeys: (keyof userFormData)[] = [
    "firstName",
    "id",
    "lastName",
    "paswd",
    "roleId",
    "sites",
  ];

  const showFeaturePermission = () => {
    const { hasError } = errorDetails(compKeys);

    if (hasError) {
      return;
    } else {
      setFeaturePermissionShown(true);
      setSiteSelectionShown(false);
    }
  };

  const getValue = (id: number | undefined, list: option[]) => {
    try {
      let list2 = Array.isArray(list) ? list : [];
      if (typeof id !== `number` || id < 1) {
        return defaultOption;
      } else {
        const result = list2.filter((item) => item.value === id)[0];
        if (result) {
          return {
            label: result.label,
            value: result.value,
          };
        } else {
          return defaultOption;
        }
      }
    } catch {
      return defaultOption;
    }
  };

  const actualSave = () => {
    const { hasError } = errorDetails(compKeys);
    if (hasError) {
      return;
    } else {
      // Reconvert sites to array of ids
      const data: userData = {
        ...formData,
        sites: (formData.sites || []).map((site) => site.id),
      };
      saveUser("new", data);
      setFeaturePermissionShown(false);
      setTimeout(() => {
        navigate("/settings/staff");
      }, 1000);
    }
  };

  const isValid = () => {
    if (formData.paswd && paswdConfirm && formData.paswd !== paswdConfirm) {
      return false;
    }

    return true;
  };

  return (
    <>
      <form>
        <div className="user-form user_form_wrapper">
          <div className="user_form_record">
            <div className="user_form_header">Darbuotojo slapyvardis</div>
            <div className="user_form_header split">
              <span>Vardas</span>
              <span>Pavardė</span>
            </div>
            <div className="user_form_header">
              Darbuotojo tipas sistemoje (rolė)
            </div>
            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ uname: value })}
                  type={"text"}
                  value={formData.uname || ""}
                  placeholder={"Darbuotojo slapyvardis"}
                  error={!!formError.uname}
                />
              </div>
            </div>

            <div className="user_form_container split">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ firstName: value })}
                  type={"text"}
                  value={formData.firstName || ""}
                  placeholder={"Vardas"}
                  error={!!formError.firstName}
                />
              </div>
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ lastName: value })}
                  type={"text"}
                  value={formData.lastName || ""}
                  placeholder={"Pavardė"}
                  error={!!formError.lastName}
                />
              </div>
            </div>

            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <DropDown
                  options={roleOptions}
                  onSelect={(selectedVal) =>
                    updateFormData({ roleId: selectedVal.value })
                  }
                  value={getValue(formData.roleId, roleOptions)}
                  border={""}
                  title={"Pasirinkti..."}
                  error={!!formError.roleId}
                />
              </div>
            </div>
          </div>
          <div className="user_form_record">
            <div className="user_form_header">Naujas slaptažodis</div>

            <div className="user_form_header">El. pašto adresas</div>

            <div className="user_form_header">Darbo grupė</div>

            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ paswd: value })}
                  type={"password"}
                  value={formData.paswd || ""}
                  placeholder={"Naujas slaptažodis"}
                  error={!isValid() || !!formError.paswd}
                />
              </div>
            </div>
            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ email: value })}
                  type={"text"}
                  value={formData.email || ""}
                  placeholder={"El. pašto adresas"}
                  error={false}
                />
              </div>
            </div>
            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <DropDown
                  options={userGroupOptions}
                  value={getValue(formData.userGroupId, userGroupOptions)}
                  onSelect={(selected) =>
                    updateFormData({ userGroupId: selected.value })
                  }
                  border={""}
                  title={"Ieškoti..."}
                  error={false}
                />
              </div>
            </div>
          </div>

          <div className="user_form_record">
            <div className="user_form_header">
              Patvirtinkite įvestą slaptažodį
            </div>
            <div className="user_form_header">Telefono nr.</div>
            <div className="user_form_header"></div>
            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => setPaswdConfirm(value)}
                  type={"password"}
                  value={paswdConfirm}
                  placeholder={"Patvirtinkite įvestą slaptažodį"}
                  error={!isValid()}
                />
              </div>
            </div>

            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ phone: value })}
                  type={"number"}
                  value={formData.phone || ""}
                  placeholder={"Tel. nr."}
                  error={false}
                />
              </div>
            </div>

            <div className="user_form_container"></div>
          </div>
          <div className="user_form_record">
            <div className="user_form_header">Būsena</div>
            <div className="user_form_header">Pareigos</div>
            <div className="user_form_header"></div>
            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <DropDown
                  options={activeOptions}
                  value={
                    formData.isActive ? activeOptions[0] : activeOptions[1]
                  }
                  onSelect={(selected) =>
                    isActiveChanged(
                      selected.value === 1 ? "active" : "inactive"
                    )
                  }
                  border={""}
                  title={""}
                  error={false}
                />
              </div>
            </div>

            <div className="user_form_container">
              <div className="user_form_container_input_div">
                <Input
                  handler={(value) => updateFormData({ position: value })}
                  type={"text"}
                  value={formData.position || ""}
                  placeholder={"Pareigos"}
                  error={false}
                />
              </div>
            </div>

            <div className="user_form_container split"></div>
          </div>
          <div className="user_form_btn_container">
            <div className="actions">
              <button type="button" className="btn btn-custom">
                Atšaukti
              </button>
              <button
                type="button"
                className="btn btn-green"
                onClick={() => showSiteSelection()}
              >
                Toliau
              </button>
            </div>
          </div>
        </div>
      </form>

      {siteSelectionShown && (
        <NewPopUpFrame
          isShown={siteSelectionShown}
          handleOpen={setSiteSelectionShown}
          title={"AIKŠTELĖS"}
          showShadow={false}
        >
          <SiteSelection
            sites={sites}
            formData={formData}
            onCancel={() => setSiteSelectionShown(false)}
            onNext={() => showFeaturePermission()}
            updateFormData={updateFormData}
          />
        </NewPopUpFrame>
      )}

      {featurePermissionShown && (
        <NewPopUpFrame
          classNameWrap="feature_popup_wrap"
          isShown={featurePermissionShown}
          handleOpen={setFeaturePermissionShown}
          title={"DARBUOTOJO PRIEIGOS VALDYMAS"}
          showShadow={false}
        >
          <FeaturePermission
            features={features}
            formData={formData}
            onCancel={() => setFeaturePermissionShown(false)}
            updateFormData={updateFormData}
            onSave={() => actualSave()}
          />
        </NewPopUpFrame>
      )}
    </>
  );
};

export default UserForm;
