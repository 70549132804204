import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  defaultOption,
  isDigit,
  isLTNum,
  rearrangeMunic,
  shortName,
} from "../../../helpers/misc";
import { ReactComponent as LT } from "../../../assets/images/LT.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeSVG.svg";
import { person } from "../../../@types/client";
import {
  documentType,
  municipalityType,
  // person,
  personType,
} from "../../../pages/WasteAcceptance/@types";
import {
  childField,
  dropField,
  Header,
  textField,
} from "../../UI/FormGenerator/formField";
import FormGenerator, { Input } from "../../UI/FormGenerator/FormGenerator";
import { option, pageConstructType } from "../../UI/FormGenerator/formTypes";

import "./AcceptancePerson.css";
import { filter_person_id } from "../../../pages/WasteAcceptance/WasteAcceptance";
import {
  extPerson,
  getFilterPersons,
  getPersonValue,
  nullPartial,
} from "../../../pages/WasteAcceptance/defaultData";
import { useDelay } from "../../../helpers/useDelay";

const size7 = [1, 1, 1.5, 1, 1.25, 1, 1.5];

const AcceptancePerson = ({
  details,
  setDetails,
  documents,
  municipalities,
  persons,
  setSearchParams,
  searchParams,
  setPersons,
  personStandard,
  setPersonStandard,
  isComplete,
}: {
  details: personType;
  setDetails: (data: nullPartial<personType>) => void;
  documents: documentType[];
  municipalities: municipalityType[];
  persons: extPerson[];
  setSearchParams: (
    data: Partial<{ name: string; surname: string; pin: number }>
  ) => void;
  searchParams: Partial<{ name: string; surname: string; pin: number }>;
  setPersons: (data: extPerson[]) => void;
  personStandard: person | undefined;
  setPersonStandard: (data: person) => void;
  isComplete: boolean;
}) => {
  const mountedRef = useRef(true);
  const [searching, setSearching] = useState(false);
  const [documentOptions, setDocumentOptions] = useState<option[]>([]);
  const [municipalityOptions, setMunicipalityOptions] = useState<option[]>([]);

  const sTottle = useDelay({ value: searching, time: 500 });

  useEffect(() => {
    // useEffect for setting filtered persons

    mountedRef.current = true;
    const shouldSearch = Object.keys(searchParams).some(
      (param) => !!searchParams[param as keyof typeof searchParams]
    );
    if (shouldSearch) {
      setSearching(true);
      getFilterPersons(searchParams).then(
        ({ persons }) => {
          if (mountedRef.current) {
            setSearching(false);
            setPersons([...persons]);
          }
        },
        (error) => {
          if (mountedRef.current) {
            setSearching(false);
            setPersons([]);
          }
        }
      );
    } else {
      setPersons([]);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [searchParams]);

  useEffect(() => {
    // useEffect for clearing the search params when outside the list container is clicked
    const outsideClick = (e: MouseEvent) => {
      const element = document.getElementById(filter_person_id);
      if (element && !element.contains(e.target as Node)) {
        setSearchParams({});
      }
    };

    document.addEventListener("click", outsideClick);
    return () => {
      document.removeEventListener("click", outsideClick);
    };
  }, []);

  useEffect(() => {
    // This useEffect hook is for getting and setting the ID of the person
    mountedRef.current = true;

    // If the standard differs in more than 2 positions, remove ID
    // We are not clearing standard because it just a cache and the edit could be a mistake and
    // be corrected
    const name = details.name;
    const surname = details.surname;
    const pin = details.pin;
    if (
      // If there is a standard and we have at least two matches (including that of the pin)
      personStandard &&
      (name === personStandard.name || surname === personStandard.surname) &&
      pin === personStandard.pin
    ) {
      if (details.id !== personStandard.id) {
        set({ id: personStandard.id });
      }
    } else {
      /*
        If the above is not true, try to get the Id when the details are complete
        Or set the id to null pending when the details are complete
      */
      if (isComplete) {
        // Remove searchParams if all the fields are filled
        setSearchParams({});
        /* 
          If there is no ID and we have enough details, 
          get the ID of the user
        */
        if (!details.id) {
          getFilterPersons({
            name: details.name,
            surname: details.surname,
            pin: details.pin as unknown as number,
            strict: true,
          }).then(({ persons }) => {
            if (mountedRef.current) {
              if (persons && persons.length) {
                const person = persons[0];
                // set a standard here
                // We need to be aware of if it was a clicked standard or not.
                setPersonStandard(person);
                set({
                  id: person.id,
                });
              }
            }
          });
        } else {
          /* 
        Remove ID if it is not inline with the standard and there exist an unknown ID
        This is to ensure that on rerunning it,a fresh ID can be set if a familiar record is found
        
        Remove ID only if there is an already existing ID to prevent multiple rerenders
        */
          set({ id: null });
        }
      } else {
        /* 
        Only make hidden queries when all the fields are filled.
        If all fields are not filled, set id to null
        The ID will eventually be set when fields are complete
        */
        if (details.id) {
          // Remove ID only if there is an already existing ID to prevent multiple rerenders
          set({ id: null });
        }
      }
    }
    return () => {
      mountedRef.current = false;
    };
  }, [details.toString(), personStandard, isComplete]);

  const munic_value = useCallback(
    (id: number): option => {
      if (id) {
        const municipality = municipalityOptions.find(
          (munic) => munic.value === id
        );
        if (municipality) {
          return municipality;
        }
      }
      return defaultOption;
    },
    [details.municipalityId, municipalityOptions]
  );

  const doc_value = useMemo((): option => {
    if (details.documentId) {
      const Doc = documentOptions.find(
        (doc) => doc.value === details.documentId
      );
      if (Doc) {
        return Doc;
      }
    }
    return defaultOption;
  }, [details.documentId]);

  const set = (obj: Partial<nullPartial<personType>>) => {
    setDetails({ ...details, ...obj });
  };

  const setDocValue = async (docId: number) => {
    const id = personStandard?.id;

    if (id) {
      getPersonValue({ id })
        .then(({ client }) => {
          if (mountedRef.current) {
            const doc = client.PersonDocuments.find(
              (d) => d.documentId === docId
            );
            if (doc) {
              set({ documentNr: doc.documentNr, documentId: docId });
            } else {
              if (mountedRef.current) {
                set({ documentNr: "", documentId: docId });
              }
            }
          }
        })
        .catch((err) => {
          if (mountedRef.current) {
            set({ documentNr: "", documentId: docId });
          }
          console.log(err);
        });
    }
  };

  /**
   *
   * @param docId The ID of the Document Type
   * @returns A boolean. Only Permission or Permission_temp documents should receive the LT svg
   */
  const docType = (docId: personType["documentId"]): boolean => {
    if (docId) {
      const doc = documents.find((doc) => doc.id === Number(docId));
      if (
        doc &&
        (doc.type === "permission" || doc.type === "permission_temp")
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (documents.length) {
      const options = documents.map((doc) => ({
        value: doc.id,
        label: doc.name,
      }));
      setDocumentOptions([...options]);
    }
    if (municipalities.length) {
      const options = rearrangeMunic(
        municipalities.map((munic) => ({
          value: munic.id,
          label: shortName(munic.name),
        }))
      );
      setMunicipalityOptions([...options]);
    }
  }, [documents, municipalities]);

  const headConstruct: pageConstructType = {
    sizeDist: size7,
    typeDist: [],
    hasHeader: true,
    headerText: [
      Header(["Vardas"], false),
      Header(["Pavardė"], false),
      Header(["Asmens dokumentas"], false),
      Header(["Dokumento nr."], false),
      Header(["4 paskutiniai a. k. sk."], false),
      Header(["Savivaldybė"], false),
      Header(["Adresas"], false),
    ],
  };

  const rowConstruct = [
    {
      sizeDist: size7,
      typeDist: [
        textField(
          "",
          false,
          () => {},
          details.name,
          (name) => {
            set({ name });
            setSearchParams({
              name: name && name.length > 2 ? name : undefined,
            });
          }
        ),
        textField(
          "",
          false,
          () => {},
          details.surname,
          (surname) => {
            set({ surname });
            setSearchParams({
              surname: surname && surname.length > 2 ? surname : undefined,
            });
          }
        ),
        dropField(
          documentOptions,
          "",
          false,
          doc_value,
          (docType) => {
            set({
              documentId: docType,
            });

            setDocValue(docType);
          },
          true
        ),
        childField(
          <div
            className={`documentNr_accpt_wrapper center ${
              docType(details.documentId) ? "ltLogo" : ""
            }`}
            key={1}
          >
            {docType(details.documentId) ? (
              <div className="lt_wrapper">
                <LT className="img_div_contain" />
              </div>
            ) : (
              ""
            )}
            <Input
              value={details.documentNr}
              type="number"
              placeholder=""
              disabled={!details.documentId}
              handler={() => {}}
              error={false}
              externalValue={details.documentNr || ""}
              setExternalValue={(documentNr) => {
                set({ documentNr });
              }}
            />
          </div>
        ),
        textField(
          "",
          false,
          () => {},
          `${details.pin}`,
          (pin) => {
            if (isLTNum(pin) && isDigit(pin)) {
              if (pin.toString().length > 4) {
                return;
              }
              set({ pin });
              setSearchParams({
                pin: pin.length > 2 ? parseInt(pin) || undefined : undefined,
              });
            }
          }
        ),
        dropField(
          municipalityOptions,
          "",
          false,
          munic_value(details.municipalityId),
          (municipalityId) => {
            set({
              municipalityId,
            });
          },
          true
        ),
        textField(
          "",
          false,
          () => {},
          details.address,
          (address) => {
            set({ address });
          }
        ),
      ],
      hasHeader: false,
      headerText: [],
    },
  ];

  return (
    <div className="acceptanceForm_wrapper accpt_person">
      {sTottle || persons.length ? (
        <div className="waste_person_filter">
          <div className="waste_person_filter_container">
            <div className="inner" id={filter_person_id}>
              {sTottle ? (
                <></>
              ) : (
                <div
                  onClick={() => {
                    setSearchParams({});
                    setPersons([]);
                  }}
                  className="close_person_filter center"
                >
                  <CloseIcon className="img_div_contain" />
                </div>
              )}

              {persons.length && !sTottle ? (
                (persons || []).map((person, n) => (
                  <div
                    className="filter_item"
                    key={n}
                    onClick={() => {
                      setSearchParams({});
                      setPersons([]);
                      set({
                        id: person.id,
                        name: person.name,
                        surname: person.surname,
                        documentId: person.PersonDocuments.length
                          ? person.PersonDocuments[
                              person.PersonDocuments.length - 1
                            ].documentId
                          : null,
                        documentNr: person.PersonDocuments.length
                          ? person.PersonDocuments[
                              person.PersonDocuments.length - 1
                            ].documentNr
                          : null,
                        pin: person.pin,
                        address: person.address,
                        email: person.email,
                        municipalityId: person.municipalityId,
                      });
                      setPersonStandard(person);
                    }}
                  >
                    {person.name} {person.surname}
                  </div>
                ))
              ) : (
                <div className="filter_item" style={{ fontStyle: "italic" }}>
                  Ieškoma...
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ``
      )}
      {[
        <FormGenerator
          key={`acceptancePerson_headerField_1_${0}`}
          gridSizeDist={headConstruct.sizeDist}
          gridTypeDist={headConstruct.typeDist}
          hasHeader={headConstruct.hasHeader}
          headerText={headConstruct.headerText}
        />,
      ]}
      {rowConstruct.map((construct, n) => (
        <FormGenerator
          key={`acceptancePerson_rowField_1_${n}`}
          gridSizeDist={construct.sizeDist}
          gridTypeDist={construct.typeDist}
          hasHeader={construct.hasHeader}
          headerText={construct.headerText}
        />
      ))}
    </div>
  );
};

export default AcceptancePerson;
