import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { company } from "../../@types/client";
import { useQuery } from "../../App";
import { Input } from "../../components/UI/FormGenerator/FormGenerator";
import Notification from "../../components/UI/Notification/Notification";
import NewPopUpFrame from "../../components/UI/PopupFrame/NewPopUpFrame";
import { useAsync } from "../../helpers/asyncFunc";
import { STATUS_DONE, STATUS_IN_PROGRESS } from "../../statuses";
import Contract from "../Contract/Contract";
import Limitia from "../Limitia/Limitia";
import { fetchClient } from "../Profile/getter";
import { DebtPanel } from "../Profile/Profile";
import ProfileMessageBox from "../ProfileMessage/ProfileMessageBox";
import ProfileRecords from "../ProfileRecords/ProfileRecords";
import "./CompanyProfile.css";

export default function CompanyProfile({
  saveCompany,
  savingStatus,
}: {
  saveCompany: (company: company) => void;
  savingStatus: number;
}) {
  const query = useQuery();
  const id = Number(query.get("id")) || null;

  const mountedRef = useRef(true);
  const navigate = useNavigate();
  const parentUrl = "/settings/clients";

  const [formData, setFormData] = useState<company>();
  const [debtPopUpVisibility, setDebtPopUpVisibility] = useState(false);
  const [changed, setChanged] = useState<boolean>(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [mailSendSuccess, setMailSendSuccess] = useState(false);
  const [hardReminder, setHardReminder] = useState(false);

  useEffect(() => {
    mountedRef.current = true;
    const isDone = savingStatus === STATUS_DONE;
    const inProgress = savingStatus === STATUS_IN_PROGRESS;
    if (inProgress) {
      setChanged(false);
    } else if (isDone) {
      setChanged(false);

      setEditSuccess(true);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [savingStatus]);

  const profileRes = useAsync({
    asyncFunc: fetchClient,
    funcParams: { id, isCompany: true },
    immediate: true,
    clearOnError: true,
  });

  useEffect(() => {
    const profileData = profileRes.data;

    if (profileData) {
      setFormData(profileData.client as company);
    }
  }, [profileRes.data]);

  useEffect(() => {
    const profileError = profileRes.error;

    if (profileError) {
      navigate(parentUrl);
    }
  }, [profileRes.error]);

  const updateFormData = <T extends Partial<company> = Partial<company>>(
    objData: T
  ) => {
    if (formData) {
      setChanged(true);
      const _formData = { ...formData };

      for (const key in objData) {
        (_formData as T)[key as keyof T] = objData[key];
      }
      setFormData(_formData);
    }
  };

  const resetEditStatus = () => {
    setEditSuccess(false);
  };

  const resetMailStatus = () => {
    setMailSendSuccess(false);
  };

  return formData ? (
    <div className="profile">
      <Notification
        reset={resetEditStatus}
        isDone={editSuccess}
        error={false}
        text="Pakeitimai įmonės paskyroje sėkmingai išsaugoti"
      />
      <Notification
        reset={resetMailStatus}
        isDone={mailSendSuccess}
        error={false}
        text="Priminimas sėkmingai išsiųstas"
      />
      {debtPopUpVisibility && (
        <NewPopUpFrame
          showShadow={false}
          handleOpen={setDebtPopUpVisibility}
          isShown={debtPopUpVisibility}
          title={hardReminder ? "Griežtas priminimas" : "Priminimas"}
        >
          <ProfileMessageBox
            onCancel={() => setDebtPopUpVisibility(false)}
            onContinue={() => {
              setDebtPopUpVisibility(false);
              setMailSendSuccess(true);
            }}
            defEmail={formData.email}
            hard={hardReminder}
          />
        </NewPopUpFrame>
      )}
      <>
        <div className="profile_wrapper">
          <div className="profile_header_wrapper">
            <div>
              <h3>Kliento ID {formData.uniqueId || `---`}</h3>
            </div>
            <div>
              <h5>
                Paskyra sukurta{" "}
                {moment(formData.createdAt).format(`YYYY-MM-DD HH:mm`)}
              </h5>
            </div>
          </div>
          <div className="profile_container">
            <form className="profile_form">
              <div className="profile_form_wrapper">
                <div className="profile_form_record">
                  <div className="profile_form_header">Įmonės pavadinimas</div>
                  <div className="profile_form_header split">Telefono nr.</div>

                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) =>
                          updateFormData({ companyName: value })
                        }
                        type={"text"}
                        value={formData.companyName}
                        placeholder={"Įmonės pavadinimas"}
                        error={false}
                      />
                    </div>
                  </div>
                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => updateFormData({ mobile: value })}
                        type={"number"}
                        value={formData.mobile || ""}
                        placeholder={"Tel. nr."}
                        error={false}
                      />
                    </div>
                  </div>
                </div>

                <div className="profile_form_record">
                  <div className="profile_form_header">Įmonės kodas</div>
                  <div className="profile_form_header">El. p. adresas</div>
                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) =>
                          updateFormData({ companyCode: value })
                        }
                        type={"text"}
                        value={formData.companyCode}
                        placeholder={"Įmonės kodas"}
                        error={false}
                      />
                    </div>
                  </div>

                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => updateFormData({ email: value })}
                        type={"text"}
                        value={formData.email || ""}
                        placeholder={"El. p. adresas"}
                        error={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="profile_form_record">
                  <div className="profile_form_header">Adresas</div>
                  <div className="profile_form_header">PVM mokėtojo kodas</div>
                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) => updateFormData({ address: value })}
                        type={"text"}
                        value={formData.address || ""}
                        placeholder={"Adresas"}
                        error={false}
                      />
                    </div>
                  </div>

                  <div className="profile_form_container">
                    <div className="profile_form_container_input_div">
                      <Input
                        handler={(value) =>
                          updateFormData({
                            vatcode: value as unknown as number,
                          })
                        }
                        onlyDigit={true}
                        type={"number"}
                        value={`${formData.vatcode || ``}`}
                        placeholder={"PVM mokėtojo kodas"}
                        error={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="profile_debt_wrapper">
              <div className="profile_debt_title">
                <p>Įsiskolinimai</p>
              </div>
              <DebtPanel
                isCompany={true}
                formData={formData}
                reload={() => {
                  profileRes.execute({ id, isCompany: true });
                }}
              />

              <div className="profile_debt_label">
                Informuoti apie įsiskolinimą
              </div>
              <div className="profile_debt_btn_wrapper">
                <button
                  onClick={() => {
                    setDebtPopUpVisibility(true);
                    setHardReminder(false);
                  }}
                  className="flexBtn btn-orange"
                >
                  Priminimas
                </button>
                <button
                  onClick={() => {
                    setDebtPopUpVisibility(true);
                    setHardReminder(true);
                  }}
                  className="flexBtn btn-red"
                >
                  Griežtas priminimas
                </button>
              </div>
            </div>
          </div>
          <div className="profile_btn_wrapper">
            <button
              type="button"
              className="flexBtn btn-default profile_btn"
              onClick={() => {
                navigate("/settings/clients");
              }}
            >
              Atšaukti
            </button>
            <button
              type="button"
              className="flexBtn btn-green profile_btn"
              disabled={!changed}
              onClick={() => {
                if (changed) {
                  saveCompany(formData);
                }
              }}
            >
              Išsaugoti
            </button>
          </div>
        </div>
        <ProfileRecords isCompany={true} />
        <Contract clientId={formData.id} />
        <Limitia isCompany={true} />
      </>
    </div>
  ) : (
    <div />
  );
}
