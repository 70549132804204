import React, { useEffect, useMemo, useRef, useState } from "react";
import CheckBox from "../../UI/checkBox/checkBox";
import {
  childField,
  dropField,
  Header,
  textField,
} from "../../UI/FormGenerator/formField";
import { ReactComponent as InfoSVG } from "../../../assets/images/info.svg";
import FormGenerator, {
  GenerateForm,
  Input,
} from "../../UI/FormGenerator/FormGenerator";
import {
  gridType,
  option,
  pageConstructType,
} from "../../UI/FormGenerator/formTypes";
import IconContainer from "../../UI/iconContainer/iconContainer";

import "./AcceptanceForm.css";
import { originType } from "../../../pages/WasteAcceptance/@types";
import {
  wasteItem,
  wasteItem_init,
} from "../../../pages/WasteAcceptance/defaultData";
import moment from "moment";
import {
  primarySource,
  siteWasteType,
  wasteActivityTypeType,
  wasteType,
} from "../../../@types/waste";
import {
  getCombinedWastes,
  getSiteAvailableWastes,
  getSiteWastes,
  getTranstionDeficit,
} from "./helper";
import { useAsync } from "../../../helpers/asyncFunc";
import { defaultOrigin } from "../../../pages/WasteAcceptance/WasteAcceptance";
import {
  getWastePrice,
  isNumber,
  resolve_unit_price,
  transform,
} from "../../../helpers/misc";
import { connect } from "react-redux";

const size5 = [4, 3, 4, `5rem`, `3rem`];
const size8 = [3, 2, 2, 2, 2, 2, `4.5rem`, `2.5rem`];
const warningClass = {
  neutral: `amt_neutral`,
  good: `amt_good`,
  warning: `amt_warning`,
  danger: `amt_danger`,
  accumWarning: `amt_accumWarning`,
};

const AcceptanceFields = ({
  siteId,
  isCompany,
  siteWastes,
  origins,
  primarySources,
  wasteActivityTypes,
  date,
  completeDetails,
  waste_list,
  setWaste_list,
  id: personId,
  fromDisposal,
  wastes,
}: {
  siteId: number;
  isCompany: boolean;
  siteWastes: siteWasteType[];
  date: Date;
  origins: originType[];
  primarySources: primarySource[];
  wasteActivityTypes: wasteActivityTypeType[];
  completeDetails: boolean;
  waste_list: Partial<wasteItem>[];
  setWaste_list: (data: Partial<wasteItem>[]) => void;
  id: null | number;
  fromDisposal: boolean;
  wastes: wasteType[];
}) => {
  const defaultOption = { label: "", value: 0 };
  const indicatorID = "accpt__float_indicator_ID";

  const [activeRow, setActiveRow] = useState<number | undefined>(0);
  const [personCombinedWaste, setPersonCombinedWaste] = useState<{
    [wasteId: number | string]: number;
  }>({});
  const [combinedWeights, setCombinedWeights] = useState<{
    [wasteId: number | string]: number;
  }>({});
  const [wasteOptions, setWasteOptions] = useState<option[]>([]);
  const [originOptions, setOriginOptions] = useState<option[]>([]);
  const [wasteActivityTypeOptions, setWasteActivityTypeOptions] = useState<
    option[]
  >([]);
  const [primarySourceOptions, setPrimarySourceOptions] = useState<option[]>(
    []
  );

  const defaultOriginId = useMemo(() => {
    const origin = origins.find(
      (item) => item.name.toLowerCase() === defaultOrigin.toLowerCase()
    );
    return origin?.id;
  }, [origins]);

  const combineWeightsRes = useAsync(
    {
      asyncFunc: getSiteAvailableWastes,
      funcParams: { siteId },
      immediate: true,
    },
    [siteId]
  );
  useEffect(() => {
    const weightObj = combineWeightsRes.data;
    if (weightObj) {
      setCombinedWeights(weightObj.remainingWeights);
    }
  }, [combineWeightsRes.data]);

  const mountedRef = useRef(true);
  useEffect(() => {
    //Get combined person waste
    mountedRef.current = true;
    if (!personId) {
      setPersonCombinedWaste({});
    }

    getCombinedWastes({ clientId: personId || ``, isCompany }).then(
      (res) => {
        if (mountedRef.current) {
          if (res?.combinedWastes) {
            setPersonCombinedWaste({ ...res.combinedWastes });
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    return () => {
      mountedRef.current = false;
    };
  }, [personId, isCompany]);

  useEffect(() => {
    if (!fromDisposal && siteWastes) {
      const options = siteWastes.map((opt) => ({
        value: opt.wasteId,
        label: `${opt.Waste.code ? `${opt.Waste.code} - ` : ""}${
          opt.Waste.waste
        }`,
      }));

      setWasteOptions([...options]);
    }
  }, [siteWastes]);

  useEffect(() => {
    if (fromDisposal && wastes) {
      const options = wastes.map((opt) => ({
        value: opt.id,
        label: `${opt.code ? `${opt.code} - ` : ""}${opt.waste}`,
      }));

      setWasteOptions([...options]);
    }
  }, [wastes]);

  useEffect(() => {
    if (wasteActivityTypes) {
      const options = wasteActivityTypes.map((waTypes) => ({
        value: waTypes.id,
        label: waTypes.name,
      }));
      setWasteActivityTypeOptions([...options]);
    }
  }, [wasteActivityTypes]);

  useEffect(() => {
    if (primarySources) {
      const options = primarySources.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));
      setPrimarySourceOptions([...options]);
    }
  }, [primarySources]);

  useEffect(() => {
    if (origins) {
      const options = origins.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));
      setOriginOptions([...options]);
    }
  }, [origins]);

  const Key = (item: Partial<wasteItem>) =>
    item.selectedWaste || item.groupId
      ? item.groupId
        ? `group_${item.groupId}`
        : item.selectedWaste
      : undefined;

  type status = {
    neutral: boolean;
    good: boolean;
    warning: boolean;
    danger: boolean;
    accumWarning: boolean;
  };
  const getWasteInfo = (index?: number) => {
    const Status = (data: Partial<status> = { neutral: true }): status => {
      const defaultStatus = {
        neutral: false,
        good: false,
        warning: false,
        danger: false,
        accumWarning: false,
      };
      return { ...defaultStatus, ...data };
    };
    const normalize_to_zero = (value: number) => {
      if (value >= 0) {
        return Number(value.toFixed(3));
      } else {
        return 0;
      }
    };

    // The previous collections (from past visits to sites) will be included in this Total initialization
    // Total is an object with key as the id of the waste and value as the amount collected already for that year
    const Total = { ...personCombinedWaste };
    const List: Partial<wasteItem>[] = [];
    let info:
      | (Partial<wasteItem> & {
          quantPay: number;
          status: status;
        })
      | undefined = undefined;

    for (let n = 0; n < waste_list.length; n++) {
      const item = waste_list[n];

      const key = Key(item);

      const total = key ? Total[key] || 0 : 0;
      const siteWeight = key ? combinedWeights[key] || 0 : 0;

      const annualDelivery = key ? personCombinedWaste[key] || 0 : 0;

      /**
       * Freelimit should be either the group limit or the waste limit
       * The items should be accumulated as normal and before the status is confirmed,
       * Make an aggregation based on the groupId if there is any.
       * With the new totalReached, update the status
       */

      const fLimit =
        item.freeLimit || typeof item.freeLimit === `number`
          ? parseFloat(`${item.freeLimit}`) || 0
          : Infinity; // If null, free limit is infinity

      const mLimit =
        item.maxLimit || typeof item.maxLimit === `number`
          ? parseFloat(`${item.maxLimit}`) || 0
          : Infinity; // If null, max limit is infinity

      const accumLimit =
        item.accumulationLimit || typeof item.accumulationLimit === `number`
          ? parseFloat(`${item.accumulationLimit}`) || 0
          : Infinity; // If null, accum limit is infinity

      const total_reached = (parseFloat(`${item.weight}`) || 0) + total;
      const localTotalReached = total_reached - annualDelivery;
      // If localTotalReached + available in site is more than the accum limit
      // set the warning. Note that warning setting will only correspond when max limit does not apply
      const potentialSiteWeight = siteWeight + localTotalReached;
      const surpasedAccumLimit = potentialSiteWeight >= accumLimit;

      const current_payable = normalize_to_zero(total_reached - fLimit);
      const prev_payable = normalize_to_zero(total - fLimit);

      const paidQuantity = Number(
        (key ? current_payable - prev_payable : 0).toFixed(3)
      );

      // When everything is good, but accumulation limit surpassed
      const acWarning = surpasedAccumLimit ? { accumWarning: true } : {};

      const status = fromDisposal
        ? Status()
        : key && total_reached && item.weight
        ? localTotalReached > mLimit // Danger if local reach greater than max limit
          ? Status({ danger: true, ...acWarning })
          : total_reached > fLimit // Warning if total reach greater than free limit, but local total reach less than or equal to max limit
          ? Status({ warning: true, ...acWarning })
          : fLimit !== Infinity // Good if total reach less than or equal to free limit and also local total isless than or equal to max limit
          ? Status({ good: true, ...acWarning })
          : Status({ neutral: true, ...acWarning }) // Neutral when good and unlimited
        : Status();

      if (key) {
        Total[key] = total_reached;
      }
      if (index === n) {
        info = { ...item, quantPay: paidQuantity, status };
        break;
      } else {
        //The error should be set here. Set error from status
        // console.log({ paidQuantity }, n);
        List.push({
          ...item,
          paidQuantity: fromDisposal ? 0 : paidQuantity,
          error: status.danger,
        });
        if (n === waste_list.length - 1) {
          setWaste_list([...List]);
        }
      }
    }
    return info;
  };

  /**
   * Function to get the className of the indicator to paint it green, gray, orange or red
   * @param status_obj Warning obj
   * @returns A string which is a className value
   */
  const getWarningClass = (
    status_obj:
      | (Partial<wasteItem> & {
          quantPay: number;
          status: status;
        })
      | undefined
  ): string => {
    if (status_obj) {
      const { status } = status_obj;
      if (status.accumWarning) {
        return warningClass.accumWarning;
      } else if (status.good) {
        return warningClass.good;
      } else if (status.warning) {
        return warningClass.warning;
      } else if (status.danger) {
        return warningClass.danger;
      } else {
        return warningClass.neutral;
      }
    } else {
      return warningClass.neutral;
    }
  };

  const formatNumber = (value: number, fix = false): string => {
    const val = `${fix ? value.toFixed(2) : value}`.replace(".", ",");

    return val;
  };

  //weightNum is only for kg
  // The useMemo variables below are only for useEffect changes
  const weightNum = useMemo(() => {
    return waste_list.map((item) => item.weight).join(` `);
  }, [waste_list]);
  const wasteNum = useMemo(() => {
    return waste_list.map((item) => item.selectedWaste).join(` `);
  }, [waste_list]);

  useEffect(() => {
    getWasteInfo();
  }, [weightNum, wasteNum, personCombinedWaste]);

  useEffect(() => {
    const outsideClick = (e: MouseEvent) => {
      const element = document.getElementById(indicatorID);
      if (!element || !element.contains(e.target as Node)) {
        setActiveRow(undefined);
      }
    };

    document.addEventListener("click", outsideClick);
    return () => {
      document.removeEventListener("click", outsideClick);
    };
  }, []);

  useEffect(() => {
    setActiveRow(undefined);
  }, [waste_list.length]);

  const getWaste = (id: number): siteWasteType | undefined => {
    return siteWastes.find((item) => item.wasteId === id);
  };

  /**
   *
   * @returns The size of the columns for the acceptance form
   */
  const getSize = () => {
    if (isCompany) {
      return size8;
    } else {
      return size5;
    }
  };

  /**
   * Function to set the waste_list array
   * @param fieldValue The value(s) to be changed
   * @param n Index of the item to change
   */
  const set = (fieldValue: Partial<wasteItem>, n: number) => {
    const list = waste_list.map((item, index) => {
      if (index === n) {
        return { ...item, ...fieldValue };
      } else {
        return item;
      }
    });
    setActiveRow(n);
    setWaste_list([...list]);
  };

  /**
   * Removes a row from the waste_list array and resets the state with the remaining items in the array
   * A row can only be removed if the total row is 2 or more
   * @param n Index of the row to remove
   */
  const remove = (n: number) => {
    if (waste_list.length > 1) {
      //  * A row can only be removed if the total row is 2 or more
      const new_list = waste_list.filter((item, index) => index !== n);
      setWaste_list([...new_list]);
    }
  };

  const waste_value = (id?: number | string): option => {
    id = parseInt(`${id}`);
    const wasteValue = wasteOptions.find((opt) => opt.value === id);

    return wasteValue || defaultOption;
  };
  const origin_value = (id?: number | string): option => {
    id = parseInt(`${id}`);
    const originValue = originOptions.find((opt) => opt.value === id);
    return originValue || defaultOption;
  };
  const primarySource_value = (id?: number | string): option => {
    id = parseInt(`${id}`);
    const primarySourceValue = primarySourceOptions.find(
      (opt) => opt.value === id
    );
    return primarySourceValue || defaultOption;
  };

  const handlingCode_value = (id?: number) => {
    id = parseInt(`${id}`);
    const waOption = wasteActivityTypeOptions.find((opt) => opt.value === id);
    return waOption || defaultOption;
  };

  const add = () => {
    setWaste_list([
      ...waste_list,
      {
        ...wasteItem_init,
        date,
        siteId,
        origin: isCompany ? undefined : defaultOriginId,
      },
    ]);
  };

  const headConstruct: pageConstructType = {
    sizeDist: getSize(),
    typeDist: [],
    hasHeader: true,
    headerText: isCompany
      ? [
          Header(["Atlieka"], false),
          Header(["Gautas kiekis"], false),
          Header(["Atliekų kilmė"], false),
          Header([" Mokėjimo dokumentas"], false),
          Header([" Tvarkymo kodas"], false),
          Header(["Pirminis atliekų šaltinis"], false),
          Header(["Sąskaita"], false),
        ]
      : [
          Header(["Atlieka"], false),
          Header(["Gautas kiekis"], false),
          Header(["Atliekų kilmė"], false),
          Header(["Sąskaita"], false),
        ],
  };

  const extraRow = (
    item: Partial<wasteItem>,
    options: { sourceOption: option; handlingCode: option },
    n: number
  ): gridType[] => {
    const { handlingCode, sourceOption } = options;
    return isCompany
      ? [
          textField(
            "",
            false,
            () => {},
            item.paymentDocument || "",
            (paymentDocument) => {
              set({ paymentDocument }, n);
            }
          ),
          dropField(
            wasteActivityTypeOptions,
            "",
            false,
            handlingCode,
            (waType) => {
              set({ handlingCode: waType }, n);
            },
            true
          ),
          dropField(
            primarySourceOptions,
            "",
            false,
            sourceOption,
            (primarySource) => {
              set({ primarySource }, n);
            },
            true
          ),
        ]
      : [];
  };

  const isUnit = (item: Partial<wasteItem>) =>
    item.hasUnit && isNumber(item.kgPerUnit);

  // console.log(personCombinedWaste, `personCombinedWaste`);

  const rowConstruct = waste_list.map((item, n) => {
    const warningObj = getWasteInfo(n);

    return {
      sizeDist: getSize(),
      typeDist: [
        dropField(
          wasteOptions,
          "Ieškoti...",
          false,
          waste_value(item.selectedWaste),
          (selectedWaste) => {
            // console.log(`selectedWaste`, selectedWaste);
            const siteWaste = getWaste(selectedWaste);
            const waste = siteWaste?.Waste;
            const hasUnit = siteWaste?.Waste.uom === "unit";
            const kgPerUnit = siteWaste?.Waste.kgPerUnit;
            let prices: undefined | { price: number; priceVat: number } =
              undefined;

            let waste_info: Partial<wasteItem> = {};

            if (siteWaste && waste) {
              prices = getWastePrice({
                direct_vat_calc: siteWaste.direct_vat_calc,
                price: siteWaste.price,
                priceVat: siteWaste.priceVat,
              });

              waste_info = {
                name: waste.waste,
                code: waste.code || `-`,
                paidPrice_raw: prices.price || 0,
                paidPriceVat_raw: prices.priceVat || 0,
                maxLimit: siteWaste.maxLimit,
                groupId: siteWaste.Waste.groupId,
                freeLimit: siteWaste.siteGroupFreeLimit
                  ? siteWaste.siteGroupFreeLimit
                  : siteWaste.freeLimit,
                accumulationLimit: siteWaste.accumulationLimit,
                invoice: !!siteWaste.compInvoice,
                compInvoice: !!siteWaste.compInvoice,
                kgPerUnit,
                hasUnit,
                uom: siteWaste.Waste.uom || "kg",
              };
            }

            set({ selectedWaste, ...waste_info }, n);
          },
          true
        ),
        childField(
          <div className="quantity_accpt_wrapper" key={`quant_${n}`}>
            <div className="input_wrapper flex">
              <span className="unit_title">{isUnit(item) ? "vnt." : "kg"}</span>
              <div className="flex1 input_weight_cont">
                <Input
                  value={
                    isUnit(item)
                      ? item.quantity || item.quantity === 0
                        ? `${item.quantity}`
                        : ``
                      : item.weight || item.weight === 0
                      ? `${item.weight}`
                      : ``
                  }
                  placeholder={""}
                  handler={() => {}}
                  error={false}
                  type={"number"}
                  externalValue={
                    isUnit(item)
                      ? item.quantity || item.quantity === 0
                        ? `${item.quantity}`
                        : ``
                      : item.weight || item.weight === 0
                      ? `${item.weight}`
                      : ``
                  }
                  setExternalValue={(value) => {
                    const weight = isUnit(item)
                      ? item.kgPerUnit! * parseInt(value)
                      : (value as unknown as number);

                    const quantity = isUnit(item)
                      ? { quantity: parseInt(value) }
                      : {};
                    // console.log(`{ error, warning }`, weight);
                    set({ weight, ...quantity }, n);
                  }}
                />
              </div>
              <div
                className={`warning_label_wrapper center ${getWarningClass(
                  warningObj
                )} ${activeRow == n ? `activeRow` : ``}`}
              >
                {warningObj?.status.danger ||
                warningObj?.status.warning ||
                warningObj?.status.accumWarning ? (
                  <div
                    id={indicatorID}
                    className="accpt_float_indicator_wrapper"
                  >
                    <div className="accpt_float_indicator_container">
                      {warningObj?.status.warning ? (
                        <>
                          <p>
                            Viršytas nemokamai priimamų atliekų kiekis per
                            metus:{" "}
                            {isUnit(item)
                              ? isNumber(item.freeLimit)
                                ? (item.freeLimit || 0) / item.kgPerUnit! || `0`
                                : `-`
                              : isNumber(item.freeLimit)
                              ? item.freeLimit || "0"
                              : `-`}{" "}
                            {isUnit(item) ? "vnt." : "kg"}
                          </p>
                          <p>
                            Per vieną dieną pristatomų atliekų limitas:{" "}
                            {isUnit(item)
                              ? (item.maxLimit || 0) / item.kgPerUnit!
                              : item.maxLimit || `-`}{" "}
                            {isUnit(item) ? "vnt." : "kg"}
                          </p>
                          {/* Collected before now */}
                          <p>
                            Iki šiol priimta:{" "}
                            {Key(item) && personCombinedWaste[Key(item)!]
                              ? isUnit(item)
                                ? personCombinedWaste[Key(item)!] /
                                  item.kgPerUnit!
                                : personCombinedWaste[Key(item)!]
                              : 0}{" "}
                            {isUnit(item) ? "vnt." : "kg"}
                          </p>
                          <p>
                            Mokamas kiekis:{" "}
                            {(warningObj?.quantPay || 0) /
                              (isUnit(item) ? item.kgPerUnit! : 1)}{" "}
                            {isUnit(item) ? "vnt." : "kg"}
                            <br />
                            Mokėtina suma:{" "}
                            {transform(
                              (
                                (warningObj?.paidPriceVat_raw || 0) *
                                (warningObj.paidQuantity || 0)
                              ).toFixed(2)
                            )}{" "}
                            €
                          </p>
                        </>
                      ) : warningObj?.status.danger ? (
                        <>
                          <p>
                            Viršytas per vieną dieną pristatomų atliekų limitas:{" "}
                            {isUnit(item)
                              ? (item.maxLimit || 0) / item.kgPerUnit!
                              : item.maxLimit || `-`}{" "}
                            {isUnit(item) ? "vnt." : "kg"}
                          </p>
                          {/* Collected before now */}
                          <p>
                            Iki šiol priimta:{" "}
                            {Key(item) && personCombinedWaste[Key(item)!]
                              ? isUnit(item)
                                ? personCombinedWaste[Key(item)!] /
                                  item.kgPerUnit!
                                : personCombinedWaste[Key(item)!]
                              : 0}{" "}
                            {isUnit(item) ? "vnt." : "kg"}
                          </p>
                        </>
                      ) : (
                        <div />
                      )}
                      {warningObj?.status.accumWarning ? (
                        <p
                          className={
                            warningObj?.status.warning ||
                            warningObj?.status.danger
                              ? `accumtLimit_acceptance_warning`
                              : ``
                          }
                        >
                          Viršytas sąvartyne / aikštelėje kaupiamos atliekos
                          limitas:{" "}
                          {item.accumulationLimit &&
                          parseFloat(`${item.accumulationLimit}`)
                            ? parseFloat(`${item.accumulationLimit}`) /
                              (isUnit(item) ? item.kgPerUnit! : 1000)
                            : `-`}{" "}
                          {isUnit(item) ? item.uom : "t"}
                        </p>
                      ) : (
                        <div />
                      )}
                      {warningObj?.status.warning ||
                      warningObj?.status.danger /* 
                      Note that if there are wastes currently inputed in rows
                      before this, they will be added in the calculation
                       */ ? (
                        <div className="accpt_float_note_wrapper">
                          Jei priimamų atliekų sąraše jau yra įvestų atliekų, jų
                          kiekiai įtraukti į skaičiavimą
                        </div>
                      ) : (
                        ``
                      )}
                    </div>
                  </div>
                ) : (
                  ``
                )}
                <IconContainer width={24}>
                  <InfoSVG />
                </IconContainer>
              </div>
            </div>
          </div>
        ),
        dropField(
          originOptions,
          "Ieškoti...",
          false,
          origin_value(item.origin),
          (origin) => {
            set({ origin }, n);
          },
          true
        ),
        ...extraRow(
          item,
          {
            sourceOption: primarySource_value(item.primarySource || undefined),
            handlingCode: handlingCode_value(item.handlingCode || undefined),
          },
          n
        ),
        childField(
          <div
            className="accpt_waste_removal_btn_wrapper center"
            key={`invoice_${n}`}
          >
            <CheckBox
              readOnly={!!item.compInvoice}
              isChecked={!!item.invoice}
              onChange={(e) => {
                if (!item.compInvoice) {
                  const isChecked = !!item.invoice;
                  set({ invoice: !isChecked }, n);
                }
              }}
            />
          </div>
        ),
        childField(
          <div
            className="remove_waste_btn_warapper center"
            key={`remove_waste_${n}`}
          >
            {waste_list.length > 1 ? (
              <IconContainer>
                <img
                  title="Pašalinti"
                  src={require(`../../../assets/images/minus.svg`).default}
                  alt=""
                  onClick={() => {
                    remove(n);
                  }}
                />
              </IconContainer>
            ) : (
              ``
            )}
          </div>
        ),
      ],
      hasHeader: false,
      headerText: [],
    };
  });

  const addConstruct = {
    sizeDist: getSize(),
    typeDist: [
      childField(
        <div
          key={`add_remove_btn`}
          onClick={() => add()}
          className="waste_add_btn_wrapper flex"
          style={{
            gridColumn: `1/${getSize().length + 1}`,
          }}
        >
          <button type="button" name="button" className="waste_add_btn">
            <IconContainer>
              <img
                src={require(`../../../assets/images/plus.svg`).default}
                alt=""
              />
            </IconContainer>
            <div className="center">Pridėti atlieką</div>
          </button>
        </div>
      ),
    ],
    hasHeader: false,
    headerText: [],
  };

  const deficitRes = useAsync(
    {
      asyncFunc: getTranstionDeficit,
      funcParams: { id: personId, isCompany },
      immediate: true,
      clearOnError: true,
    },
    [personId, isCompany]
  );

  const oweConstruct = {
    isOwing: !!deficitRes.data?.deficit,
    sizeDist: getSize(),
    typeDist: [
      childField(
        <div
          key={`add_remove_btn`}
          onClick={() => add()}
          style={{
            gridColumn: `1/${getSize().length + 1}`,
          }}
        >
          {deficitRes.loading
            ? "Įkeliamas ..."
            : deficitRes.data
            ? `Klientas šiuo metu skolingas ${
                deficitRes.data.deficit
                  ? formatNumber(deficitRes.data.deficit)
                  : "0,00"
              } €`
            : ``}
        </div>
      ),
    ],
    hasHeader: false,
    headerText: [],
  };

  return (
    <div className="accpt_table_wrapper">
      {!completeDetails && <div className="accpt_float"></div>}
      <GenerateForm>
        {[
          <FormGenerator
            key={`acceptance_headerField_1_${0}`}
            gridSizeDist={headConstruct.sizeDist}
            gridTypeDist={headConstruct.typeDist}
            hasHeader={headConstruct.hasHeader}
            headerText={headConstruct.headerText}
          />,
        ]}
        {rowConstruct.map((construct, n) => (
          <FormGenerator
            key={`acceptance_rowField_1_${n}`}
            gridSizeDist={construct.sizeDist}
            gridTypeDist={construct.typeDist}
            hasHeader={construct.hasHeader}
            headerText={construct.headerText}
          />
        ))}
        {[addConstruct, ...(personId ? [oweConstruct] : [])].map(
          (construct, n) => (
            <FormGenerator
              id={
                // @ts-ignore
                n === 1 && construct.isOwing
                  ? "acceptance_deficit_warning"
                  : undefined
              }
              key={`acceptance_rowField_1_${n}`}
              gridSizeDist={construct.sizeDist}
              gridTypeDist={construct.typeDist}
              hasHeader={construct.hasHeader}
              headerText={construct.headerText}
            />
          )
        )}
      </GenerateForm>
    </div>
  );
};

const AcceptanceForm = ({
  siteId,
  isCompany,
  origins,
  primarySources,
  wasteActivityTypes,
  date,
  completeDetails,
  waste_list,
  setWaste_list,
  id,
  fromDisposal,
  wastes,
}: {
  siteId: number;
  isCompany: boolean;
  date: Date;
  origins: originType[];
  primarySources: primarySource[];
  wasteActivityTypes: wasteActivityTypeType[];
  completeDetails: boolean;
  waste_list: Partial<wasteItem>[];
  setWaste_list: (data: Partial<wasteItem>[]) => void;
  id: null | number;
  fromDisposal: boolean;
  wastes: wasteType[];
}) => {
  const siteWasteRes = useAsync(
    {
      asyncFunc: getSiteWastes,
      funcParams: { siteId },
      immediate: true,
    },
    [siteId]
  );

  const siteWastes = siteWasteRes.data?.siteWastes || [];

  return (
    <div className="acceptanceForm_wrapper accpt_form">
      <div className="wasteacceptance_title_header">PRIIMAMOS ATLIEKOS</div>

      <div className="acceptance_waste_date">
        <p>Atliekų priėmimo data ir laikas</p>
        <div className="acceptance_waste_date_dropD_container">
          {moment(date).format("YYYY-MM-DD HH:mm")}
        </div>
      </div>
      {/* 
          isCompany && () and not isCompany ? () : () was used.
          This is to prevent using previous state (i.e isCompany false state stage) as is set by the DropDown
          component via `dropField` from being used by the current state (i.e isCompany true state stage)
          This occurs because the dropdown field component's state is set internal and not externally
      */}
      {isCompany && (
        <AcceptanceFields
          wastes={wastes}
          fromDisposal={fromDisposal}
          completeDetails={completeDetails}
          date={date}
          isCompany={isCompany}
          origins={origins}
          id={id}
          primarySources={primarySources}
          setWaste_list={setWaste_list}
          siteId={siteId}
          siteWastes={siteWastes}
          waste_list={waste_list}
          wasteActivityTypes={wasteActivityTypes}
        />
      )}
      {!isCompany && (
        <AcceptanceFields
          wastes={wastes}
          fromDisposal={fromDisposal}
          completeDetails={completeDetails}
          date={date}
          isCompany={isCompany}
          origins={origins}
          id={id}
          primarySources={primarySources}
          setWaste_list={setWaste_list}
          siteId={siteId}
          siteWastes={siteWastes}
          waste_list={waste_list}
          wasteActivityTypes={wasteActivityTypes}
        />
      )}
      <div className="waste_acceptance_legend_wrapper">
        <div>
          <div className="color_code neutral_code"></div>
          <span>Priimamų atliekų kiekis neribojamas</span>
        </div>
        <div>
          <div className="color_code good_code"></div>
          <span>Nemokamai priimamos atliekos</span>
        </div>
        <div>
          <div className="color_code warning_code"></div>
          <span>Mokamai priimamos atliekos</span>
        </div>
        <div>
          <div className="color_code danger_code"></div>
          <span>
            Viršytas per vieną dieną pristatomų atliekų limitas arba viršytas
            sąvartyne / aikštelėje kaupiamos atliekos limitas
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStoreStateToProps = (state: any) => ({
  wastes: state.wasteReducer.wastes,
});

export default connect(mapStoreStateToProps)(AcceptanceForm);
