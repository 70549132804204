import { connect } from "react-redux";

import WasteManagement from "./WasteManagement";

const mapStoreStateToProps = function (store) {
  return {
    primarySources: store.primarySourceReducer.primarySources,
    sites: store.siteReducer.sites,
  };
};

export default connect(mapStoreStateToProps)(WasteManagement);
